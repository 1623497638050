import {createAction, props} from "@ngrx/store";

export const UpsertPreviousUrl = createAction(
  '[Layout] Upsert previous url', props<{ previousUrl: string }>()
);

export const LoadRuntimeInfo = createAction(
  '[Runtime info] LoadRuntimeInfo', props<{ id: string, name: string, isEdit: boolean }>()
);

export const InitRuntimeInfo = createAction(
  '[Runtime info] InitRuntimeInfo'
);
