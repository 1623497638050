import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngrx/store';
import {WindowState} from '../../reducers';
import {Subject} from 'rxjs';
import {FwFormBuilder, FwFormGroup} from '@happy-windows/framework/core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {translation} from "../../i18n";
import {CheckDate, ResetPageWindows} from '../../actions';

/**
 * Pravy panel pri testovacim rezimu
 */
@Component({
  selector: 'hw-window-testing',
  templateUrl: './window-testing.component.html',
  styleUrls: ['./window-testing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowTestingComponent implements OnInit, OnDestroy {

  translation = translation;
  destroyed$: Subject<any> = new Subject();

  testForm: FwFormGroup;

  constructor(private store: Store<WindowState>,
              private fb: FwFormBuilder) {
    this.testForm = fb.fwGroup({
      active: fb.fwControl(false)
    });
  }

  ngOnInit(): void {
    this.testForm.valueChanges.pipe(
      takeUntil(this.destroyed$),
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(x => {
        this.store.dispatch(CheckDate());
      }
    );
  }

  onResetPageWindow() {
    this.store.dispatch(ResetPageWindows());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

}
