import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WarningMessageModel} from '@happy-windows/api-interfaces';
import {translation} from '../../i18n';
import {Store} from '@ngrx/store';
import {WarningMessageState} from '../../reducers';
import {selectWarningMessagesByCalendarName} from '../../selectors';
import {Observable} from 'rxjs';
import {FwFormArray, FwFormBuilder, IdentifiedPack} from '@happy-windows/framework/core';
import {tap} from 'rxjs/operators';
import {SaveManyWarningMessage} from "../../actions";

@Component({
  selector: 'hw-warning-messages-dialog',
  templateUrl: './warning-messages-dialog.component.html',
  styleUrls: ['./warning-messages-dialog.component.scss']
})
export class WarningMessagesDialogComponent {

  translation = translation;

  warningMessageState$: Observable<{ data: IdentifiedPack<WarningMessageModel>[], loading: boolean, error: any }>;
  warningMessagesForm: FwFormArray<WarningMessageModel>;

  constructor(private dialogRef: MatDialogRef<WarningMessagesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public calendarName: string,
              private store: Store<WarningMessageState>, private fb: FwFormBuilder) {
    this.warningMessagesForm = fb.fwArray<WarningMessageModel>([]);
    this.warningMessageState$ = this.store.select(selectWarningMessagesByCalendarName(calendarName)).pipe(
      tap(x => {
        if (!x.loading) {
          x.data.forEach(y => {
            this.add(y.data);
          });
        }
      })
    );
  }

  save() {
    this.store.dispatch(SaveManyWarningMessage({
      calendarName: this.calendarName,
      warningMessages: this.warningMessagesForm.getRawValue().filter(x => !!x.text)
    }));
    this.dialogRef.close();
  }

  add(warningMessage?: WarningMessageModel) {
    const formGroup = this.fb.fwGroup({
      id: this.fb.fwControl(warningMessage ? warningMessage.id : null),
      idName: this.fb.fwControl(this.calendarName),
      text: this.fb.fwControl(warningMessage ? warningMessage.text : null)
    });
    this.warningMessagesForm.push(formGroup);
  }

  remove(id: number) {
    this.warningMessagesForm.removeAt(id);
  }

}
