import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {
  ChangeLayoutTemplate,
  ChangeLayoutTemplateError,
  ChangeLayoutTemplateSuccess,
  ChangeWindowsMode,
  ClosePageWindow,
  ClosePageWindowSuccess,
  CreateWindow,
  CreateWindowError,
  CreateWindows,
  CreateWindowsError,
  CreateWindowsSuccess,
  CreateWindowSuccess,
  DoNothingWindow,
  InitContentWindow,
  LoadExampleWindows,
  LoadWindowsByCalendarName,
  LoadWindowsByCalendarNameError,
  LoadWindowsByCalendarNameSuccess,
  OpenPageWindow,
  RemoveWindow,
  RemoveWindowError,
  RemoveWindowSuccess,
  ResetWarningMessages,
  SetFocusOnWindow,
  ToggledContentWindow,
  ToggledWarningMessage,
  UpdateWindow,
  UpdateWindowError,
  UpdateWindows,
  UpdateWindowsError,
  UpdateWindowsSuccess,
  UpdateWindowSuccess,
  ValidateWindow
} from '../actions';
import {concatMap, map, mergeMap, switchMap} from 'rxjs/operators';
import {SnackBarService, WarningMessageService, WindowService} from '../providers';
import {of} from 'rxjs';
import * as moment from 'moment';
import {Store} from '@ngrx/store';
import {selectWarningMessagesByCalendarName} from '../selectors';
import {LocalStateService, SetLocalState} from '@happy-windows/framework/local-state';
import {translation} from "../i18n";
import {DESIGNER_MODE, RIGHT_PANEL_STATE} from '../const';
import {withLatestCached} from '@happy-windows/framework/core';
import {DesignerModeType} from '@happy-windows/api-interfaces';

@Injectable()
export class WindowEffect {

  translation = translation;

  loadWindowsByCalendarName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadWindowsByCalendarName),
      concatMap(({calendarName}) => {
        return this.windowService.getWindowsByCalendarName(calendarName).pipe(
          map(env => env.success
            ? LoadWindowsByCalendarNameSuccess({windows: env.data})
            : LoadWindowsByCalendarNameError({error: env.error})
          )
        )
      })
    )
  );

  loadExampleWindows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadExampleWindows),
      concatMap(_ => {
        return this.windowService.getExampleWindows().pipe(
          map(env => env.success
            ? LoadWindowsByCalendarNameSuccess({windows: env.data})
            : LoadWindowsByCalendarNameError({error: env.error})
          )
        )
      })
    )
  );

  updateWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateWindow),
      concatMap(({window}) => this.windowService.upsertWindow(window).pipe(
        map(env => {
          if (env.success) {
            return UpdateWindowSuccess({window: env.data})
          } else {
            this.snackBarService.openSnackBar(translation.window.updateWindowError, 'warn');
            return UpdateWindowError({id: window.id, error: env.error})
          }
        })
        )
      )
    )
  );

  createWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateWindow),
      concatMap(({window}) => this.windowService.upsertWindow(window).pipe(
        map(env => {
          if (env.success) {
            return CreateWindowSuccess({window: env.data})
          } else {
            this.snackBarService.openSnackBar(translation.window.createWindowError, 'warn');
            return CreateWindowError({error: env.error})
          }
        })
        )
      )
    )
  );

  removeWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveWindow),
      concatMap(({window}) => this.windowService.removeWindow(window).pipe(
        map(env => {
          if (env.success) {
            return RemoveWindowSuccess({window: window})
          } else {
            this.snackBarService.openSnackBar(translation.window.removeWindowError, 'warn');
            return RemoveWindowError({id: window.id, error: env.error})
          }
        })
        )
      )
    )
  );

  createWindows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateWindows),
      concatMap(({windows}) => this.windowService.createWindows(windows).pipe(
        map(env => env.success
          ? CreateWindowsSuccess({windows: env.data})
          : CreateWindowsError({error: env.error})
        ))
      )
    )
  );

  updateWindows$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateWindows),
      concatMap(({idName, properties}) => this.windowService.updateWindows(idName, properties).pipe(
        map(env => env.success
          ? UpdateWindowsSuccess({windows: env.data})
          : UpdateWindowsError({error: env.error})
        ))
      )
    )
  );

  changeLayoutTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeLayoutTemplate),
      concatMap(({idName, windows}) => this.windowService.changeLayoutTemplate(idName, windows).pipe(
        map(env => {
          if (env.success) {
            this.snackBarService.openSnackBar(translation.window.changeTemplateLayoutSuccess, 'warn');
            return ChangeLayoutTemplateSuccess({windows: env.data});
          } else {
            this.snackBarService.openSnackBar(translation.window.changeTemplateLayoutError, 'warn');
            return ChangeLayoutTemplateError({error: env.error});
          }
        }))
      )
    )
  );

  switchPageWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenPageWindow),
      withLatestCached(({calendarName}) => this.store.select(selectWarningMessagesByCalendarName(calendarName))),
      switchMap(([{window}, warningMessages]) => {
        const designerMode: DesignerModeType = this.localStateService.getStateInstant(DESIGNER_MODE);
        const warningMessage = this.warningMessageService.getWarningMessage(warningMessages.data);
        if (designerMode === 'test' || designerMode === 'example') {
          // zjisteni, zda se ma vubec validovat datum
          if (window.checkDate && window.date) {
            if (moment().isSame(window.date, "day")) {
              // datum je platny
              return of(ToggledContentWindow({window: window}));
            } else {
              // zobrazit varovnou hlasku
              return of(ToggledWarningMessage({warningMessage: warningMessage}));
            }
          } else {
            // nevaliduje se datum
            return of(ToggledContentWindow({window: window}));
          }
        } else if (designerMode === 'run') {
          if (window.open) {
            // jiz je otoceny window
            return of(ToggledContentWindow({window: window}));
          }
          // const connection = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
          // if (connection.rtt === 0 && connection.downlink === 0) {
          if (!navigator.onLine) {
            // problem s internetem
            this.snackBarService.openSnackBar(translation.shared.connectionMessageError, 'warn');
            return of(DoNothingWindow());
          }
          // dojde k overeni otoceni okna na serveru (vytazeni do dalsiho effektu z duvodu usnandneni prace a prehlednosti)
          return of(ValidateWindow({window: window, warningMessage: warningMessage}));
        }
      })
    )
  );

  validateWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ValidateWindow),
      concatMap(({window, warningMessage}) => this.windowService.validateWindow(window.id).pipe(
        map(x => {
          if (x.success) {
            // FIXME co se ma stat, kdyz nemam zadnou Warning message?
            if (x.data) {
              // datum je aktualni
              return ToggledContentWindow({window: window});
            }
            // podvadi se, datum je neplatny
            return ToggledWarningMessage({warningMessage: warningMessage});
          } else {
            // spadnul mi dotaz na overeni okna
            this.snackBarService.openSnackBar(translation.window.validateWindowError, 'warn');
            return DoNothingWindow();
          }
        })
        )
      )
    )
  );

  changeWindowsMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeWindowsMode),
      switchMap(({mode}) => [
        ResetWarningMessages(),
        SetLocalState({
          key: DESIGNER_MODE,
          payload: mode
        })
        ]
      )
    )
  );

  /**
   * Resi problem se zavrenim praveho panelu, pokud po 2. kliknu na window
   */
  setFocusOnWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetFocusOnWindow),
      switchMap(({window}) => {
          return of(SetLocalState({
            key: RIGHT_PANEL_STATE,
            payload: window.focused ? null : 'windowSetting'
          }));
        }
      )
    )
  );

  toggledContentWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClosePageWindow),
      mergeMap(({window}) => {
          const designerMode: DesignerModeType = this.localStateService.getStateInstant(DESIGNER_MODE);
          if (designerMode === 'example' || designerMode === 'test') {
            // pokud se jedna o example, tak se fackuje otoceni
            return [InitContentWindow(), ClosePageWindowSuccess({window: {...window, pageState: 'back'}})];
          } else if (designerMode === 'run' && window.open) {
            // pokud se jedna o runtime a uz jsem otocenej, tak neni duvod volat request
            return [InitContentWindow(), ClosePageWindowSuccess({window: window})];
          }
          // jedna se o runtime a nejsem otocenej
          return this.windowService.upsertWindow({...window, open: true}).pipe(
            mergeMap((x) => {
              if (x.success) {
                return [InitContentWindow(), ClosePageWindowSuccess({window: x.data})];
              }
            })
          );
        }
      )
    )
  );

  doNothingWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DoNothingWindow),
    ), {dispatch: false}
  );

  constructor(private actions$: Actions, private store: Store<any>, private localStateService: LocalStateService,
              private warningMessageService: WarningMessageService,
              private windowService: WindowService, private snackBarService: SnackBarService) {
  }

}
