import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {PwaService, RuntimeService} from '../providers';
import {LoadRuntimeInfo} from '../actions';
import {getRuntimeInfo} from '../selectors';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class IsLoggedInGuard implements CanActivate {
  constructor(
    private router: Router,
    private pwaService: PwaService,
    private runtimeService: RuntimeService,
    private store: Store<any>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.runtimeService.isLoggedIn()) {
      let runtimeInfo = null;
      try {
        runtimeInfo = this.runtimeService.getRuntimeInfo();
      } catch (e) {
        this.runtimeService.signOut(state.url.includes('/pwa/pwa'), true);
        return false;
      }

      if (state.url.includes('/home') && this.pwaService.isPwa()) {
        // jsem prihlasenej a jsem na pwa
        return this.router.parseUrl('/pwa/pwa');
      }

      if (state.url.includes('/designer') && !runtimeInfo.isEdit) {
        // zadal jsem url designer a nemam heslo pro editaci, tak chci presmerovat na home
        return this.router.parseUrl('/home');
      }

      if (state.url.includes('/pwa')) {
        // jedna se o PWA
        if (runtimeInfo.isEdit) {
          // pokud mam heslo pro editaci, tak chci vzdy prejitn a home
          return this.router.parseUrl('/home');
        }

        // pokud nemam heslo pro editaci
        if (state.url.includes('/login')) {
          // nema smysl jit na login, kdyz jsem jiz prihlaseny
          return this.router.parseUrl('/pwa/pwa');
        }
        // pokud URL je spravna a nemam heslo pro editaci, tak true (pusti me to na /pwa/pwa)
        return true;
      }

      this.store.dispatch(LoadRuntimeInfo({
        id: runtimeInfo.id,
        name: runtimeInfo.name,
        isEdit: runtimeInfo.isEdit
      }));
      return this.store.select(getRuntimeInfo).pipe(
        filter(x => x && !!x.id),
        // tap(x => this.pwaService.ask(x.id)),
        map(_ => true)
      );
    }
    /////////////////////// NEJSEM PRIHLASENEJ ///////////////////////////
    if (state.url.includes('/home')) {
      // guard mam na vsechno, proto musim povolit home
      if (this.pwaService.isPwa()) {
        return this.router.parseUrl('/pwa/login');
      }
      // posle me to sem i kdyz zadam nesmyslnout URL (!== home nebo designer)
      return true;
    }
    if (state.url.includes('/designer')) {
      // zadal jsem url designer, tak chci presmerovat na home
      return this.router.parseUrl('/home');
    }
    if (state.url.includes('/pwa/login')) {
      // guard mam na vsechno, proto musim povolit pwa/login
      // posle me to sem i kdyz zadam nesmyslnout URL (!== pwa/login nebo pwa/pwa)
      return true;
    }
    if (state.url.includes('/pwa/pwa')) {
      // zadal jsem url designer, tak chci presmerovat na home
      return this.router.parseUrl('/pwa/login');
    }
    return false;
  }

}

// @Injectable()
// export class RuntimeInfoResolver implements Resolve<RuntimeInfo> {
//   constructor(private store: Store<SharedState>) {
//   }
//
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RuntimeInfo> {
//     return this.store.select(
//       x => x.runtimeInfo).pipe(
//       filter(x => x && !!x.id),
//       take(1),
//       map(x => x));
//   }
// }
