import {Directive} from '@angular/core';

@Directive({
  selector: '[fwLoadingDataTemplate]'
})
export class LoadingDataTemplateDirective {

  constructor() {
  }

}
