import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {
  CreateWarningMessage,
  CreateWarningMessageError,
  CreateWarningMessageSuccess,
  LoadExampleWarningMessages,
  LoadWarningMessagesByCalendarName,
  LoadWarningMessagesByCalendarNameError,
  LoadWarningMessagesByCalendarNameSuccess,
  RemoveWarningMessage,
  RemoveWarningMessageError,
  RemoveWarningMessageSuccess,
  SaveManyWarningMessage,
  SaveManyWarningMessageError,
  SaveManyWarningMessageSuccess,
  UpdateWarningMessage,
  UpdateWarningMessageError,
  UpdateWarningMessageSuccess
} from '../actions';
import {concatMap, map} from 'rxjs/operators';
import {WarningMessageService} from '../providers';

@Injectable()
export class WarningMessageEffect {

  loadWarningMessagesByCalendarName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadWarningMessagesByCalendarName),
      concatMap(({calendarName}) => {
        return this.warningMessageService.getWarningMessageByCalendarName(calendarName).pipe(
          map(env => env.success
            ? LoadWarningMessagesByCalendarNameSuccess({warningMessages: env.data})
            : LoadWarningMessagesByCalendarNameError({error: env.error})
          )
        )
      })
    )
  );

  loadExampleWarningMessagesB$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadExampleWarningMessages),
      concatMap(_ => {
        return this.warningMessageService.getExampleWarningMessage().pipe(
          map(env => env.success
            ? LoadWarningMessagesByCalendarNameSuccess({warningMessages: env.data})
            : LoadWarningMessagesByCalendarNameError({error: env.error})
          )
        )
      })
    )
  );

  updateWarningMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateWarningMessage),
      concatMap(({warningMessage}) => this.warningMessageService.upsertWarningMessage(warningMessage).pipe(
        map(env => env.success
          ? UpdateWarningMessageSuccess({warningMessage: env.data})
          : UpdateWarningMessageError({id: warningMessage.id, error: env.error})
        ))
      )
    )
  );

  createWarningMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateWarningMessage),
      concatMap(({warningMessage}) => this.warningMessageService.upsertWarningMessage(warningMessage).pipe(
        map(env => env.success
          ? CreateWarningMessageSuccess({warningMessage: env.data})
          : CreateWarningMessageError({error: env.error})
        ))
      )
    )
  );

  removeWarningMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveWarningMessage),
      concatMap(({warningMessage}) => this.warningMessageService.removeWarningMessage(warningMessage).pipe(
        map(env => env.success
          ? RemoveWarningMessageSuccess({warningMessage: warningMessage})
          : RemoveWarningMessageError({id: warningMessage.id, error: env.error})
        ))
      )
    )
  );

  saveManyWarningMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveManyWarningMessage),
      concatMap(({warningMessages, calendarName}) =>
        this.warningMessageService.saveManyWarningMessages(warningMessages, calendarName).pipe(
          map(env => env.success
            ? SaveManyWarningMessageSuccess({warningMessages: env.data, calendarName: calendarName})
            : SaveManyWarningMessageError({error: env.error})
          ))
      )
    )
  );

  constructor(private actions$: Actions,
              private warningMessageService: WarningMessageService) {
  }

}
