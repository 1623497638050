import {Injectable} from "@angular/core";
import {ApiService, ConfigService} from '@happy-windows/framework/core';
import {WindowModel} from '@happy-windows/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor(private apiService: ApiService,
              private configService: ConfigService) {
  }

  getExampleWindows() {
    return this.apiService.get<any, WindowModel[]>(`${this.configService.value.apiUrls.base}flipper/load/example`,
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x, true)));
  }

  getWindowsByCalendarName(calendarName: string) {
    return this.apiService.get<any, WindowModel[]>(`${this.configService.value.apiUrls.base}flipper/calendar-name/${calendarName}`,
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x)));
  }

  // slouzi k overeni datumu okna, pokud je, tak se hlida, jinak vraci true
  validateWindow(windowId: number) {
    return this.apiService.get<boolean, boolean>(`${this.configService.value.apiUrls.base}flipper/validate/${windowId}`);
  }

  upsertWindow(window: WindowModel) {
    return this.apiService.post<any, WindowModel>(`${this.configService.value.apiUrls.base}flipper`,
      this.convertToDb(window),
      rawResponse => this.convertFromDb(rawResponse.data));
  }

  removeWindow(window: WindowModel) {
    return this.apiService.delete<any, WindowModel>(`${this.configService.value.apiUrls.base}flipper/${window.id}`);
  }

  // Nevyzaduje na backendu token
  createWindows(windows: WindowModel[]) {
    return this.apiService.post<any, WindowModel[]>(`${this.configService.value.apiUrls.base}flipper/create`,
      windows.map(window => this.convertToDb(window)),
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x)));
  }

  // Vyzaduje na backendu token
  upsertWindows(windows: WindowModel[]) {
    return this.apiService.post<any, WindowModel[]>(`${this.configService.value.apiUrls.base}flipper/all`,
      windows.map(window => this.convertToDb(window)),
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x)));
  }

  // Slouzi pro smazani soucasnych oken a vytvoreni novych
  changeLayoutTemplate(calendarName: string, windows: WindowModel[]) {
    return this.apiService.put<any, WindowModel[]>(`${this.configService.value.apiUrls.base}flipper/calendar-name/${calendarName}`,
      windows.map(window => this.convertToDb(window)),
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x)));
  }

  updateWindows(calendarName: string, properties: { textColor?: string, borderColor?: string, contentColor?: string }) {
    return this.apiService.patch<any, WindowModel[]>(`${this.configService.value.apiUrls.base}flipper/calendar-name/${calendarName}`,
      properties,
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x)));
  }

  private convertFromDb(window: any, isExample = false): WindowModel {
    return {
      id: window.id,
      idName: window.idName,
      front: window.front,
      frontContent: window.frontContent,
      back: window.back,
      backContent: window.backContent,
      text: window.text,
      date: window.date,
      open: window.open,
      conditional: window.conditional,
      textColor: window.textColor,
      borderColor: window.borderColor,
      contentColor: window.contentColor,
      position: {
        x: window.xPosition,
        y: window.yPosition,
      },
      size: {
        width: window.widthSize,
        height: window.heightSize,
      },
      focused: false,
      pageState: 'front',
      checkDate: false
    }
  }


  private convertToDb(window: WindowModel): any {
    return {
      id: window.id,
      idName: window.idName,
      front: window.front,
      frontContent: window.frontContent,
      back: window.back,
      backContent: window.backContent,
      text: window.text,
      date: window.date,
      open: window.open,
      conditional: window.conditional,
      textColor: window.textColor,
      borderColor: window.borderColor,
      contentColor: window.contentColor,
      xPosition: window.position.x,
      yPosition: window.position.y,
      widthSize: window.size.width,
      heightSize: window.size.height
    }
  }
}
