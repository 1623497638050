import {Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {Action, select, Store} from '@ngrx/store';
import {IdentifiedPack, Pack} from '../models';

export function isPack(obj: Pack<any> | any): obj is Pack<any> {
  const knownProperties = ["data", "loading", "validUntil", "error"];

  if (obj === undefined || obj === null) return false;
  let onlyKnownProperties = true;
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (!knownProperties.some(x => property === x)) {
        onlyKnownProperties = false;
        break;
      }
    }
  }
  return onlyKnownProperties;
}

export function isIdentifiedPack(obj: IdentifiedPack<any> | any): obj is IdentifiedPack<any> {
  const knownProperties = ["id", "data", "loading", "validUntil", "error"];

  if (obj === undefined || obj === null) return false;
  let onlyKnownProperties = true;
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (!knownProperties.some(x => property === x)) {
        onlyKnownProperties = false;
        break;
      }
    }
  }
  return onlyKnownProperties;
}

export function loadAndWait<StoreType, DataType>(loadingAction: Action, selector: (state: StoreType, props: any) => DataType, infinite = false) {
  return function latestFromisShit(source: Store<StoreType>) {
    source.dispatch(loadingAction);
    return new Observable(subscriber => {

      let obs = source
        .pipe(
          select(selector),
          filter((t: IdentifiedPack<DataType> | IdentifiedPack<any> | any) => {
            if (t instanceof Array) {
              return t && t.find(x => !x.loading);
            }
            if (isPack(t)) {
              return t && !t.loading && !!t.data;
            }
            if (isIdentifiedPack(t)) {
              return t && !t.loading && !!t.data;
            }
            return t !== undefined && t !== null;
          }));

      if (!infinite) {
        obs = obs.pipe(
          take(1)
        );
      }

      const subscription = obs.subscribe(
        value => {
          try {
            subscriber.next(value);
          } catch (err) {
            subscriber.error(err);
          }
        },
        err => subscriber.error(err),
        () => subscriber.complete()
      );
      return subscription;
    }) as Observable<DataType>;
  };
}

