import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

import {Observable, of, Subscription} from 'rxjs';

import {
  LoadingDataTemplateDirective,
  LoadingErrorTemplateDirective,
  LoadingLoadingTemplateDirective
} from '../../directives';
import {IdentifiedPack} from '@happy-windows/framework/core';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {translation} from '../../i18n';

@Component({
  selector: 'fw-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingPanelComponent implements OnDestroy {

  translation = translation;
  subscription: Subscription = null;

  @Input() dialog = false;

  @Input() size: 'big' | 'medium' | 'small' = 'big';

  @Input() customDataTemplate: TemplateRef<any>;
  @Input() customErrorTemplate: TemplateRef<any>;
  @Input() customLoadingTemplate: TemplateRef<any>;
  @Input() customLoadingTemplateDialog: TemplateRef<any>;

  @ViewChild('defaultDataTemplate', {static: true}) defaultDataTemplate;
  @ViewChild('defaultLoadingTemplate', {static: true}) defaultLoadingTemplate;
  @ViewChild('defaultLoadingTemplateDialog', {static: true}) defaultLoadingTemplateDialog;
  @ViewChild('defaultErrorTemplate', {static: true}) defaultErrorTemplate;

  @ContentChild(LoadingDataTemplateDirective, {read: TemplateRef}) contentedDataTemplate;
  @ContentChild(LoadingErrorTemplateDirective, {read: TemplateRef}) contentedErrorTemplate;
  @ContentChild(LoadingLoadingTemplateDirective, {read: TemplateRef}) contentedLoadingTemplate;

  @Output() retry: EventEmitter<any> = new EventEmitter();

  @Input() set storedPack(observable: Observable<IdentifiedPack<any>>) {
    if (!observable) {
      return;
    }
    this.unsubscribe();
    this.subscription = observable.pipe(
      debounceTime(200),
      distinctUntilChanged((prev, curr) => !prev || (prev.loading === curr.loading && prev.data === curr.data))
    ).subscribe(pack => {
      if (pack) {
        this.context = {$implicit: pack.data, loading: pack.loading, error: pack.error};
        this.cdr.markForCheck();
      }
    });
  }

  @Input() set pack(pack: IdentifiedPack<any>) {
    this.unsubscribe();
    if (pack) {
      this.subscription = of(pack).pipe(
        debounceTime(200),
        distinctUntilChanged((prev, curr) => prev.loading === curr.loading && prev.data === curr.data)
      ).subscribe(_ => {
        this.context = {$implicit: pack.data, loading: pack.loading, error: pack.error};
        this.cdr.markForCheck();
      });
    }
  }

  context = {
    $implicit: null, loading: false, error: false
  };

  constructor(private cdr: ChangeDetectorRef) {
  }

  get dataTemplate(): TemplateRef<any> {
    return this.customDataTemplate
      ? this.customDataTemplate
      : this.contentedDataTemplate
        ? this.contentedDataTemplate
        : this.defaultDataTemplate;
  }

  get errorTemplate(): TemplateRef<any> {
    return this.customErrorTemplate
      ? this.customErrorTemplate
      : this.contentedErrorTemplate
        ? this.contentedErrorTemplate
        : this.defaultErrorTemplate;
  }


  get loadingTemplate(): TemplateRef<any> {
    if (this.customLoadingTemplate) {
      return this.customLoadingTemplate
    }
    if (this.customLoadingTemplateDialog) {
      return this.customLoadingTemplateDialog
    }
    if (this.contentedLoadingTemplate) {
      return this.contentedLoadingTemplate;
    } else {
      if (this.dialog) {
        return this.defaultLoadingTemplateDialog;
      }
      return this.defaultLoadingTemplate;
    }
  }

  onRetry() {
    if (this.retry.observers.length > 0) {
      this.retry.emit();
    } else {
      location.reload();
    }
  }

  unsubscribe() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
