<h1 mat-dialog-title>
  {{translation.shared.manageWaringMessages | transloco}}
  <button mat-mini-fab color="primary" class="btn-success" (click)="add()"
          title="{{translation.shared.add | transloco}}">
    <mat-icon>add</mat-icon>
  </button>
</h1>
<div mat-dialog-content>
  <fw-loading-panel [storedPack]="warningMessageState$">
    <ng-template fwLoadingDataTemplate let-warningMessages>
      <ng-container *ngFor="let item of warningMessagesForm?.controls; let i = index;">
        <div [formGroup]="item" class="flex-grid flex-nogutter flex-align-center">
          <mat-form-field class="flex-col">
            <input matInput formControlName="text">
          </mat-form-field>
          <div class="flex-col-fixed">
            <button mat-icon-button color="warn" (click)="remove(i)" title="{{translation.shared.remove | transloco}}">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </fw-loading-panel>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="save()" cdkFocusInitial>{{translation.shared.save | transloco}}</button>
  <button mat-button mat-dialog-close>{{translation.shared.close | transloco}}</button>
</div>
