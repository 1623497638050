import {createFeatureSelector, createSelector} from '@ngrx/store';
import {warningMessageAdapter, WarningMessageState} from '../reducers';
import {CustomRouterStateUrlModel} from '@happy-windows/framework/core';

const getWarningMessageState = createFeatureSelector<WarningMessageState>('warningMessage');
const getRouterState = createFeatureSelector<CustomRouterStateUrlModel>('router');


const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = warningMessageAdapter.getSelectors(getWarningMessageState);


export const selectWarningMessagesByCalendarName = (calendarName: string) => createSelector(
  selectAll,
  getWarningMessageState,
  (warningMessages, warningMessagesState) => (
    {
      data: warningMessages.filter(x => x.data.idName === calendarName),
      loading: warningMessagesState.loading,
      error: warningMessagesState.error
    }
  )
);

export const openWarningMessage = createSelector(
  selectAll,
  (warningMessages) => warningMessages.find(x => x.data.opened)
);
