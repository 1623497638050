import {setPathsToObjectValues} from '@happy-windows/framework/core';

function AnyClass<Props>(): new () => Props {
  return class {
  } as any;
}

export function getLocalization<T = any>(translations: T): T {
  const temp = new (AnyClass<T>())();
  Object.assign(temp, setPathsToObjectValues('', translations as unknown as object));
  return temp;
}
