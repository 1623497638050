import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {WindowState} from '../../reducers';
import {DesignerModeType, PageStateType, WindowModel} from '@happy-windows/api-interfaces';
import {translation} from "../../i18n";
import {ChangeWindowsMode, CreateWindow, RemoveWindow, SwitchPageWindows} from '../../actions';
import {LocalStateService} from '@happy-windows/framework/local-state';
import {Observable} from 'rxjs';
import {BORDER_COLOR, CONTENT_COLOR, DESIGNER_MODE, RIGHT_PANEL_STATE, TEXT_COLOR} from '../../const';

@Component({
  selector: 'hw-window-action',
  templateUrl: './window-action.component.html',
  styleUrls: ['./window-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowActionComponent {

  translation = translation;
  designerMode$: Observable<DesignerModeType>;
  // slouzi pro spravne nastaveni popisu a ikony pro otoceni oken
  pageSiteState: PageStateType = 'front';

  @Input() calendarName: string;

  constructor(private store: Store<WindowState>,
              private localStateService: LocalStateService) {
    this.localStateService.setState(DESIGNER_MODE, 'select');
    this.designerMode$ = this.localStateService.getState<DesignerModeType>(DESIGNER_MODE);
  }

  addWindow() {
    this.store.dispatch(CreateWindow({
      window: {
        id: null,
        idName: this.calendarName,
        front: 'front',
        frontContent: 'text',
        back: 'back',
        backContent: 'text',
        text: 'message',
        date: null,
        open: false,
        conditional: false,
        textColor: TEXT_COLOR,
        borderColor: BORDER_COLOR,
        contentColor: CONTENT_COLOR,
        position: {x: 0, y: 0},
        size: {width: 100, height: 100},
        focused: false,
        pageState: 'front',
        checkDate: false
      }
    }))
  }

  remove(window: WindowModel) {
    this.store.dispatch(RemoveWindow({window: window}));
  }

  changeMod(mode: DesignerModeType) {
    this.store.dispatch(ChangeWindowsMode({mode: mode}));
    // pokud se jedna o testovaci mod, tak zobraz pravej panel,
    // v dalsich modech (napr: 'select') si to resi napr window sam pri jeho vyberu
    if (mode === 'test') {
      this.localStateService.setState(RIGHT_PANEL_STATE, 'testMode');
    } else {
      this.localStateService.setState(RIGHT_PANEL_STATE, null);
    }
  }

  switchPage() {
    this.store.dispatch(SwitchPageWindows());
    this.pageSiteState = this.pageSiteState === 'front' ? 'back' : 'front';
  }
}
