import {createAction, props} from '@ngrx/store';

export const SetLocalState = createAction(
  '[Local state] Set locale state',
  props<{ key: string, payload: any }>()
);

export const DeleteLocalState = createAction(
  '[Local state] Delete local state',
  props<{ key: string }>()
);
