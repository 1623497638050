import {AsyncValidatorFn, FormControl, ValidatorFn} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import * as moment from 'moment';

export class FwFormControl<T = any> extends FormControl {
  private _touchedChanges: Subject<boolean> = new Subject<boolean>();
  public touchedChanges: Observable<boolean> = null;

  public readonly value: T;

  constructor(
    formState?: T,
    validator: ValidatorFn | ValidatorFn[] = null,
    asyncValidator: AsyncValidatorFn | AsyncValidatorFn[] = null
  ) {
    super(formState, validator, asyncValidator);
    this.touchedChanges = this._touchedChanges.asObservable();
  }

  setValue(value: T, options: {
    onlySelf?: boolean,
    emitEvent?: boolean,
    emitModelToViewChange?: boolean,
    emitViewToModelChange?: boolean
  } = {}): void {
    if (moment(value + '', [moment.ISO_8601], true).isValid()) {
      const date = new Date(value as unknown as string);
      if (date.toString() === 'Invalid Date') {
        super.setValue(value, options);
      } else {
        super.setValue(date, options);
      }
    } else {
      super.setValue(value, options);
    }
  }

  patchValue(value: any, options: {
    onlySelf?: boolean,
    emitEvent?: boolean,
    emitModelToViewChange?: boolean,
    emitViewToModelChange?: boolean
  } = {}): void {
    if (moment(value + '', [moment.ISO_8601], true).isValid()) {
      const date = new Date(value as unknown as string);
      if (date.toString() === 'Invalid Date') {
        super.patchValue(value, options);
      } else {
        super.patchValue(date, options);
      }
    } else {
      super.patchValue(value, options);
    }
  }

  markAsTouchedRecursively(markAsDirty = false) {
    this.markAsTouched({onlySelf: true});
    if (markAsDirty) {
      this.markAsDirty();
    }
  }

  markAsUntouchedRecursively(markAsPristine = false) {
    this.markAsUntouched();
    if (markAsPristine) {
      this.markAsDirty();
    }
    this.markAsPristine();
  }

  markAsTouched(opts: { onlySelf?: boolean } = {}): void {
    this._touchedChanges.next(true);
    super.markAsTouched(opts);
  }

  markAsUntouched(opts: { onlySelf?: boolean } = {}): void {
    this._touchedChanges.next(false);
    super.markAsUntouched(opts);
  }
}
