import {HttpClientModule} from "@angular/common/http";
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import * as directives from './directives';
import * as components from './components';
import {CroppieModule} from 'angular-croppie-module';
import {FrameworkTranslateModule} from '@happy-windows/framework/translate';
import {localizations} from './i18n';
import {MatCheckboxModule} from '@angular/material/checkbox';


export const IMPORTS = [
  FrameworkTranslateModule.forFeature(localizations),
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  MatIconModule,
  MatProgressBarModule,
  MatButtonModule,
  MatCheckboxModule,
  CroppieModule
];
export const GUARDS = [];
export const RESOLVERS = [];
export const EXPORTS = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
];
export const COMPONENTS = [
  components.FileUploadComponent,
  components.LoadingPanelComponent,
  components.CroppieComponent,
  directives.LetDirective,
  directives.LoadingDataTemplateDirective,
  directives.LoadingErrorTemplateDirective,
  directives.LoadingErrorTemplateDirective
];
export const PROVIDERS = [];
