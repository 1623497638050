import {Action, ActionReducerMap, createReducer, on} from "@ngrx/store";
import {InitRuntimeInfo, LoadRuntimeInfo, UpsertPreviousUrl} from '../actions';
import {routerReducer} from '@ngrx/router-store';
import {CoreStateModel, RuntimeInfoModel} from '../models';
import {InjectionToken} from '@angular/core';


export const coreReducer = new InjectionToken<ActionReducerMap<CoreStateModel, Action>>(
  "Core state",
  {
    factory: () => ({
      router: routerReducer,
      core: reducer,
      runtimeInfo: runtimeInfoReducer
    })
  }
);

export const initialState: { previousUrl: string } =
  {
    previousUrl: null
  };

export const runtimeInfoInitialState: RuntimeInfoModel =
  {
    id: null,
    name: null,
    isEdit: false
  };

const reducer = createReducer(
  initialState,
  on(UpsertPreviousUrl, (state, {previousUrl}) =>
    (
      {
        ...state,
        previousUrl: previousUrl
      }
    )
  ),
);

const runtimeInfoReducer = createReducer(
  runtimeInfoInitialState,
  on(LoadRuntimeInfo, (state, {id, name, isEdit}) =>
    (
      {
        ...state,
        id: id,
        name: name,
        isEdit: isEdit
      }
    )
  ),
  on(InitRuntimeInfo, (state) =>
    (
      {
        ...runtimeInfoInitialState
      }
    )
  ),
);

