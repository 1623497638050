import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {FrameworkTranslateModule} from '@happy-windows/framework/translate';
import {localizations} from './i18n';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FrameworkSharedModule} from '@happy-windows/framework/shared';
import {META_REDUCERS, StoreModule} from "@ngrx/store";
import {StoreRouterConnectingModule} from "@ngrx/router-store";
import {
  CoreEffect,
  coreReducer,
  CustomRouterSerializer,
  getReducerCatcher,
  IsLoggedInGuard,
  JwtInterceptor,
  PrivilegeGuard
} from "@happy-windows/framework/core";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {EffectsModule} from '@ngrx/effects';
import {
  AppLayoutComponent,
  AppPwaComponent,
  DesignerComponent,
  HappyWindowsLayoutModule,
  HomeComponent,
  LoginComponent,
  PwaComponent
} from '@happy-windows/happy-windows/layout';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ServiceWorkerModule} from '@angular/service-worker';

export const appRoutes: Routes = [
  {
    path: 'pwa', component: AppPwaComponent,
    children: [
      {path: 'login', component: LoginComponent, data: {privilege: true}},
      {path: 'pwa', component: PwaComponent, data: {privilege: false}},
      {path: '**', redirectTo: 'login', pathMatch: 'full'}
    ],
    canActivate: [IsLoggedInGuard],
    canActivateChild: [PrivilegeGuard]
  },
  {
    path: '', component: AppLayoutComponent,
    children: [
      // {path: '', pathMatch: 'full',  redirectTo: 'home',}, // pouziva se pro defaultni(welcome) / presmerovavam si to rucne
      {path: 'home', component: HomeComponent, data: {animation: 'home', privilege: true}},// pouziva se pro definovani URL stranky
      {path: 'designer', component: DesignerComponent, data: {animation: 'designer', privilege: false}},
      {path: '**', redirectTo: 'home', pathMatch: 'full'}, // pouziva se napriklad pro error 404, co se ma udelat kdyz nenalezne stranku
    ],
    canActivate: [IsLoggedInGuard],
    canActivateChild: [PrivilegeGuard]
  }

  // {
  //   path: '',
  //   component: AppLayoutComponent,
  //   children: [
  //     {path: 'home', component: HomeComponent, data: {animation: 'home'}},// pouziva se pro definovani URL stranky
  //     {path: 'designer', component: DesignerComponent, data: {animation: 'designer'}},
  //     {path: '', pathMatch: 'full', component: HomeComponent}, // pouziva se pro defaultni(welcome)
  //     {path: '**', redirectTo: 'home', pathMatch: 'full'}, // pouziva se napriklad pro error 404, co se ma udelat kdyz nenalezne stranku
  //   ],
  //   canActivate: [UserIsLoggedInGuard]
  //   // canActivateChild: [PrivilegeGuard],
  //   // resolve: {runtimeInfo: RuntimeInfoResolver}
  // }
];

export function getMetaReducer() {
  return getReducerCatcher()
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      // z duvodu resolveru
      paramsInheritanceStrategy: 'always'
      // enableTracing: true
    }),
    FrameworkTranslateModule.forRoot({
      localizations: localizations,
      translocoConfig: {
        availableLangs: ['cs', 'en'],
        defaultLang: navigator.language.includes('cs') ? 'cs' : 'en',
        prodMode: environment.production,
        reRenderOnLangChange: true,
        missingHandler: {
          logMissingKey: false
        }
      }
    }),
    StoreModule.forRoot(coreReducer, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true
      }
    }),
    EffectsModule.forRoot([CoreEffect]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterSerializer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 30,
      name: 'hw',
      serialize: {}
    }),
    HappyWindowsLayoutModule,
    FrameworkSharedModule,
    MatSlideToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ServiceWorkerModule.register('custom-worker.js', {enabled: environment.production})
  ],
  providers: [
    IsLoggedInGuard,
    PrivilegeGuard,
    {
      provide: META_REDUCERS,
      useFactory: getMetaReducer,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {provide: MAT_DATE_LOCALE, useValue: 'cs-CS'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
