import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IdentifiedPack, RuntimeService} from '@happy-windows/framework/core';
import {WarningMessageModel, WindowContentModel, WindowGridModel, WindowModel} from '@happy-windows/api-interfaces';
import {Store} from '@ngrx/store';
import {WindowState} from '../../reducers';
import {
  ChangeWindowsMode,
  ClosePageWindow,
  LoadExampleWarningMessages,
  LoadExampleWindows,
  LoadWarningMessagesByCalendarName,
  LoadWindowsByCalendarName,
  OpenPageWindow,
  RemoveWindow,
  SetFocusOnWindow,
  ToggledWarningMessage,
  UpdateWindow
} from '../../actions';
import {openWarningMessage, selectWindowsByCalendarName, showContentWindow} from '../../selectors';
import {translation} from '../../i18n';
import {WarningMessageDialogComponent} from '../warning-message-dialog/warning-message-dialog.component.js';
import {takeUntil} from 'rxjs/operators';
import {WindowContentDialogComponent} from '../window-content-dialog/window-content-dialog.component';
import {DialogService} from '../../providers';
import {LocalStateService} from '@happy-windows/framework/local-state';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {BACKGROUND_COLOR, RIGHT_PANEL_STATE} from '../../const';

@Component({
  selector: 'hw-window-grid',
  templateUrl: './window-grid.component.html',
  styleUrls: ['./window-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowGridComponent implements OnChanges, OnDestroy {

  translation = translation;

  @Input() isPwa = false;
  @Input() windowZoom = 1; // resi problem u PWA s ruznym rozlisenim

  @Input() windowGrid: WindowGridModel;
  @Input() showWindowGridSetting = true;

  @HostBinding("style")
  get styleBackground(): SafeStyle {
    let background = `background-color: ${BACKGROUND_COLOR}`;
    if (this.windowGrid && this.windowGrid.background) {
      if (this.windowGrid.backgroundContent === 'color') {
        background = `background-color: ${this.windowGrid.background}`;
      } else {
        background = `background-image: url(${this.windowGrid.background}); background-size: contain;`;
      }
    }
    if (this.isPwa) {
      // resi problem u PWA s ruznym rozlisenim - jde o to, ze background je nastaveny na komponente pwa
      background = 'background: none!important';
    }
    return this.sanitizer.bypassSecurityTrustStyle(background);
  };

  windowsState$: Observable<{ data: IdentifiedPack<WindowModel>[], loading: boolean, error: any }>;

  @ViewChild("hwWindowGrid", {static: true})
  hwWindowGrid: ElementRef;

  openedWarningMessage: WarningMessageModel;
  openedWindowContent: WindowContentModel;
  destroyed$: Subject<any> = new Subject();


  constructor(private store: Store<WindowState>,
              private dialog: DialogService,
              private localStateService: LocalStateService,
              private runtimeService: RuntimeService,
              private sanitizer: DomSanitizer) {
    this.initOpenWarningMessage();
    this.initOpenContentWindow();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const tmpWindowGrid = changes['windowGrid'];
    if (tmpWindowGrid && !!tmpWindowGrid.currentValue) {
      const windowGridName = tmpWindowGrid.currentValue.name;
      const isExampleWindowGrid = tmpWindowGrid.currentValue.example;
      if (tmpWindowGrid.previousValue && windowGridName) {
        this.windowsState$ = this.store.select(selectWindowsByCalendarName(windowGridName));
        if (isExampleWindowGrid) {
          this.store.dispatch(LoadExampleWindows());
          this.store.dispatch(LoadExampleWarningMessages());
        } else if (this.runtimeService.isLoggedIn()) {
          // tmpWindowGrid.previousValue.name !== windowGridName
          this.store.dispatch(LoadWindowsByCalendarName({calendarName: windowGridName}));
          this.store.dispatch(LoadWarningMessagesByCalendarName({calendarName: windowGridName}));
        }
      }
    }
  }

  changeFocus(window: WindowModel) {
    this.store.dispatch(SetFocusOnWindow({window: window}));
  }

  remove(window: WindowModel) {
    this.store.dispatch(RemoveWindow({window: window}));
  }

  changePosition(event) {
    const tmp = {
      ...event.window,
      position: event.position
    };
    this.store.dispatch(UpdateWindow({window: tmp}));
  }

  changeSize(event) {
    const tmp = {
      ...event.window,
      size: event.size
    };
    this.store.dispatch(UpdateWindow({window: tmp}));
  }

  switchOnePage(window: WindowModel) {
    this.store.dispatch(OpenPageWindow({window: window, calendarName: this.windowGrid.name}));
  }

  changeWindowGridSetting(event: any) {
    event.stopPropagation();
    this.localStateService.setState(RIGHT_PANEL_STATE, 'windowGridSetting');
    this.store.dispatch(ChangeWindowsMode({mode: 'select'}));
  }

  private initOpenContentWindow() {
    this.store.select(showContentWindow).pipe(takeUntil(this.destroyed$))
      .subscribe(x => {
        if (x) {
          this.openedWindowContent = x;
          this.dialog.open(WindowContentDialogComponent, {
            id: 'contentWindow',
            data: x,
            maxHeight: `${this.hwWindowGrid.nativeElement.offsetHeight - 10}px`,
            width: `${this.hwWindowGrid.nativeElement.offsetWidth - 20}px`,
            hasBackdrop: true
          }, this.isPwa ? 'hw-pwa' : 'hw-window-grid').afterClosed().subscribe(_ => {
            this.store.dispatch(ClosePageWindow({window: this.openedWindowContent.window}));
            this.openedWindowContent = null;
          });
        } else {
          this.dialog.close('contentWindow');
        }
      });
  }

  private initOpenWarningMessage() {
    this.store.select(openWarningMessage).pipe(takeUntil(this.destroyed$))
      .subscribe(x => {
          if (x) {
            this.openedWarningMessage = x.data;
            this.dialog.open(WarningMessageDialogComponent, {
              id: 'warningMessage',
              data: x.data.text,
              maxHeight: `${this.hwWindowGrid.nativeElement.offsetHeight - 10}px`,
              width: `${this.hwWindowGrid.nativeElement.offsetWidth - 20}px`,
              hasBackdrop: true
            }, this.isPwa ? 'hw-pwa' : 'hw-window-grid').afterClosed().subscribe(_ => {
              this.store.dispatch(ToggledWarningMessage({warningMessage: this.openedWarningMessage}));
              this.openedWarningMessage = null;
            });
          } else {
            this.dialog.close('warningMessage');
          }
        }
      );
  }

  trackByFn(index, item) {
    return item.id;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }


}
