import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot} from '@angular/router';

import {Observable} from 'rxjs';
import {RuntimeService} from '../providers';

@Injectable()
export class PrivilegeGuard implements CanActivateChild {
  constructor(private runtimeService: RuntimeService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (route.data.privilege) {
      return true;
    }
    if (this.runtimeService.isLoggedIn()) {
      const isEdit = this.runtimeService.getRuntimeInfo().isEdit;
      if (state.url === '/pwa/pwa') {
        // pokud se jedna o PWA rezim, tak me to zajima jenom kdyz mam heslo pro pouzivani
        return !isEdit;
      }
      return isEdit;
    }
    return false;
  }

}

