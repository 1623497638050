import {Injectable} from "@angular/core";
import {ApiService, ConfigService} from '@happy-windows/framework/core';
import {WindowGridModel, WindowModel} from '@happy-windows/api-interfaces';
import {GRID} from '../const';

@Injectable({
  providedIn: 'root'
})
export class WindowGridService {

  constructor(private apiService: ApiService,
              private configService: ConfigService) {
  }

  loginHappyWindow(name: string, password: string) {
    return this.apiService.post<any, any>(`${this.configService.value.apiUrls.base}auth/login`, {
      username: name,
      password: password
    });
  }

  // prihlasi se jako ukazkovy happy window (nevyzaduje token)
  loadExampleHappyWindow() {
    return this.apiService.get<any, any>(`${this.configService.value.apiUrls.base}calendar/load/example`);
  }

  getHappyWindowByName(name: string) {
    return this.apiService.get<any, WindowGridModel>(`${this.configService.value.apiUrls.base}calendar/name/${name}`);
  }

  // Nevyzaduje na backendu token
  checkHappyWindowByName(name: string) {
    return this.apiService.get<any, boolean>(`${this.configService.value.apiUrls.base}calendar/check/${name}`);
  }

  createWindowGrid(windowGrid: WindowGridModel) {
    return this.apiService.post<any, WindowGridModel>(`${this.configService.value.apiUrls.base}calendar/create`,
      windowGrid);
  }

  upsertWindowGrid(windowGrid: WindowGridModel) {
    return this.apiService.post<any, WindowGridModel>(`${this.configService.value.apiUrls.base}calendar`,
      windowGrid);
  }

  removeWindowGrid(windowGrid: WindowGridModel) {
    return this.apiService.delete<any, WindowGridModel>(`${this.configService.value.apiUrls.base}calendar/${windowGrid.id}`);
  }

  /** Vygeneruje okna na zaklade sablony a velikosti gridu */
  public generateWindowsByTemplate(idName: string, textColor: string, borderColor: string, contentColor: string, template: { row: number, col: number }): WindowModel[] {
    const tmpHeightWindow = GRID.height / template.row;
    const tmpWidthWindow = GRID.width / template.col;
    // velikost okna - 10 odsazeni
    const windowSize = (tmpHeightWindow < tmpWidthWindow ? tmpHeightWindow : tmpWidthWindow) - 10;

    const marginHeight = (GRID.width - (windowSize * template.col)) / (template.col + 1);
    const marginWidth = (GRID.height - (windowSize * template.row)) / (template.row + 1);

    const windows: WindowModel[] = [];
    for (let row = 1; row <= template.row; row++) {
      for (let col = 1; col <= template.col; col++) {
        windows.push(
          {
            id: null,
            idName: idName,
            front: row + 'x' + col,
            frontContent: 'text',
            back: 'back',
            backContent: 'text',
            text: '',
            date: null,
            open: false,
            conditional: false,
            textColor: textColor,
            borderColor: borderColor,
            contentColor: contentColor,
            position: {
              x: (marginHeight * col) + (windowSize * (col - 1)),
              y: (marginWidth * row) + (windowSize * (row - 1))
            },
            size: {width: windowSize, height: windowSize},
            focused: false,
            pageState: 'front',
            checkDate: false
          }
        )
      }
    }
    return windows;
  }


  // private convertFromDb(window: any): WindowGridModel {
  //   return {
  //     id: window.id,
  //   }
  // }
  //
  //
  // private convertToDb(window: WindowGridModel): any {
  //   return {
  //     id: window.id,
  //   }
  // }
}
