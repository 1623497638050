import {createReducer, on} from "@ngrx/store";
import {
  ChangeLayoutTemplate,
  ChangeLayoutTemplateError,
  ChangeLayoutTemplateSuccess,
  ChangeWindowsMode,
  CheckDate,
  ClosePageWindow,
  ClosePageWindowError,
  ClosePageWindowSuccess,
  CreateWindow,
  CreateWindowError,
  CreateWindows,
  CreateWindowsError,
  CreateWindowsSuccess,
  CreateWindowSuccess,
  DisableFocusOnWindow,
  InitWindow,
  LoadExampleWindows,
  LoadWindowById,
  LoadWindowByIdError,
  LoadWindowByIdSuccess,
  LoadWindowsByCalendarName,
  LoadWindowsByCalendarNameError,
  LoadWindowsByCalendarNameSuccess,
  RemoveWindow,
  RemoveWindowError,
  RemoveWindowSuccess,
  ResetPageWindows,
  SetFocusOnWindow,
  SetTemporaryVariableWindow,
  SwitchPageWindows,
  UpdateWindow,
  UpdateWindowError,
  UpdateWindows,
  UpdateWindowsError,
  UpdateWindowsSuccess,
  UpdateWindowSuccess
} from '../actions';
import {WindowModel} from '@happy-windows/api-interfaces';
import {EntityAdapter, EntityState} from '@ngrx/entity/src/models';
import {createEntityAdapter} from '@ngrx/entity';
import {IdentifiedPack} from '@happy-windows/framework/core';


export interface WindowState extends EntityState<IdentifiedPack<WindowModel>> {
  loading: boolean;
  error: any;
}

export const windowAdapter: EntityAdapter<IdentifiedPack<WindowModel>> = createEntityAdapter<WindowModel>({});

const initialState: WindowState = windowAdapter.getInitialState({
  loading: false,
  error: null
});

export const windowReducer = createReducer(
  initialState,

  on(InitWindow,
    (state,) => ({
      ...windowAdapter.removeAll(state),
      error: null,
      loading: false
    })
  ),

  on(LoadWindowsByCalendarName,
    LoadExampleWindows,
    CreateWindow,
    CreateWindows,
    UpdateWindows,
    ChangeLayoutTemplate,
    (state) => ({
      ...state,
      loading: true
    })
  ),

  on(LoadWindowsByCalendarNameSuccess,
    UpdateWindowsSuccess,
    (state, {windows}) => ({
      ...windowAdapter.upsertMany(windows.map(window => ({
        id: window.id,
        data: window,
        loading: false,
        error: null
      })), state),
      error: null,
      loading: false
    })
  ),

  on(LoadWindowsByCalendarNameError,
    CreateWindowError,
    CreateWindowsError,
    UpdateWindowsError,
    ChangeLayoutTemplateError,
    (state, {error}) => ({
      ...state,
      loading: false,
      error: error
    })
  ),

  on(LoadWindowById,
    (state, {id}) =>
      windowAdapter.upsertOne({
        id: id,
        loading: true
      }, state)
  ),

  on(UpdateWindow,
    RemoveWindow,
    ClosePageWindow,
    (state, {window}) =>
      windowAdapter.upsertOne({
        id: window.id,
        data: {
          ...state.entities[window.id].data,
          size: window.size, // hack, aby zustala velikost window jeste nez se ulozi do DB
          position: window.position // hack, aby zustala pozice jeste nez se ulozi do DB
        },
        loading: true
      }, state)
  ),

  on(LoadWindowByIdSuccess,
    UpdateWindowSuccess,
    CreateWindowSuccess,
    SetTemporaryVariableWindow,
    (state, {window}) =>
      windowAdapter.upsertOne({
        id: window.id,
        data: {
          ...window,
          focused: state.entities[window.id] ? state.entities[window.id].data.focused : false, // je to z duvodu pridani novyho okna
          pageState: state.entities[window.id] ? state.entities[window.id].data.pageState : 'front' // je to z duvodu pridani novyho okna
        },
        loading: false,
        error: null
      }, {...state, loading: false, error: null})
  ),


  on(CreateWindowsSuccess,
    (state, {windows}) => ({
      ...windowAdapter.upsertMany(windows.map(window => ({
        id: window.id,
        data: {
          ...window
        },
        loading: false,
        error: null
      })), {...state, loading: false, error: null})
    })),

  on(ChangeLayoutTemplateSuccess,
    (state, {windows}) => {
      const tmpState = windowAdapter.removeAll(state);
      return {
        ...windowAdapter.upsertMany(windows.map(window => ({
          id: window.id,
          data: {
            ...window
          },
          loading: false,
          error: null
        })), {...tmpState, loading: false, error: null})
      }
    }
  ),

  on(RemoveWindowSuccess, (state, {window}) =>
    windowAdapter.removeOne(window.id, state)
  ),

  on(LoadWindowByIdError,
    UpdateWindowError,
    RemoveWindowError,
    ClosePageWindowError,
    (state, {id, error}) =>
      windowAdapter.upsertOne({
        id: id,
        data: null,
        loading: false,
        error: error
      }, state)
  ),

  on(ClosePageWindowSuccess,
    (state, {window}) =>
      windowAdapter.upsertOne({
        id: window.id,
        data: {
          ...window
        },
        loading: false
      }, state)
  ),

  on(ChangeWindowsMode,
    (state, {mode}) =>
      windowAdapter.map((entity => (
        {
          ...entity,
          data: {
            ...entity.data,
            focused: false,
            pageState: 'front',
            checkDate: false
          }
        }
      )), state)
  ),

  on(CheckDate,
    (state) =>
      windowAdapter.map((entity => (
        {
          ...entity,
          data: {
            ...entity.data,
            checkDate: !entity.data.checkDate
          }
        }
      )), state)
  ),

  on(SwitchPageWindows,
    (state) =>
      windowAdapter.map((entity => (
        {
          ...entity,
          data: {
            ...entity.data,
            pageState: entity.data.pageState === 'front' ? 'back' : 'front'
          }
        }
      )), state)
  ),

  on(ResetPageWindows,
    (state) =>
      windowAdapter.map((entity => (
        {
          ...entity,
          data: {
            ...entity.data,
            pageState: 'front'
          }
        }
      )), state)
  ),

  on(SetFocusOnWindow,
    (state, {window}) =>
      windowAdapter.map((entity => (
        {
          ...entity,
          data: {
            ...entity.data,
            focused: entity.data.id === window.id ? !entity.data.focused : false
          }
        }
      )), state)
  ),

  on(DisableFocusOnWindow,
    (state) =>
      windowAdapter.map((entity => (
        {
          ...entity,
          data: {
            ...entity.data,
            focused: false
          }
        }
      )), state)
  )
);
