import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Envelope, ServerHttpOptions} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) {
  }

  public get<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.get(url, defOptions).pipe(
      map(
        (resource: any) =>
          this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError(
        error =>
          this.projectError<ToClientType>(error)
      )
    );
  }

  public getRaw<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions ? {...requestOptions, observe: 'response'} : {observe: 'response'};
    return this.http.get(url, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body)
            : resource.body
        };
      }),
      catchError(
        error =>
          this.projectError<ToClientType>(error)
      )
    );
  }

  public post<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.post(url, body, defOptions).pipe(
      map(
        (resource: any) =>
          this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError(
        error =>
          this.projectError<ToClientType>(error)
      )
    );
  }


  public postRaw<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions ? {...requestOptions, observe: 'response'} : {observe: 'response'};
    return this.http.post(url, body, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body)
            : resource.body
        };
      }),
      catchError(
        error => of(error)
      )
    );
  }

  public put<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.put(url, body, defOptions).pipe(
      map(
        (resource: any) =>
          this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError(
        error =>
          this.projectError<ToClientType>(error)
      )
    );
  }


  public putRaw<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions ? {...requestOptions, observe: 'response'} : {observe: 'response'};
    return this.http.put(url, body, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body)
            : resource.body
        };
      }),
      catchError(
        error => of(error)
      )
    );
  }


  public delete<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.delete(url, defOptions).pipe(
      map(
        (resource: any) =>
          this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError(
        error =>
          this.projectError<ToClientType>(error)
      )
    );
  }


  public deleteRaw<ServerResponseType, ToClientType>(
    url: string,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions ? {...requestOptions, observe: 'response'} : {observe: 'response'};
    return this.http.delete(url, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body)
            : resource.body
        };
      }),
      catchError(
        error => of(error)
      )
    );
  }

  public patch<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<Envelope<ToClientType>> {
    const defOptions = requestOptions ? requestOptions : undefined;
    return this.http.patch(url, body, defOptions).pipe(
      map(
        (resource: any) =>
          this.buildResponse<ServerResponseType, ToClientType>(resource, mapping)
      ),
      catchError(
        error =>
          this.projectError<ToClientType>(error)
      )
    );
  }

  public patchRaw<ServerResponseType, ToClientType>(
    url: string,
    body: any,
    mapping?: (rawResponse: ServerResponseType) => ToClientType,
    requestOptions?: ServerHttpOptions
  ): Observable<HttpResponse<ToClientType>> {
    const defOptions = requestOptions ? {...requestOptions, observe: 'response'} : {observe: 'response'};
    return this.http.patch(url, body, defOptions as any).pipe(
      map((resource: any) => {
        return {
          ...resource,
          body: mapping ? mapping(resource.body)
            : resource.body
        };
      }),
      catchError(
        error => of(error)
      )
    );
  }

  private buildResponse<ServerType, ClientType>(
    response: Envelope<ServerType> | ServerType | any,
    mapping?: (rawResponse: ServerType) => ClientType)
    : Envelope<ClientType> {

    return {
      data: mapping != null ? mapping(response as ServerType) : response.data,
      success: true,
      resultCode: 200
    } as Envelope<ClientType>;

  }

  private projectError<ClientType>(error: HttpErrorResponse): Observable<Envelope<ClientType>> {
    let er: any = null;
    if (error.error) {
      er = error.error;
    } else {
      if (error.message) {
        er = error.message;
      }
    }
    return of({
      success: false,
      data: null,
      resultCode: er.status,
      error: {
        serverError: er.error,
        message: er.message,
        status: er.status
      },
    } as Envelope<ClientType>);
  }
}
