import {Injectable} from '@angular/core';
import {SwPush, SwUpdate} from '@angular/service-worker';
import {ConfigService} from './config.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

// **********************************************************************************************************************************************************************************************
// **      UKAZKOVY KLIENT V Node.JS, KTERÝ PŘIJÍMÁ SUBSCRIPTION A OBRATEM NA NĚ POSÍLÁ NOTIFIKACI - AKTUÁLNĚ JE SUBSCRIPTION VYTVOŘENA PŘI ÚSPĚŠNÉM PŘÍCHODU NA PORTÁL (VŽDY PO ZALOGOVÁNÍ)   **
// **********************************************************************************************************************************************************************************************

// const express = require('express');
// const bodyParser = require('body-parser');
// var webpush = require('web-push');
// var cors = require('cors');
// const app = express();

// var vapidPublicKey = 'BGi27_4OTb9AtmB1oSCOXSM1a2K-aVi6eY5AIdjcpYQei7xr1vC9ggKylYKp6GTYCU3tRbDFzq9H4X-V3U6Baqw';
// var vapidPrivateKey = 'awaTocWZVSl_7eqixSc_JBDomkQqGj4ILF36y4vSgqg';

// webpush.setVapidDetails(
//     'mailto:myaccount@outlook.com',
//     vapidPublicKey,
//     vapidPrivateKey
// );

// var payload = {
//     "notification": {
//         "title": "Telco Service Manager - CRM",
//         "body": "Přidán nový profile 'Zákazníci CETIN' ",
//         "icon": "https://marianbencat.herokuapp.com/assets/icons/icon-192x192.png",
//         "vibrate": [100, 50, 100],
//         "data": {
//             "url": "https://marianbencat.herokuapp.com/crm/customer/profile/0f3f0956-18da-4f04-bd5c-55452677f896"
//         },
//         "actions": [{
//             "action": "explore",
//             "title": "Přejít do aplikace"
//         }]
//     }
// };

// app.use(cors())

// app.use(bodyParser.json());
// app.use(bodyParser.urlencoded({ extended: true }));

// app.post('/', (req, res) => {
//     console.log(req.body);
//     webpush.sendNotification(JSON.parse(req.body.parameterValue), Buffer.from(JSON.stringify(payload)))
//         .then(function (response) {
//             console.log('sent');
//         }).catch(function (err) {
//             console.log(err);
//         });
//     res.send(req.body);
// });

// app.listen(3000, () => console.log('server started'));


interface BeforeInstallPromptEvent extends Event {

  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;

}

//https://web.dev/customize-install/#beforeinstallprompt
@Injectable({
  providedIn: 'root'
})
export class PwaService {
  install: BeforeInstallPromptEvent;
  notificationsSupported = false;

  constructor(
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private config: ConfigService,
    private router: Router,
    private http: HttpClient
  ) {
    window.addEventListener('beforeinstallprompt', (event: BeforeInstallPromptEvent) => {
      this.install = event;
    });

    window.addEventListener('appinstalled', (evt) => {
      // aplikace byla nainstalovana a neresi se, zda pres tlacitko, co nabidne chrome nebo vyvolany programatorem
      console.log('a2hs installed');
    });

    /**
     if (swUpdate.isEnabled) {
      // pokud je povoleny update sw a zaroven se jedna o jiz nainstalovanou aplikaci, tak hlidam, zda neni nova verze
      //if (this.isPwa()) {
        console.log('display-mode is standalone or fullscreen');
        swUpdate.available.subscribe(ev => {
          console.log("New version available!");
          console.log(ev);
          // dialog, zda chceme nainstalovat novou aplikaci
          if (confirm('Do you want update app?')) {
            window.location.reload();
          }
        });
      //}
    }
     */
  }

  askPwa() {
    if (this.install) {
      // musi mi to nekde uzivatel potvrdit (resim to v jinem dialogu)
      // if (confirm('Do you want install app?')) {
      this.install.prompt();
      this.install.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.install = null;
      });
    }
    // }
  }

  isPwa(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches;
  }

  // isSafari(): boolean {
  //   return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // }

  askPushNotification(user_id: string) {
    // this.swPush.requestSubscription({
    //     serverPublicKey: this.config.value.pwa.publicKey
    // }).then(subscription => {
    //   let deviceId = localStorage.getItem("deviceId")
    //   if (!deviceId) {
    //     deviceId = getUuid()
    //     localStorage.setItem("deviceId", deviceId)
    //   }
    //
    //   // Get public key and user auth from the subscription object
    //   const key = subscription.getKey ? subscription.getKey('p256dh') : '';
    //   const auth = subscription.getKey ? subscription.getKey('auth') : '';
    //   const value = {
    //     endpoint: subscription.endpoint,
    //     // Take byte[] and turn it into a base64 encoded string suitable for
    //     // POSTing to a server over HTTP
    //     key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
    //     auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : '',
    //     deviceId: deviceId
    //   };
    //     console.log(subscription);
    //   this.http.post(this.config.value.pwa.subscriptionSendToURL + '/' + user_id, value).subscribe(x => {
    //     console.log("Subscription send to server");
    //   });
    // })
    //     .catch(err => {
    //         console.log('Cannot set notifications: ' + err);
    //     });
  }
}
