<form [formGroup]="windowForm">

  <div class="flex-grid">
    <div class="flex-col-12">
      <h3>{{translation.window.front | transloco}}</h3>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field>
        <mat-label>{{translation.window.contentType | transloco}}</mat-label>
        <mat-select formControlName="frontContent" required (selectionChange)="changePageContent('front')">
          <mat-option *ngFor="let option of optionWindowContent" [value]="option.value">
            {{option.viewValue | transloco}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="windowForm.get('frontContent').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field>
        <mat-label>{{translation.window.content | transloco}}</mat-label>
        <input *ngIf="windowForm.get('frontContent').value === 'text'"
               matInput formControlName="front" required>
        <ng-container *ngIf="windowForm.get('frontContent').value === 'image'">
          <input disabled
                 matInput
                 [value]="windowForm.get('front').value ? (translation.shared.imageLoaded | transloco) : null"
                 required>
          <mat-icon aria-hidden="false" aria-label="add_photo_alternate" (click)="setImage('front')"
                    style="position: absolute; right: 0; top: 0;">
            add_photo_alternate
          </mat-icon>
        </ng-container>
        <mat-error *ngIf="windowForm.get('front').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="flex-grid">
    <div class="flex-col-12">
      <h3>{{translation.window.back | transloco}}</h3>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field>
        <mat-label>{{translation.window.contentType | transloco}}</mat-label>
        <mat-select formControlName="backContent" required (selectionChange)="changePageContent('back')">
          <mat-option *ngFor="let option of optionWindowContent" [value]="option.value">
            {{option.viewValue | transloco}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="windowForm.get('backContent').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field>
        <mat-label>{{translation.window.content | transloco}}</mat-label>
        <input *ngIf="windowForm.get('backContent').value === 'text'"
               matInput formControlName="back" required>
        <ng-container *ngIf="windowForm.get('backContent').value === 'image'">
          <input disabled
                 matInput
                 [value]="windowForm.get('back').value ? (translation.shared.imageLoaded | transloco) : null"
                 required>
          <mat-icon aria-hidden="false" aria-label="add_photo_alternate" (click)="setImage('back')"
                    style="position: absolute; right: 0; top: 0;">
            add_photo_alternate
          </mat-icon>
        </ng-container>
        <mat-error *ngIf="windowForm.get('back').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="flex-grid">
    <div class="flex-col-12">
      <h3>{{translation.window.general | transloco}}</h3>
    </div>
    <div class="flex-col-12">
      <mat-form-field>
        <mat-label>{{translation.window.date | transloco}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="windowForm.get('date').hasError('required')">
          {{translation.shared.requiredDateMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-col-12">
      <mat-form-field>
        <mat-label>{{translation.window.text | transloco}}</mat-label>
        <textarea matInput formControlName="text" required></textarea>
        <mat-error *ngIf="windowForm.get('text').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="flex-grid">
    <div class="flex-col-12 flex-md-6">
      <mat-form-field style="width: 80px">
        <mat-label>{{translation.window.textColor | transloco}}</mat-label>
        <input matInput formControlName="textColor" required type="color">
        <mat-error *ngIf="windowForm.get('textColor').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
      <button mat-button color="warn" (click)="setTextColorEveryone()">
        <!-- <mat-icon>file_upload</mat-icon> -->
        {{translation.shared.setEveryone | transloco}}
      </button>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field style="width: 80px">
        <mat-label>{{translation.window.borderColor | transloco}}</mat-label>
        <input matInput formControlName="borderColor" required type="color">
        <mat-error *ngIf="windowForm.get('borderColor').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
      <button mat-button color="warn" (click)="setBorderColorEveryone()">
        <!-- <mat-icon>file_upload</mat-icon> -->
        {{translation.shared.setEveryone | transloco}}
      </button>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field style="width: 80px">
        <mat-label>{{translation.window.contentColor | transloco}}</mat-label>
        <input matInput formControlName="contentColor" required type="color">
        <mat-error *ngIf="windowForm.get('contentColor').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
      <button mat-button color="warn" (click)="setContentColorEveryone()">
        <!-- <mat-icon>file_upload</mat-icon> -->
        {{translation.shared.setEveryone | transloco}}
      </button>
    </div>
  </div>

  <!--  <div class="flex-grid" formGroupName="size">-->
  <!--    <div class="flex-col-12 flex-md-6">-->
  <!--      <mat-form-field style="width: 80px">-->
  <!--        <mat-label>{{translation.window.size.width | transloco}}</mat-label>-->
  <!--        <input matInput formControlName="width" required type="number">-->
  <!--        <mat-error *ngIf="windowForm.get('size.width').hasError('required')">-->
  <!--          {{translation.shared.mustRequiredMessage | transloco}}-->
  <!--        </mat-error>-->
  <!--      </mat-form-field>-->
  <!--      <button mat-button color="warn" (click)="setTextColorEveryone()">-->
  <!--        &lt;!&ndash; <mat-icon>file_upload</mat-icon> &ndash;&gt;-->
  <!--        {{translation.shared.setEveryone | transloco}}-->
  <!--      </button>-->
  <!--    </div>-->
  <!--    <div class="flex-col-12 flex-md-6">-->
  <!--      <mat-form-field style="width: 80px">-->
  <!--        <mat-label>{{translation.window.size.height | transloco}}</mat-label>-->
  <!--        <input matInput formControlName="height" required type="number">-->
  <!--        <mat-error *ngIf="windowForm.get('size.height').hasError('required')">-->
  <!--          {{translation.shared.mustRequiredMessage | transloco}}-->
  <!--        </mat-error>-->
  <!--      </mat-form-field>-->
  <!--      <button mat-button color="warn" (click)="setBorderColorEveryone()">-->
  <!--        &lt;!&ndash; <mat-icon>file_upload</mat-icon> &ndash;&gt;-->
  <!--        {{translation.shared.setEveryone | transloco}}-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </div>-->

</form>
