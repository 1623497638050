import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {translation} from '../../i18n';
import {FwAbstractControl, FwFormBuilder, FwFormGroup, loadAndWait} from '@happy-windows/framework/core';
import {AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DialogService, WindowGridService} from '../../providers';
import {CroppieDialogComponent} from '../croppie-dialog/croppie-dialog.component';
import {Store} from '@ngrx/store';
import {WindowGridState} from '../../reducers';
import {CreateWindowGrid} from '../../actions';
import {getWindowGrid} from '../../selectors';
import {WindowModel} from '@happy-windows/api-interfaces';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  CONTENT_COLOR,
  GRID,
  OPTION_WINDOW_GRID_TEMPLATE,
  TEXT_COLOR
} from '../../const';

@Component({
  selector: 'hw-create-happy-window-dialog',
  templateUrl: './create-happy-window-dialog.component.html',
  styleUrls: ['./create-happy-window-dialog.component.scss']
})
export class CreateHappyWindowDialogComponent {

  translation = translation;

  happyWindowForm: FwFormGroup;
  windowGridForm: FwFormGroup;

  optionWindowGridContent = [
    {value: 'color', viewValue: this.translation.shared.color},
    {value: 'image', viewValue: this.translation.shared.image},
  ];

  windowsTemplate: WindowModel[] = null;

  optionWindowGridTemplate = OPTION_WINDOW_GRID_TEMPLATE;

  constructor(@Inject(MAT_DIALOG_DATA) public text: string, private dialogService: DialogService,
              private dialogRef: MatDialogRef<CreateHappyWindowDialogComponent>,
              private fb: FwFormBuilder, private windowGridService: WindowGridService,
              private store: Store<WindowGridState>) {
    this.happyWindowForm = fb.fwGroup({
      id: fb.fwControl(),
      name: fb.fwControl('', [Validators.required], [this.nameValidator()]),
      password: fb.fwGroup({
        password: fb.fwControl('', [Validators.required]),
        passwordConfirm: fb.fwControl('', [Validators.required]),
      }, {validator: this.compareValuesValidator('password', 'passwordConfirm', 'error')}),
      passwordEdit: fb.fwGroup({
        passwordEdit: fb.fwControl('', [Validators.required]),
        passwordEditConfirm: fb.fwControl('', [Validators.required])
      }, {validator: this.compareValuesValidator('passwordEdit', 'passwordEditConfirm', 'error')}),
      samePassword: fb.fwControl(),
    }, {validator: this.comparePasswordValidator()});

    this.windowGridForm = this.fb.fwGroup({
      template: fb.fwControl(null),
      background: fb.fwControl(BACKGROUND_COLOR),
      backgroundContent: fb.fwControl('color'),
      borderColor: fb.fwControl(BORDER_COLOR),
      textColor: fb.fwControl(TEXT_COLOR),
      contentColor: fb.fwControl(CONTENT_COLOR)
    });
  }

  create() {
    this.changeWindowGridTemplate({value: this.windowGridForm.get('template').value});
    this.store.pipe(
      loadAndWait<any, any>(
        CreateWindowGrid({
          windowGrid: {
            name: this.happyWindowForm.get('name').value,
            password: this.happyWindowForm.get('password.password').value,
            passwordEdit: this.happyWindowForm.get('passwordEdit.passwordEdit').value,
            background: this.windowGridForm.get('background').value,
            backgroundContent: this.windowGridForm.get('backgroundContent').value,
            example: false
          },
          windows: this.windowsTemplate
        }),
        getWindowGrid
      )
    ).subscribe(x => {
        if (x) {
          this.dialogRef.close();
        }
      }
    )
  }

  setImage() {
    const dialogRef = this.dialogService.open(CroppieDialogComponent, {
      height: '900px',
      width: '500px',
      data: GRID
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.windowGridForm.get('background').setValue(result);
      }
    });
  }

  changeWindowGridContent(event) {
    if (event.value === 'color') {
      this.windowGridForm.get('background').setValue(BACKGROUND_COLOR);
    } else {
      this.windowGridForm.get('background').setValue(null);
    }
  }

  changeWindowGridTemplate(event) {
    const value: { row: number, col: number } = event.value;
    if (value === null) {
      this.windowGridForm.get('template').setValue(null);
      this.windowsTemplate = null;
    } else {
      this.windowsTemplate = this.windowGridService.generateWindowsByTemplate(
        this.happyWindowForm.get('name').value,
        this.windowGridForm.get('textColor').value,
        this.windowGridForm.get('borderColor').value,
        this.windowGridForm.get('contentColor').value,
        value
      );
    }
  }


  compareValuesValidator(firstControl: string, secondControl: string, errorMessage: string): ValidatorFn {
    return (control: FwAbstractControl): ValidationErrors | null => {
      const first = control.get(firstControl).value;
      const second = control.get(secondControl).value;
      if (!first || !second || (first === second)) {
        if (second !== '') {
          control.get(secondControl).setErrors(null);
        }
        return null;
      }
      control.get(secondControl).setErrors({customErrorCode: errorMessage});
      return null;
    }
  }

  comparePasswordValidator(): ValidatorFn {
    return (control: FwAbstractControl): ValidationErrors | null => {
      const password = control.get('password.password').value; //passwordConfirm
      const passwordEdit = control.get('passwordEdit.passwordEdit').value; // passwordEditConfirm
      if (password && passwordEdit) {
        if (password === passwordEdit) {
          control.get('samePassword').setErrors({customErrorCode: 'shit'});
          return null;
        }
        control.get('samePassword').setErrors(null);
        return null;
      }
      control.get('samePassword').setErrors(null);
      return null;
    }
  }

  nameValidator(): AsyncValidatorFn {
    return (control: FwAbstractControl): Observable<ValidationErrors | null> => {
      return this.windowGridService.checkHappyWindowByName(control.value).pipe(
        map(res => {
          return !res.success || res.data ? {customErrorCode: true} : null;
        })
      );
    };
  }

  getBackgroundContent(key: string): string {
    return this.optionWindowGridContent.find(x => x.value === key).viewValue;
  }
}
