import {Injectable} from "@angular/core";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';
import {ComponentType} from '@angular/cdk/overlay';
import {ConfirmType} from '@happy-windows/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private readonly horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  private readonly verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar,
              private translocoService: TranslocoService) {
  }

  openSnackBar(message: string, type: ConfirmType = 'success', duration = 2000) {
    this.snackBar.open(this.translocoService.translate(message), 'x', {
      duration: duration,
      panelClass: this.getCssClass(type),
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  openFromComponentSnackBar(component: ComponentType<any>, type: ConfirmType = 'success', duration = 2000) {
    this.snackBar.openFromComponent(component, {
      duration: duration,
      panelClass: this.getCssClass(type),
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  private getCssClass(confirmType: ConfirmType): string {
    if (confirmType === 'success') {
      return 'snackbar-success';
    }
    if (confirmType === 'info') {
      return 'snackbar-info';
    }
    if (confirmType === 'warn') {
      return 'snackbar-warn';
    }
    if (confirmType === 'error') {
      return 'snackbar-error';
    }
  }

}
