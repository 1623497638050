import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CoreStateModel, RuntimeInfoModel} from '../models';

const getRuntimeInfoState = createFeatureSelector<RuntimeInfoModel>('runtimeInfo');
const geCoreState = createFeatureSelector<CoreStateModel>('core');

export const getRuntimeInfo = createSelector(
  getRuntimeInfoState,
  (runtimeInfo) => runtimeInfo
);

export const getPreviousUrl = createSelector(
  geCoreState,
  (core) => (core as any).previousUrl
);
