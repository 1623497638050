import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {translation} from '../../../i18n';
import {WindowGridState, WindowState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {DesignerModeType, RightPanelType, WindowModel} from '@happy-windows/api-interfaces';
import {Observable} from 'rxjs';
import {IdentifiedPack, RuntimeService} from '@happy-windows/framework/core';
import {getWindowGrid, selectedWindow} from '../../../selectors';
import {tap} from 'rxjs/operators';
import {MatDrawer} from '@angular/material/sidenav';
import {ChangeWindowsMode, DisableFocusOnWindow, LoadWindowGridByName} from '../../../actions';
import {LocalStateService} from '@happy-windows/framework/local-state';
import {DESIGNER_MODE, RIGHT_PANEL_STATE} from '../../../const';

@Component({
  selector: 'hw-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignerComponent {

  translation = translation;

  selectedWindow$: Observable<IdentifiedPack<WindowModel>>;
  rightPanel$: Observable<RightPanelType>;

  @ViewChild("drawer", {static: false})
  drawer: MatDrawer;

  windowGrid$: Observable<WindowGridState>;

  constructor(private store: Store<WindowState>,
              private runtimeService: RuntimeService,
              private localStateService: LocalStateService) {
    this.store.dispatch(LoadWindowGridByName({name: runtimeService.getLogin()}))
    this.store.dispatch(ChangeWindowsMode({mode: 'select'}));
    this.windowGrid$ = this.store.select(getWindowGrid);
    this.selectedWindow$ = this.store.select(selectedWindow);
    this.rightPanel$ = this.localStateService.getState<RightPanelType>(RIGHT_PANEL_STATE).pipe(
      tap(x => {
        if (this.drawer) {
          if (x !== null) {
            this.drawer.open();
          } else {
            this.drawer.close();
          }
        }
      })
    );
  }

  disableFocus() {
    // pokud nejde o testovaci mod, tak kdyz kliknu nekam jinam, nez na window nebo setting tak zavri pravej panel
    if (this.localStateService.getStateInstant<DesignerModeType>(DESIGNER_MODE) !== 'test') {
      this.localStateService.setState(RIGHT_PANEL_STATE, null);
    }
    this.store.dispatch(DisableFocusOnWindow());
  }

}
