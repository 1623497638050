import {Inject, Injectable, OnDestroy, TemplateRef} from "@angular/core";
import {DOCUMENT} from '@angular/common';
import {OverlayContainer} from '@angular/cdk/overlay';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';
import {MatDialogConfig} from '@angular/material/dialog/dialog-config';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DialogService implements OnDestroy {

  destroyed$: Subject<any> = new Subject();

  constructor(@Inject(DOCUMENT) private document: any,
              private overlayContainer: OverlayContainer,
              private dialog: MatDialog) {
    this.dialog.afterAllClosed.pipe(takeUntil(this.destroyed$)).subscribe(_ =>
      this.document.querySelector('body').appendChild(this.overlayContainer.getContainerElement())
    );
  }

  open<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>, selector: string = 'body'): MatDialogRef<T, R> {
    this.document.querySelector(selector).appendChild(this.overlayContainer.getContainerElement());
    return this.dialog.open(componentOrTemplateRef, config);
  }


  close(id: string) {
    if (this.dialog.getDialogById(id)) {
      this.dialog.getDialogById(id).close();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

}
