import {ActionReducer} from '@ngrx/store';

export function defaultReducerCatcherBehavior(err: any) {
  if (console && console.log) {
    console.log('Error in Reducer! Application may be in inconsistent state! Strongly suggest to refresh application!');
    // tslint:disable-next-line: no-console
    console.trace(err);
  }
  if (localStorage.getItem('ignoreReducerCatcherWarning') !== 'true') {
    const response = window.confirm('Error in Reducer! Application may be in inconsistent state! Strongly suggest to refresh application! Do you wish to reload application? Cancel to disable showing this. (localStorage)');
    if (response === true) {
      location.reload();
    } else {
      localStorage.setItem('ignoreReducerCatcherWarning', "true");
    }
  }
}

export function noopReducerCatcherBehavior(err: any) {
  // nothing
}


export function getReducerCatcher(customBehavior?: ((err: any) => void) | 'noop' | 'default') {
  const aliases = {
    'noop': noopReducerCatcherBehavior,
    'default': defaultReducerCatcherBehavior
  };

  if (typeof customBehavior === 'string' || customBehavior instanceof String) {
    customBehavior = aliases[customBehavior as unknown as string];
    if (!customBehavior) throw Error('Behavior alias is wrong!');
  }
  return function reducerCatcher(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
      let newState = null;
      try {
        newState = reducer(state, action);
      } catch (err) {
        ((customBehavior as (err: any) => void) || defaultReducerCatcherBehavior)(err);
        newState = state;
      }
      return newState;
    };
  }
}
