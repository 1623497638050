import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {LoginWindowGrid} from '../../../actions';
import {FwFormBuilder, FwFormGroup} from '@happy-windows/framework/core';
import {Validators} from '@angular/forms';
import {translation} from '../../../i18n';

@Component({
  selector: 'hw-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {
  translation = translation;

  loginForm: FwFormGroup;

  constructor(private fb: FwFormBuilder, private store: Store<any>) {
    this.loginForm = fb.fwGroup({
      name: fb.fwControl('', [Validators.required]),
      password: fb.fwControl('', [Validators.required]),
    });
  }

  login() {
    if (this.loginForm.valid) {
      this.store.dispatch(LoginWindowGrid({
        name: this.loginForm.get('name').value,
        password: this.loginForm.get('password').value,
        isPwa: true
      }));
    } else {
      this.loginForm.markAsTouchedRecursively();
    }
  }

}
