<form [formGroup]="testForm" class="example-form">
  <mat-slide-toggle formControlName="active"
                    labelPosition="before">{{ translation.shared.activeWarningMessages | transloco}}
  </mat-slide-toggle>
</form>
<button mat-button color="warn"
        class="ui-button-danger pull-right"
        (click)="onResetPageWindow()">
  <span class="material-icons">cached</span>
  {{translation.shared.resetSwitchWindow | transloco}}
</button>
