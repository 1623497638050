import {createReducer, on} from "@ngrx/store";
import {WindowGridModel} from '@happy-windows/api-interfaces';
import {
  CreateWindowGrid,
  CreateWindowGridError,
  CreateWindowGridSuccess,
  InitWindowGrid,
  LoadExampleWindowGrid,
  LoadWindowGridByName,
  LoadWindowGridByNameError,
  LoadWindowGridByNameSuccess,
  RemoveWindowGrid,
  RemoveWindowGridError,
  RemoveWindowGridSuccess,
  UpdateWindowGrid,
  UpdateWindowGridError,
  UpdateWindowGridSuccess
} from '../actions/window-grid.action';


export interface WindowGridState extends WindowGridModel {
  loading: boolean;
  error: any;
}

const initialState: WindowGridState = {
  id: null,
  name: null,
  background: null,
  backgroundContent: null,
  password: null,
  passwordEdit: null,
  example: false,
  loading: false,
  error: null
};

export const windowGridReducer = createReducer(
  initialState,

  on(InitWindowGrid,
    RemoveWindowGridSuccess,
    (state) =>
      (
        initialState
      )
  ),

  on(LoadWindowGridByName,
    LoadExampleWindowGrid,
    CreateWindowGrid,
    UpdateWindowGrid,
    RemoveWindowGrid,
    (state) =>
      (
        {
          ...state,
          loading: true,
          error: null
        }
      )
  ),

  on(LoadWindowGridByNameSuccess,
    CreateWindowGridSuccess,
    UpdateWindowGridSuccess,
    (state, {windowGrid}) => {
      const {isEdit, ...x} = (windowGrid as any);
      return {
        ...state,
        ...x,
        loading: false,
        error: null
      }
    }
  ),

  on(LoadWindowGridByNameError,
    CreateWindowGridError,
    UpdateWindowGridError,
    RemoveWindowGridError,
    (state, {error}) =>
      (
        {
          ...state,
          loading: false,
          error: error
        }
      )
  ),
);
