import {createAction, props} from "@ngrx/store";
import {WindowGridModel, WindowModel} from '@happy-windows/api-interfaces';

export const LoginWindowGrid = createAction('[WindowGrid] LoginWindowGrid', props<{ name: string, password: string, isPwa: boolean }>());
export const LogoutWindowGrid = createAction('[WindowGrid] LogoutWindowGrid', props<{ isPwa: boolean }>());
export const InitWindowGrid = createAction('[WindowGrid] InitWindowGrid');

// slouzi pro nacteni happy window jako ukazky (nepouziva token)
export const LoadExampleWindowGrid = createAction('[WindowGrid] LoadExampleWindowGrid');
export const LoadWindowGridByName = createAction('[WindowGrid] LoadWindowGridByName', props<{ name: string }>());
export const LoadWindowGridByNameSuccess = createAction('[WindowGrid] LoadWindowGridByNameSuccess', props<{ windowGrid: WindowGridModel }>());
export const LoadWindowGridByNameError = createAction('[WindowGrid] LoadWindowGridByNameError', props<{ error: any }>());

export const LoadWindowGridById = createAction('[WindowGrid] LoadWindowGridById', props<{ id: number }>());
export const LoadWindowGridByIdSuccess = createAction('[WindowGrid] LoadWindowGridByIdSuccess', props<{ windowGrid: WindowGridModel }>());
export const LoadWindowGridByIdError = createAction('[WindowGrid] LoadWindowGridByIdError', props<{ id: number, error: any }>());

export const CreateWindowGrid = createAction('[WindowGrid] CreateWindowGrid', props<{ windowGrid: WindowGridModel, windows: WindowModel[] }>());
export const CreateWindowGridSuccess = createAction('[WindowGrid] CreateWindowGridSuccess', props<{ windowGrid: WindowGridModel }>());
export const CreateWindowGridError = createAction('[WindowGrid] CreateWindowGridError', props<{ error: any }>());

export const UpdateWindowGrid = createAction('[WindowGrid] UpdateWindowGrid', props<{ windowGrid: WindowGridModel }>());
export const UpdateWindowGridSuccess = createAction('[WindowGrid] UpdateWindowGridSuccess', props<{ windowGrid: WindowGridModel }>());
export const UpdateWindowGridError = createAction('[WindowGrid] UpdateWindowGridError', props<{ id: number, error: any }>());

export const RemoveWindowGrid = createAction('[WindowGrid] RemoveWindowGrid', props<{ windowGrid: WindowGridModel }>());
export const RemoveWindowGridSuccess = createAction('[WindowGrid] RemoveWindowGridSuccess', props<{ windowGrid: WindowGridModel }>());
export const RemoveWindowGridError = createAction('[WindowGrid] RemoveWindowGridError', props<{ id: number, error: any }>());
