import {translation} from './i18n';

export const DESIGNER_MODE = 'DESIGNER_MODE';
export const RIGHT_PANEL_STATE = 'RIGHT_PANEL_STATE';
export const TEXT_COLOR = '#000000';
export const BACKGROUND_COLOR = '#FFFFFF';
export const CONTENT_COLOR = '#FFFFFF';
export const BORDER_COLOR = '#000000';
export const GRID = {width: 300, height: 600};
export const OPTION_WINDOW_GRID_TEMPLATE = [
  {value: null, viewValue: translation.happyWindow.customLayout},
  {value: {row: 2, col: 1}, viewValue: '2x1'},
  {value: {row: 3, col: 1}, viewValue: '3x1'},
  {value: {row: 2, col: 2}, viewValue: '2x2'},
  {value: {row: 3, col: 2}, viewValue: '3x2'},
  {value: {row: 4, col: 2}, viewValue: '4x2'},
  {value: {row: 3, col: 3}, viewValue: '3x3'},
  {value: {row: 5, col: 2}, viewValue: '5x2'},
  {value: {row: 4, col: 3}, viewValue: '4x3'},
  {value: {row: 5, col: 3}, viewValue: '5x3'},
  {value: {row: 4, col: 4}, viewValue: '4x4'},
  {value: {row: 6, col: 3}, viewValue: '6x3'},
  {value: {row: 5, col: 4}, viewValue: '5x4'},
  {value: {row: 6, col: 4}, viewValue: '6x4'}
];
