<form [formGroup]="loginForm" autocomplete="off">
  <div class="flex-grid flex-align-center">
    <div class="flex-col-12">
      <mat-form-field>
        <mat-label>{{translation.login.name | transloco}}</mat-label>
        <input matInput formControlName="name" required>
        <mat-error *ngIf="loginForm.get('name').hasError('required')">
          {{translation.login.mustRequiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-col-12">
      <mat-form-field>
        <mat-label>{{translation.login.password | transloco}}</mat-label>
        <input matInput formControlName="password" type="password" required>
        <mat-error *ngIf="loginForm.get('password').hasError('required')">
          {{translation.login.mustRequiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-col-12">
      <button mat-raised-button (click)="login()" class="login-btn">
        {{translation.login.button.login | transloco}}
        <mat-icon>login</mat-icon>
      </button>
    </div>
  </div>
</form>
