import {RouterStateSerializer} from "@ngrx/router-store";
import {CustomRouterStateUrlModel} from "@happy-windows/framework/core";
import {RouterStateSnapshot} from "@angular/router";

export class CustomRouterSerializer implements RouterStateSerializer<CustomRouterStateUrlModel> {

  serialize(routerState: RouterStateSnapshot): CustomRouterStateUrlModel {
    let router = routerState.root;

    while (router.firstChild) {
      router = router.firstChild;
    }

    return {
      url: routerState.url,
      params: router.params,
      queryParams: router.queryParams,
      segments: routerState.url.split("/"),
      data: router.data
    };
  }

}
