<div class="flex-grid" style="width: 1100px">
  <div class="flex-col-12 flex-md-6">
    <mat-vertical-stepper linear #stepper style="width: 100%">

      <mat-step [stepControl]="happyWindowForm">
        <form [formGroup]="happyWindowForm" autocomplete="off">
          <ng-template matStepLabel>{{translation.happyWindow.info | transloco}}</ng-template>

          <div class="flex-grid">
            <div class="flex-col-12">
              <mat-form-field>
                <mat-label>{{translation.happyWindow.name | transloco}}</mat-label>
                <input matInput formControlName="name" required>
                <mat-error *ngIf="happyWindowForm.get('name').hasError('required')">
                  {{translation.shared.mustRequiredMessage | transloco}}
                </mat-error>
                <mat-error *ngIf="happyWindowForm.get('name').hasError('customErrorCode')">
                  {{translation.shared.nameMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-grid" formGroupName="password">
            <div class="flex-col-12 flex-md-6">
              <mat-form-field>
                <mat-label>{{translation.happyWindow.password | transloco}}</mat-label>
                <input matInput formControlName="password" required type="password">
                <mat-error *ngIf="happyWindowForm.get('password.password').hasError('required')">
                  {{translation.shared.mustRequiredMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-col-12 flex-md-6">
              <mat-form-field>
                <mat-label>{{translation.happyWindow.passwordConfirm | transloco}}</mat-label>
                <input matInput formControlName="passwordConfirm" required type="password">
                <mat-error *ngIf="happyWindowForm.get('password.passwordConfirm').hasError('required')">
                  {{translation.shared.mustRequiredMessage | transloco}}
                </mat-error>
                <mat-error *ngIf="happyWindowForm.get('password.passwordConfirm').hasError('customErrorCode')">
                  {{translation.shared.confirmPasswordMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-grid" formGroupName="passwordEdit">
            <div class="flex-col-12 flex-md-6">
              <mat-form-field>
                <mat-label>{{translation.happyWindow.passwordEdit | transloco}}</mat-label>
                <input matInput formControlName="passwordEdit" required type="password">
                <mat-error *ngIf="happyWindowForm.get('passwordEdit.passwordEdit').hasError('required')">
                  {{translation.shared.mustRequiredMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-col-12 flex-md-6">
              <mat-form-field>
                <mat-label>{{translation.happyWindow.passwordEditConfirm | transloco}}</mat-label>
                <input matInput formControlName="passwordEditConfirm" required type="password">
                <mat-error *ngIf="happyWindowForm.get('passwordEdit.passwordEditConfirm').hasError('required')">
                  {{translation.shared.mustRequiredMessage | transloco}}
                </mat-error>
                <mat-error *ngIf="happyWindowForm.get('passwordEdit.passwordEditConfirm').hasError('customErrorCode')">
                  {{translation.shared.confirmPasswordMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-grid">
            <div class="flex-col-12 flex-md-12">
              <mat-error *ngIf="happyWindowForm.get('samePassword').hasError('customErrorCode')">
                {{translation.shared.samePasswordMessage | transloco}}
              </mat-error>
            </div>
          </div>

        </form>
        <div>
          <button mat-raised-button matStepperNext>{{translation.button.next | transloco}}</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="windowGridForm">
        <form [formGroup]="windowGridForm">
          <ng-template matStepLabel>{{translation.happyWindow.settingGrid | transloco}}</ng-template>

          <div class="flex-grid">
            <div class="flex-col-12">
              <mat-form-field>
                <mat-label>{{translation.happyWindow.templateLayout | transloco}}</mat-label>
                <mat-select formControlName="template"
                            (selectionChange)="changeWindowGridTemplate($event)">
                  <mat-option *ngFor="let option of optionWindowGridTemplate" [value]="option.value">
                    {{option.viewValue | transloco}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="windowGridForm.get('template').hasError('required')">
                  {{translation.shared.mustRequiredMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-grid">
            <div class="flex-col-12">
              <mat-form-field>
                <mat-label>{{translation.window.backContent | transloco}}</mat-label>
                <mat-select formControlName="backgroundContent" required
                            (selectionChange)="changeWindowGridContent($event)">
                  <mat-option *ngFor="let option of optionWindowGridContent" [value]="option.value">
                    {{option.viewValue | transloco}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="windowGridForm.get('backgroundContent').hasError('required')">
                  {{translation.shared.requiredMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-col-12">
              <mat-form-field>
                <mat-label>{{translation.happyWindow.backgroundGrid | transloco}}</mat-label>
                <input *ngIf="windowGridForm.get('backgroundContent').value === 'color'"
                       matInput formControlName="background" required type="color">
                <ng-container *ngIf="windowGridForm.get('backgroundContent').value === 'image'">
                  <input disabled matInput required
                         [value]="windowGridForm.get('background').value ? (translation.shared.imageLoaded | transloco) : null">
                  <mat-icon aria-hidden="false" aria-label="add_photo_alternate" (click)="setImage()"
                            style="position: absolute; right: 0; top: 0;">
                    add_photo_alternate
                  </mat-icon>
                </ng-container>
                <div *ngIf="windowGridForm.get('background').hasError('required')">
                  {{translation.shared.mustRequiredMessage | transloco}}
                </div>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-grid">
            <div class="flex-col-12">
              <mat-form-field>
                <mat-label>{{translation.window.textColor | transloco}}</mat-label>
                <input matInput formControlName="textColor" required type="color">
                <mat-error *ngIf="windowGridForm.get('textColor').hasError('required')">
                  {{translation.shared.requiredMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-col-12">
              <mat-form-field>
                <mat-label>{{translation.window.borderColor | transloco}}</mat-label>
                <input matInput formControlName="borderColor" required type="color">
                <mat-error *ngIf="windowGridForm.get('borderColor').hasError('required')">
                  {{translation.shared.requiredMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-col-12">
              <mat-form-field>
                <mat-label>{{translation.window.contentColor | transloco}}</mat-label>
                <input matInput formControlName="contentColor" required type="color">
                <mat-error *ngIf="windowGridForm.get('contentColor').hasError('required')">
                  {{translation.shared.requiredMessage | transloco}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

        </form>
        <div>
          <button mat-raised-button matStepperPrevious>{{translation.button.back | transloco}}</button>
          <!--      <button mat-raised-button matStepperNext>{{translation.button.next | transloco}}</button>-->
          <button mat-raised-button (click)="create()">{{translation.button.createHappyWindow | transloco}}</button>
        </div>
      </mat-step>

    </mat-vertical-stepper>
  </div>
  <div class="flex-col-12 flex-md-6">
    <ng-template matStepLabel>{{translation.happyWindow.summary | transloco}}</ng-template>
    <div class="flex-grid">
      <div class="flex-col-12 flex-md-5">{{translation.happyWindow.name | transloco}}:</div>
      <div class="flex-col-12 flex-md-7 medium">{{happyWindowForm.get('name').value}}</div>
      <div class="flex-col-12 flex-md-5">{{translation.happyWindow.password | transloco}}:</div>
      <div class="flex-col-12 flex-md-7 medium">{{happyWindowForm.get('password.password').value}}</div>
      <div class="flex-col-12 flex-md-5">{{translation.happyWindow.passwordEdit | transloco}}:</div>
      <div class="flex-col-12 flex-md-7 medium">{{happyWindowForm.get('passwordEdit.passwordEdit').value}}</div>
    </div>

    <div class="flex-grid">
      <div class="flex-col-12 flex-md-5">{{translation.happyWindow.templateLayout | transloco}}:</div>
      <div class="flex-col-12 flex-md-7 medium" *ngIf="!windowGridForm.get('template').value">
        {{translation.happyWindow.customLayoutMessage | transloco}}
      </div>

      <div class="flex-col-12 flex-md-7 medium" *ngIf="windowGridForm.get('template').value">
        <div class="fake-layout" [style.margin-bottom]="'5px'"
             *ngFor="let row of [].constructor(windowGridForm.get('template').value.row); let i = index">
          <div class="fake-layout-item" [style.margin-right]="'5px'"
               *ngFor="let col of [].constructor(windowGridForm.get('template').value.col); let j = index">
            {{j}}
          </div>
        </div>
      </div>

      <div class="flex-col-12 flex-md-5">{{translation.happyWindow.backgroundGrid | transloco}}:</div>
      <div class="flex-col-12 flex-md-7 medium" *ngIf="windowGridForm.get('backgroundContent').value === 'color'">
        <span style="display: inline-block; height: 15px; width: 15px;"
              [style.background-color]="windowGridForm.get('background').value"></span>
        <!--        {{windowGridForm.get('background').value}}-->
      </div>
      <div class="flex-col-12 flex-md-7 medium" *ngIf="windowGridForm.get('backgroundContent').value === 'image'">
        <div class="background-content-image"
             [ngStyle]="{'background-image': 'url(' + windowGridForm.get('background').value + ')'}"
             style="height: 5em; width: 5em;"></div>
      </div>

      <div class="flex-col-12 flex-md-5">{{translation.happyWindow.backgroundContentGrid | transloco}}:</div>
      <div class="flex-col-12 flex-md-7 medium">
        {{getBackgroundContent(windowGridForm.get('backgroundContent').value) | transloco}}
      </div>

      <div class="flex-col-12 flex-md-5">{{translation.window.borderColor | transloco}}
        /{{translation.window.textColor | transloco}}:
      </div>
      <div class="flex-col-12 flex-md-7 medium border-text-color"
           [style.border]="'1px inset '+ windowGridForm.get('borderColor').value">
        <span [style.color]="windowGridForm.get('textColor').value">Text</span>
      </div>

      <div class="flex-col-12" style="margin-bottom: .5em; padding: 0"></div>

      <div class="flex-col-12 flex-md-5">{{translation.window.contentColor | transloco}}:</div>
      <div class="flex-col-12 flex-md-7 medium border-content-color">
        <span
          [style.color]="windowGridForm.get('contentColor').value">{{translation.window.contentTextColor | transloco}}</span>
      </div>
    </div>
  </div>
</div>
