<div class="hw-window-grid" #hwWindowGrid>
  <fw-loading-panel [storedPack]="windowsState$">
    <ng-template fwLoadingDataTemplate let-windows>

      <hw-window *ngFor="let window of windows; trackBy: trackByFn" [windowIdentifyPack]="window"
                 (changeFocus)="changeFocus($event)" (remove)="remove($event)"
                 (changePosition)="changePosition($event)" (changeSize)="changeSize($event)"
                 (switchPage)="switchOnePage($event)" [style.zoom]="windowZoom">
      </hw-window>

    </ng-template>
  </fw-loading-panel>
</div>
<div class="action-toolbar" *ngIf="showWindowGridSetting">
  <button mat-icon-button color="primary" aria-label="menu icon"
          (click)="changeWindowGridSetting($event)">
    <mat-icon>settings</mat-icon>
  </button>
  <!--  <button mat-icon-button color="warn" aria-label="menu icon">-->
  <!--    <mat-icon>format_paint</mat-icon>-->
  <!--  </button>-->
</div>

