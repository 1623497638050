<form [formGroup]="windowGridForm">

  <div class="flex-grid">
    <div class="flex-col-12">
      <h3>{{translation.happyWindow.manageBackground | transloco}} - {{windowGridForm.get('name').value}}</h3>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field>
        <mat-label>{{translation.happyWindow.backgroundContentGrid | transloco}}</mat-label>
        <mat-select formControlName="backgroundContent" required (selectionChange)="changeBackgroundContent($event)">
          <mat-option *ngFor="let option of optionWindowGridContent" [value]="option.value">
            {{option.viewValue | transloco}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="windowGridForm.get('backgroundContent').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field>
        <mat-label>{{translation.window.content | transloco}}</mat-label>
        <input *ngIf="windowGridForm.get('backgroundContent').value === 'color'"
               matInput formControlName="background" required type="color">
        <ng-container *ngIf="windowGridForm.get('backgroundContent').value === 'image'">
          <input disabled
                 matInput
                 [value]="windowGridForm.get('background').value ? (translation.shared.imageLoaded | transloco) : null"
                 required>
          <mat-icon aria-hidden="false" aria-label="add_photo_alternate" (click)="setImage()"
                    style="position: absolute; right: 0; top: 0;">
            add_photo_alternate
          </mat-icon>
        </ng-container>
        <mat-error *ngIf="windowGridForm.get('background').hasError('required')">
          {{translation.shared.requiredMessage | transloco}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="flex-grid">
    <div class="flex-col-12">
      <h3>{{translation.shared.manageWaringMessages | transloco}}</h3>
    </div>
    <div class="flex-col-12">
      <button mat-button color="warn" (click)="manageWaringMessages()">
        <mat-icon>settings</mat-icon>
        {{translation.shared.management | transloco}}
      </button>
    </div>
  </div>

  <!--  FIXME JN dyt to zarovnaný dole máš :) - TO MAM, ale cekal jsem, ze mi ten style opravis nejak lip :-D -->
  <div class="flex-grid" style="bottom: 0;position: absolute; width: 95%;">
    <div class="flex-col-12">
      <h3>{{translation.happyWindow.management | transloco}}</h3>
    </div>
    <div class="flex-col-12 flex-md-6">
      <mat-form-field>
        <mat-label>{{translation.happyWindow.templateLayout | transloco}}</mat-label>
        <mat-select [(ngModel)]="selectedWindowGridTemplate" (selectionChange)="changeWindowGridTemplate($event)"
                    [ngModelOptions]="{standalone: true}">
          <mat-option *ngFor="let option of optionWindowGridTemplate" [value]="option.value">
            {{option.viewValue | transloco}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex-col-12 flex-md-6">
      <button class="pull-right" mat-stroked-button color="warn" (click)="removeHappyWindow()">
        {{translation.button.removeHappyWindow | transloco}}
      </button>
    </div>
  </div>

</form>
