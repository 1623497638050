import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import * as Croppie from 'croppie';
import {CroppieDirective} from 'angular-croppie-module';
import {SizeModel} from '@happy-windows/api-interfaces';
import {translation} from "../../i18n";

@Component({
  selector: 'fw-croppie',
  templateUrl: './croppie.component.html',
  styleUrls: ['./croppie.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CroppieComponent implements AfterViewInit, OnChanges {

  translation = translation;

  private originalFile: File;
  croppieOptions: Croppie.CroppieOptions = {
    // https://foliotek.github.io/Croppie/#documentation Options
    enableExif: true,
    viewport: {
      width: 100,
      height: 100,
      type: 'square'
    },
    boundary: {
      width: 200,
      height: 200
    }
  };

  @Input()
  size: SizeModel = {width: 100, height: 100};

  @ViewChild('croppie', {static: false})
  public croppieDirective: CroppieDirective;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const tmpSize = changes['size'];
    if (tmpSize) {
      this.croppieOptions = {
        ...this.croppieOptions, viewport: {
          type: this.croppieOptions.viewport.type,
          width: tmpSize.currentValue.width,
          height: tmpSize.currentValue.height
        },
        boundary: {
          height: tmpSize.currentValue.height + 100,
          width: tmpSize.currentValue.width + 100
        }
      }
    }
  }

  ngAfterViewInit() {
    // https://foliotek.github.io/Croppie/#documentation Methods
    this.croppieDirective.croppie.bind({
      url: 'assets/image/missing_product.png',
    });
  }

  handleUpdate(event) {
    // console.log(event);
  }

  getResult() {
    return this.croppieDirective.croppie.result({type: 'base64', quality: 50});
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        if (!file) {
          return;
        }
        if (['image/gif', 'image/jpeg', 'image/png'].indexOf(file.type) === -1) {
          return Error('Invalid input file type');
        }
        const reader = new FileReader();
        reader.onload = () => {
          this.croppieDirective.croppie.bind({url: reader.result as string});
        };
        this.originalFile = file;
        reader.readAsDataURL(this.originalFile);
      }
    };
    fileUpload.click();
  }
}
