import {ChangeDetectionStrategy, Component} from '@angular/core';
import {translation} from '../../../i18n';
import {WindowGridState, WindowState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {DialogService} from '../../../providers';
import {CreateHappyWindowDialogComponent} from '../../create-happy-window-dialog/create-happy-window-dialog.component';
import {ChangeWindowsMode, LoadExampleWindowGrid, LoadWindowGridByName} from '../../../actions';
import {getWindowGrid} from '../../../selectors';
import {Observable} from 'rxjs';
import {getRuntimeInfo, PwaService, RuntimeInfoModel, RuntimeService} from '@happy-windows/framework/core';
import {RIGHT_PANEL_STATE} from '../../../const';
import {LocalStateService} from '@happy-windows/framework/local-state';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'hw-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {

  translation = translation;
  windowGrid$: Observable<WindowGridState>;
  runtimeInfo$: Observable<RuntimeInfoModel>;

  testovaniQuillu: string;

  constructor(private store: Store<WindowState>,
              private runtimeService: RuntimeService,
              private pwaService: PwaService,
              private localStateService: LocalStateService,
              private dialog: DialogService) {
    if (!runtimeService.isLoggedIn()) {
      this.store.dispatch(LoadExampleWindowGrid());
      this.store.dispatch(ChangeWindowsMode({mode: 'example'}));
    } else {
      this.store.dispatch(LoadWindowGridByName({name: runtimeService.getLogin()}));
      this.store.dispatch(ChangeWindowsMode({mode: runtimeService.getRuntimeInfo().isEdit ? 'example' : 'run'}));
    }
    this.localStateService.setState(RIGHT_PANEL_STATE, null);
    this.windowGrid$ = this.store.select(getWindowGrid);
    this.runtimeInfo$ = this.store.select(getRuntimeInfo);
  }

  openCreateHappyWindowDialog() {
    const dialogRef = this.dialog.open(CreateHappyWindowDialogComponent, {
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  askInstallPwa() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translation.shared.pwa,
        message: this.translation.shared.pwaMessage
      }
    }).afterClosed().subscribe(x => {
      if (x === 'yes') {
        this.pwaService.askPwa();
      }
    });
  }

}
