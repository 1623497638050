import {Directive} from '@angular/core';

@Directive({
  selector: '[fwLoadingErrorTemplate]'
})
export class LoadingErrorTemplateDirective {

  constructor() {
  }

}
