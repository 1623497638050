import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormBuilder, ValidatorFn} from '@angular/forms';
import {FwFormGroup} from './fw-form-group';
import {FwFormArray} from './fw-form-array';
import {FwFormControl} from './fw-form-control';

@Injectable({
  providedIn: 'root'
})
export class FwFormBuilder extends FormBuilder {
  /**
   * @description
   * Construct a new `FormGroup` instance.
   *
   * @param controlsConfig A collection of child controls. The key for each child is the name
   * under which it is registered.
   *
   * @param extra An object of configuration options for the `FormGroup`.
   * * `validator`: A synchronous validator function, or an array of validator functions
   * * `asyncValidator`: A single async validator or array of async validator functions
   *
   */
  fwGroup<T>(controlsConfig: { [key: string]: any }, extra: { [key: string]: any } | null = null): FwFormGroup<T> {
    const controls = this._reduceControls(controlsConfig);
    const validator: ValidatorFn = extra != null ? extra['validator'] : null;
    const asyncValidator: AsyncValidatorFn = extra != null ? extra['asyncValidator'] : null;
    return new FwFormGroup<T>(controls, validator, asyncValidator);
  }

  /**
   * @description
   * Construct a new `FormControl` instance.
   *
   * @param formState Initializes the control with an initial value,
   * or an object that defines the initial value and disabled state.
   *
   * @param validator A synchronous validator function, or an array of synchronous validator
   * functions.
   *
   * @param asyncValidator A single async validator or array of async validator functions
   *
   * @usageNotes
   *
   * ### Initialize a control as disabled
   *
   * The following example returns a control with an initial value in a disabled state.
   *
   * <code-example path="forms/ts/formBuilder/form_builder_example.ts"
   *   linenums="false" region="disabled-control">
   * </code-example>
   *
   */
  fwControl<T>(
    formState?: any, validator?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null): FwFormControl<T> {
    return new FwFormControl(formState, validator, asyncValidator);
  }

  /**
   * @description
   * Construct a new `FormArray` instance.
   *
   * @param controlsConfig An array of child controls. The key for each child control is its index
   * in the array.
   *
   * @param validator A synchronous validator function, or an array of synchronous validator
   * functions.
   *
   * @param asyncValidator A single async validator or array of async validator functions
   */
  fwArray<T>(
    controlsConfig: any[], validator?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null): FwFormArray<T> {
    const controls = controlsConfig.map(c => this._createControl(c));
    return new FwFormArray(controls, validator, asyncValidator);
  }

  /** @internal */
  private _reduceControls(controlsConfig: { [k: string]: any }): { [key: string]: AbstractControl } {
    const controls: { [key: string]: AbstractControl } = {};
    Object.keys(controlsConfig).forEach(controlName => {
      controls[controlName] = this._createControl(controlsConfig[controlName]);
    });
    return controls;
  }

  /** @internal */
  private _createControl(controlConfig: any): AbstractControl {
    if (controlConfig instanceof FwFormControl || controlConfig instanceof FwFormGroup ||
      controlConfig instanceof FwFormArray) {
      return controlConfig;

    } else if (Array.isArray(controlConfig)) {
      const value = controlConfig[0];
      const validator: ValidatorFn = controlConfig.length > 1 ? controlConfig[1] : null;
      const asyncValidator: AsyncValidatorFn = controlConfig.length > 2 ? controlConfig[2] : null;
      return this.fwControl(value, validator, asyncValidator);

    } else {
      return this.fwControl(controlConfig);
    }
  }
}
