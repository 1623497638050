import {createReducer, on} from "@ngrx/store";
import {WindowContentModel} from '@happy-windows/api-interfaces';
import {InitContentWindow, ToggledContentWindow} from '../actions';


const initialState: WindowContentModel = {
  window: null,
  content: null,
  contentType: 'text'
};

export const windowContentReducer = createReducer(
  initialState,

  on(InitContentWindow,
    (state) =>
      (
        {
          ...state,
          ...initialState
        }
      )
  ),

  on(ToggledContentWindow,
    (state, {window}) =>
      (
        {
          ...state,
          window: window,
          contentType: 'text',
          content: {
            text: window.text,
            textColor: window.textColor
          } as any
        }
      )
  )
);
