import {createFeatureSelector, createSelector} from '@ngrx/store';
import {WindowContentModel} from '@happy-windows/api-interfaces';

const getWindowContentState = createFeatureSelector<WindowContentModel>('windowContent');


export const showContentWindow = createSelector(
  getWindowContentState,
  (windowContent) => windowContent.content && windowContent.window ? windowContent : null
);
