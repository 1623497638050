<div class="welcome-page flex-grid flex-align-center vertical-container" *fwLet="runtimeInfo$ | async as runtimeInfo">
  <div class="col-left flex-col-12 flex-md-6 main-content mobile-order-2">
    <hw-window-grid *ngIf="windowGrid$ | async as windowGrid" [windowGrid]="windowGrid"
                    [showWindowGridSetting]="false">
    </hw-window-grid>
  </div>
  <ng-container *ngIf="!runtimeInfo.id">
    <!--    JEDNA SE O REZIM, KDY NENI NIDKO PRIHLASENEJ -->
    <div class="col-right flex-col-12 flex-md-6 mobile-order-1">
      <div>
        <h1>HappyWindow<sup>&reg;</sup></h1><!-- &copy; &trade; -->
        <h2 class="text-orange-400">Nejlepší dárek pro vaše blízké</h2>
        <p class="m-b-40">Vytvoř pro někoho na kom Ti záleží HappyWindow adventní kalendář nebo originální narozeninové
          přání.</p>
        <button mat-raised-button (click)="openCreateHappyWindowDialog()" class="create-happy-window-btn">
          {{translation.layout.createHappyWindow | transloco}}
        </button>
        <mat-icon class="play-icon-btn">play_circle_filled</mat-icon>
      </div>
    </div>
    <div class="col-mobile flex-col-12 mobile-order-3">
      <h2 class="text-blue-grey-400">Pro vytvoření HappyWindow, použijte stolní počítač/notebook.</h2>
      <p>Nam quis nulla. Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Fusce tellus. Nunc auctor.
        Pellentesque sapien.</p>
    </div>
    <div class="col-mobile flex-col-12 mobile-order-4">
      <h2 class="text-blue-grey-400">Pro jeho vyzvednutí si naistalujte aplikaci do telefonu.</h2>
      <img src="assets/image/download-app-store.svg" style="width: 80vw" (click)="askInstallPwa()"/>
      <img src="assets/image/download-google-play.svg" style="width: 80vw" (click)="askInstallPwa()"/>
    </div>
  </ng-container>

  <ng-container *ngIf="runtimeInfo.id && runtimeInfo.isEdit">
    <!--    JEDNA SE O REZIM, KDY JSEM PRIHLASENY A JSEM V EDITACNIM REZIMU -->
    <div class="col-right flex-col-12 flex-md-6 mobile-order-1">
      <quill-editor [(ngModel)]="testovaniQuillu"
                    [placeholder]="translation.presentation.quillPlaceholder | transloco"></quill-editor>
      <quill-view [content]="testovaniQuillu"></quill-view>
    </div>
  </ng-container>

  <ng-container *ngIf="runtimeInfo.id && !runtimeInfo.isEdit">
    <!--    JEDNA SE O REZIM, KDY JSEM PRIHLASENY A JSEM V READONLY/RUNTIME REZIMU -->
    <div class="col-right flex-col-12 flex-md-6 mobile-order-1">
    </div>
  </ng-container>
</div>
