import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {
  ChangeWindowsMode,
  CreateWindowGrid,
  CreateWindowGridError,
  CreateWindowGridSuccess,
  CreateWindows,
  InitWarningMessages,
  InitWindow,
  InitWindowGrid,
  LoadExampleWindowGrid,
  LoadWindowGridByName,
  LoadWindowGridByNameError,
  LoadWindowGridByNameSuccess,
  LoginWindowGrid,
  LogoutWindowGrid,
  RemoveWindowGrid,
  RemoveWindowGridError,
  RemoveWindowGridSuccess,
  UpdateWindowGrid,
  UpdateWindowGridError,
  UpdateWindowGridSuccess
} from '../actions';
import {concatMap, filter, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {SnackBarService, WindowGridService} from '../providers';
import {InitRuntimeInfo, LoadRuntimeInfo, RuntimeService} from '@happy-windows/framework/core';
import {of, zip} from 'rxjs';
import {translation} from "../i18n";
import {Router} from '@angular/router';
import {SetLocalState} from '@happy-windows/framework/local-state';
import {DESIGNER_MODE} from '../const';
import {Store} from '@ngrx/store';

@Injectable()
export class WindowGridEffect {

  translation = translation;

  /**
   * Nacte se ukazkovy kalendar (ten uplne uvodni)
   */
  loadExampleWindowGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadExampleWindowGrid),
      switchMap(_ => {
        return this.windowGridService.loadExampleHappyWindow().pipe(
          map(env => {
              if (env.success) {
                return LoadWindowGridByNameSuccess({windowGrid: env.data});
              } else {
                return LoadWindowGridByNameError({error: env.error});
              }
            }
          )
        )
      })
    )
  );

  /**
   * Klasicky prihlaseni
   */
  loginWindowGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginWindowGrid),
      concatMap(({name, password, isPwa}) => {
        return this.windowGridService.loginHappyWindow(name, password).pipe(
          mergeMap(env => {
              if (env.success) {
                this.runtimeService.setToken(env.data.access_token);
                const runtimeInfo = this.runtimeService.getRuntimeInfo();
                if (runtimeInfo.isEdit && isPwa) {
                  // pokud pouziju heslo pro editaci a jsem na PWA -> reknu mu, ze se neda prihlasit
                  this.runtimeService.removeToken();
                  this.snackBarService.openSnackBar(translation.shared.loginMessageError, 'warn');
                  return [LogoutWindowGrid({isPwa: isPwa})];
                }
                this.runtimeService.setLogin(runtimeInfo.name);
                this.snackBarService.openSnackBar(translation.shared.loginMessageSuccess);
                this.router.navigate([isPwa ? '/pwa/pwa' : '/home']);
                if (isPwa) {
                  return [
                    LoadRuntimeInfo({
                      id: runtimeInfo.id,
                      isEdit: runtimeInfo.isEdit,
                      name: runtimeInfo.name
                    }),
                    SetLocalState({
                      key: DESIGNER_MODE,
                      payload: 'run'
                    })
                  ];
                }
                return [
                  LoadRuntimeInfo({
                    id: runtimeInfo.id,
                    isEdit: runtimeInfo.isEdit,
                    name: runtimeInfo.name
                  }),
                  LoadWindowGridByName({name: runtimeInfo.name}),
                  SetLocalState({
                    key: DESIGNER_MODE,
                    payload: runtimeInfo.isEdit ? 'example' : 'run'
                  })
                ];
              } else {
                this.snackBarService.openSnackBar(translation.shared.loginMessageError, 'warn');
                return [LogoutWindowGrid({isPwa: isPwa})];
              }
            }
          )
        )
      })
    )
  );


  loadWindowGridName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadWindowGridByName),
      concatMap(({name}) => {
        return this.windowGridService.getHappyWindowByName(name).pipe(
          map(env => {
              if (env.success) {
                return LoadWindowGridByNameSuccess({windowGrid: env.data})
              }
              return LoadWindowGridByNameError({error: env.error})
            }
          )
        )
      })
    )
  );

  /**
   * Prihlaseni po vytvoreni happy windows
   */
  loginWindowGridAfterCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateWindowGridSuccess),
      concatMap(({windowGrid}) => {
        return this.windowGridService.loginHappyWindow(windowGrid.name, windowGrid.passwordEdit).pipe(
          switchMap(env => {
              if (env.success) {
                this.runtimeService.setToken(env.data.access_token);
                const runtimeInfo = this.runtimeService.getRuntimeInfo();
                this.runtimeService.setLogin(runtimeInfo.name);
                this.snackBarService.openSnackBar(translation.happyWindow.createHappyWindowSuccess);
                return of(LoadRuntimeInfo({
                  id: runtimeInfo.id,
                  isEdit: runtimeInfo.isEdit,
                  name: runtimeInfo.name
                }));
              } else {
                this.snackBarService.openSnackBar(translation.happyWindow.createHappyWindowError, 'warn');
                return of(LogoutWindowGrid({isPwa: false}));
              }
            }
          )
        )
      })
    )
  );

  // Presmerovani na zaklade prihlaseni, zalezi o jaky heslo jde, teoreticky to je kravina
  // loadRuntimeInfo$ = createEffect(() => this.actions$.pipe(
  //   ofType(LoadRuntimeInfo),
  //   tap(({isEdit}) => {
  //     if (isEdit) {
  //       this.router.navigate(['/designer']);
  //     } else {
  //       this.router.navigate(['/home']);
  //     }
  //   }),
  //   take(1)
  // ), {dispatch: false});

  /**
   * Ohlaseni happy window
   */
  logoutWindowGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LoginWindowGrid,
        LogoutWindowGrid,
        CreateWindowGrid,
        RemoveWindowGridSuccess
      ),
      tap((action) => {
        if (action.type === LogoutWindowGrid.type) {
          this.runtimeService.signOut(action.isPwa, true);
        } else if (action.type === RemoveWindowGridSuccess.type) {
          this.runtimeService.signOut(false, true);
        }
      }),
      mergeMap((_) => {
        return [InitRuntimeInfo(), InitWindowGrid(), InitWindow(), InitWarningMessages()];
      })
    )
  );

  loginExampleWindowGrid$ = createEffect(() =>
    this.actions$.pipe(
      filter((action) => action.type === LogoutWindowGrid.type || action.type === RemoveWindowGridSuccess.type),
      switchMap((action) =>
        zip(
          this.actions$.pipe(ofType(InitRuntimeInfo)),
          this.actions$.pipe(ofType(InitWindowGrid)),
          this.actions$.pipe(ofType(InitWindow)),
          this.actions$.pipe(ofType(InitWarningMessages)),
          of(action) // hack je v tom, ze musim pockat i na tu akci, ktera me zajima
        ).pipe(
          // tap(([a, b, c, d, e]) => console.log(e)),
          mergeMap(([a, b, c, d, e]) => {
              if (e.type === LogoutWindowGrid.type && (e as any).isPwa) {
                return [ChangeWindowsMode({mode: 'run'})];
              }
              return [
                LoadExampleWindowGrid(),
                ChangeWindowsMode({mode: 'example'})
              ];
            }
          )
        )
      )
    )
  );

  createWindowGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateWindowGrid),
      concatMap(({windowGrid, windows}) => this.windowGridService.createWindowGrid(windowGrid).pipe(
        mergeMap(env => env.success
          ? (windows === null
              ? [CreateWindowGridSuccess({windowGrid: env.data})]
              : [CreateWindowGridSuccess({windowGrid: env.data}), CreateWindows({windows: windows})]
          )
          : [CreateWindowGridError({error: env.error})]
        ))
      )
    )
  );

  updateWindowGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateWindowGrid),
      concatMap(({windowGrid}) => this.windowGridService.upsertWindowGrid(windowGrid).pipe(
        map(env => {
          if (env.success) {
            return UpdateWindowGridSuccess({windowGrid: env.data});
          } else {
            this.snackBarService.openSnackBar(translation.happyWindow.updateWindowGridError, 'warn');
            return UpdateWindowGridError({id: windowGrid.id, error: env.error});
          }
        })
        )
      )
    )
  );

  removeWindowGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveWindowGrid),
      concatMap(({windowGrid}) => this.windowGridService.removeWindowGrid(windowGrid).pipe(
        map(env => {
          if (env.success) {
            this.snackBarService.openSnackBar(translation.happyWindow.removeHappyWindowSuccess, 'success');
            return RemoveWindowGridSuccess({windowGrid: env.data});
          } else {
            this.snackBarService.openSnackBar(translation.happyWindow.removeHappyWindowError, 'warn');
            return RemoveWindowGridError({id: windowGrid.id, error: env.error});
          }
        })
        )
      )
    )
  );

  constructor(private actions$: Actions, private windowGridService: WindowGridService,
              private router: Router, private store: Store<any>,
              private runtimeService: RuntimeService, private snackBarService: SnackBarService) {
  }

}
