import {Injectable} from "@angular/core";
import {ApiService, ConfigService, IdentifiedPack} from '@happy-windows/framework/core';
import {WarningMessageModel} from '@happy-windows/api-interfaces';

@Injectable({
  providedIn: 'root'
})
export class WarningMessageService {

  constructor(private apiService: ApiService,
              private configService: ConfigService) {
  }


  getExampleWarningMessage() {
    return this.apiService.get<any, WarningMessageModel[]>(`${this.configService.value.apiUrls.base}warning/load/example`,
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x)));
  }

  getWarningMessageByCalendarName(calendarName: string) {
    return this.apiService.get<any, WarningMessageModel[]>(`${this.configService.value.apiUrls.base}warning/calendar-name/${calendarName}`,
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x)));
  }

  upsertWarningMessage(warningMessage: WarningMessageModel) {
    return this.apiService.post<any, WarningMessageModel>(`${this.configService.value.apiUrls.base}warning`,
      this.convertToDb(warningMessage),
      rawResponse => this.convertFromDb(rawResponse.data));
  }

  removeWarningMessage(warningMessage: WarningMessageModel) {
    return this.apiService.delete<any, WarningMessageModel>(`${this.configService.value.apiUrls.base}warning/${warningMessage.id}`);
  }

  saveManyWarningMessages(warningMessages: WarningMessageModel[], calendarName: string) {
    return this.apiService.post<any, WarningMessageModel[]>(`${this.configService.value.apiUrls.base}warning/many`,
      {warnings: warningMessages.map(x => this.convertToDb(x)), calendarName: calendarName},
      rawResponse => rawResponse.data.map(x => this.convertFromDb(x)));
  }


  private convertFromDb(message: any): WarningMessageModel {
    return {
      id: message.id,
      idName: message.idName,
      text: message.text,
      opened: false
    }
  }

  private convertToDb(message: WarningMessageModel): any {
    return {
      id: message.id,
      idName: message.idName,
      text: message.text,
    }
  }

  // vybere nahodne nejakou warning message
  getWarningMessage(warningMessages: IdentifiedPack<WarningMessageModel>[]): WarningMessageModel {
    if (warningMessages.length === 0) {
      return {
        id: 0,
        idName: "null",
        opened: false,
        text: "This window is not for today! :-)"
      };
    }
    const tmpWarningMessages = warningMessages.map(x => x.data);
    return tmpWarningMessages[Math.floor(Math.random() * warningMessages.length)];
  }
}
