import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';
import {translation} from '../../../i18n';
import {WindowGridState, WindowState} from '../../../reducers';
import {Store} from '@ngrx/store';
import {DialogService} from '../../../providers';
import {CreateHappyWindowDialogComponent} from '../../create-happy-window-dialog/create-happy-window-dialog.component';
import {ChangeWindowsMode, LoadWindowGridByName, LogoutWindowGrid} from '../../../actions';
import {getWindowGrid} from '../../../selectors';
import {Observable} from 'rxjs';
import {RuntimeService} from '@happy-windows/framework/core';
import {BACKGROUND_COLOR, GRID, RIGHT_PANEL_STATE} from '../../../const';
import {LocalStateService} from '@happy-windows/framework/local-state';
import {tap} from 'rxjs/operators';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {TranslocoService} from '@happy-windows/framework/translate';

@Component({
  selector: 'hw-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PwaComponent {

  translation = translation;
  windowGrid$: Observable<WindowGridState>;
  background: SafeStyle;

  language: string;
  swipeClass: 'swipe-left' | 'swipe-right' | 'swipe-tap' = 'swipe-left';

  constructor(private store: Store<WindowState>,
              public runtimeService: RuntimeService,
              private translocoService: TranslocoService,
              private localStateService: LocalStateService,
              private dialog: DialogService,
              private sanitizer: DomSanitizer) {
    this.language = runtimeService.getLanguage();
    this.store.dispatch(LoadWindowGridByName({name: runtimeService.getLogin()}));
    this.store.dispatch(ChangeWindowsMode({mode: 'run'}));
    this.localStateService.setState(RIGHT_PANEL_STATE, null);
    this.windowGrid$ = this.store.select(getWindowGrid).pipe(
      tap(windowGrid => {
        if (windowGrid && windowGrid.background) {
          let background: string;
          if (windowGrid.backgroundContent === 'color') {
            background = `background-color: ${windowGrid.background}`;
          } else {
              background = `background-image: url(${windowGrid.background}); background-size: cover;`;
            }
            this.background = this.sanitizer.bypassSecurityTrustStyle(background);
          }
        }
      )
    );
  }

  @HostBinding("style")
  get styleBackground(): SafeStyle {
    if (this.background) {
      return this.background;
    }
    return this.sanitizer.bypassSecurityTrustStyle(`background-color: ${BACKGROUND_COLOR}`);
  };

  onSwipe(event, type: 'left' | 'right' | 'tap') {
    if (type === 'left' || type === 'tap') {
      this.swipeClass = 'swipe-left';
    } else {
      this.swipeClass = 'swipe-right';
    }
  }

  openCreateHappyWindowDialog() {
    const dialogRef = this.dialog.open(CreateHappyWindowDialogComponent, {
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  // funkce vypocita novy rozmer okna, pro umisteni oken na zaklade skutecneho rozliseni telefonu
  calculateZoom(): { width: number, height: number, zoom: number } {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if (!width && !height) {
      return {
        width: GRID.width,
        height: GRID.height,
        zoom: 1
      }
    }
    const tmpSmallSize = height < width ? height : width;
    const tmpBigSize = height > width ? height : width;

    let tmpSize = tmpSmallSize;
    while ((tmpSize * 2) > tmpBigSize) {
      tmpSize = tmpSize - 5; // po kolika budu odcitat, nez najdu spravny rozmer
    }
    // pokud je mensi rozmer rozliseni obrazovny mensi nez vypoctenej  && je mensi nez definovany v designeru -> vem ten mensi dle rozliseni
    // resi se to hlavne pro phone se/5
    const tmpResultSize = tmpSize < tmpSmallSize && tmpSize < GRID.width ? tmpSmallSize : tmpSize;
    return {
      width: tmpResultSize,
      height: tmpResultSize * 2,
      zoom: (tmpResultSize * 100 / (height < width ? GRID.height : GRID.width)) / 100
    }

  }

  switchLanguage() {
    this.language = this.language === 'cs' ? 'en' : 'cs';
    this.runtimeService.setLanguage(this.language);
    this.translocoService.setActiveLang(this.language);
  }

  logout() {
    this.store.dispatch(LogoutWindowGrid({isPwa: true}));
  }

}
