import {ChangeDetectionStrategy, Component, ElementRef} from '@angular/core';
import {RuntimeService} from '@happy-windows/framework/core';
import {translation} from '../../i18n';
import {TranslocoService} from '@happy-windows/framework/translate';

@Component({
  selector: "hw-app-pwa",
  template: `
      <router-outlet></router-outlet>
      <mat-slide-toggle *ngIf="!runtimeService.isLoggedIn()" [ngModel]="language"
                        (ngModelChange)="switchLanguage($event)">
          {{language ? (translation.language.cs | transloco) : (translation.language.en | transloco)}}
      </mat-slide-toggle>
  `,
  styleUrls: ['./app-pwa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppPwaComponent {
  translation = translation;

  constructor(private translocoService: TranslocoService,
              private elementRef: ElementRef,
              public runtimeService: RuntimeService) {
    let language = runtimeService.getLanguage();
    if (language) {
      this.translocoService.setActiveLang(language);
    } else {
      language = translocoService.getActiveLang();
    }
    this.runtimeService.setLanguage(language);

    // window.history.pushState(null, null, window.location.pathname);
    // window.addEventListener("popstate", (e: any) => {
    //   e.preventDefault();
    //   return;
    //   //alert("popstate: " + document.location + ", state: " + JSON.stringify(e));
    //   // window.history.forward();
    // });

    // window.onpopstate = function(event) {
    //   event.preventDefault();
    //   alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
    // };


    // zajisti aby neslo delat swipe back na ios
    // https://pqina.nl/blog/blocking-navigation-gestures-on-ios-13-4/
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      this.elementRef.nativeElement.addEventListener('touchstart', (e: any) => {
        // is not near edge of view, exit
        // console.log(e.pageX, window.innerWidth);
        if (e.pageX > 20 && e.pageX < window.innerWidth - 10) {
          // console.log("return");
          return;
        }
        console.log("touchstart", e.pageX, window.innerWidth);
        // console.log("prevent");
        // prevent swipe to navigate gesture
        e.preventDefault();
      });

      this.elementRef.nativeElement.addEventListener('touchmove', (e: any) => {
        // is not near edge of view, exit
        if (e.pageX > 20 && e.pageX < window.innerWidth - 10) {
          // console.log("return");
          return;
        }
        console.log("touchmove", e.pageX, window.innerWidth);
        // console.log("prevent");
        // prevent swipe to navigate gesture
        e.preventDefault();
      });
    }
  }

  switchLanguage(event) {
    const language = event ? 'cs' : 'en';
    this.runtimeService.setLanguage(language);
    this.translocoService.setActiveLang(language);
  }

  get language(): boolean {
    return this.runtimeService.getLanguage() === 'cs';
  }

}
