import * as components from './components';
import * as effects from './effects';
import {FrameworkSharedModule} from '@happy-windows/framework/shared';
import {FrameworkTranslateModule} from '@happy-windows/framework/translate';
import {localizations} from './i18n';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AngularResizeElementModule} from 'angular-resize-element';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {StoreModule} from '@ngrx/store';
import {warningMessageReducer, windowContentReducer, windowGridReducer, windowReducer} from './reducers';
import {EffectsModule} from '@ngrx/effects';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatListModule} from '@angular/material/list';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatStepperModule} from '@angular/material/stepper';
import {LocalStateModule} from '@happy-windows/framework/local-state';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {QuillModule} from 'ngx-quill';
import * as Hammer from 'hammerjs';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {Injectable} from '@angular/core';

export const IMPORTS = [
  DragDropModule,
  AngularResizeElementModule,
  FrameworkSharedModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatIconModule,
  MatListModule,
  MatDialogModule,
  MatToolbarModule,
  MatSidenavModule,
  MatGridListModule,
  MatStepperModule,
  MatSnackBarModule,
  LocalStateModule,
  HammerModule,
  FrameworkTranslateModule.forFeature(localizations),
  StoreModule.forFeature('windowGrid', windowGridReducer),
  StoreModule.forFeature('window', windowReducer),
  StoreModule.forFeature('windowContent', windowContentReducer),
  StoreModule.forFeature('warningMessage', warningMessageReducer),
  EffectsModule.forFeature([
    effects.WindowEffect,
    effects.WarningMessageEffect,
    effects.WindowGridEffect
  ]),
  QuillModule.forRoot({ // https://github.com/KillerCodeMonkey/ngx-quill
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        ['blockquote'], // 'code-block'
        // [{'header': 1}, {'header': 2}],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        // [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{'align': []}],
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        // [{'direction': 'rtl'}],                         // text direction
        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'font': []}],
        ['clean'],                                         // remove formatting button
        // ['link', 'image', 'video']                         // link and image, video
      ]
    }
  })
];
export const COMPONENTS = [
  components.AppPwaComponent,
  components.PwaComponent,
  components.LoginComponent,
  components.AppLayoutComponent,
  components.HomeComponent,
  components.DesignerComponent,
  components.WindowComponent,
  components.WindowGridComponent,
  components.WindowSettingComponent,
  components.WindowActionComponent,
  components.WindowTestingComponent,
  components.WindowContentDialogComponent,
  components.CroppieDialogComponent,
  components.ConfirmDialogComponent,
  components.WarningMessagesDialogComponent,
  components.WarningMessageDialogComponent,
  components.WindowGridSettingComponent,
  components.CreateHappyWindowDialogComponent
];

export const EXPORTS = [];

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: {direction: Hammer.DIRECTION_ALL},
  };
}

export const PROVIDERS = [
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }
];
