<!--   FIXME JT nastylovat confirm dialog, tak by dobre vypadal, kdyz ma title a i kdyz ho nema-->
<h2 *ngIf="data && data.title">
  {{data.title | transloco}}
</h2>
<mat-dialog-content class="mat-typography" style="display: contents;">
  <ng-container *ngIf="!data">
    WARN: NO MESSAGE!!!
  </ng-container>
  <ng-container *ngIf="data">
    {{data.message | transloco}}
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="accept()">{{translation.shared.yes | transloco}}</button>
  <button mat-button mat-dialog-close cdkFocusInitial>{{translation.shared.no | transloco}}</button>
</mat-dialog-actions>
