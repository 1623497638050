export const en = {
  language: {
    cs: 'Czech',
    en: 'English'
  },
  menu: {
    home: 'Home',
    designer: 'Designer'
  },
  login: {
    name: 'Happy window name',
    password: 'Password for login/edit',
    button: {
      login: 'Login',
      logout: 'Logout'
    },
    mustRequiredMessage: 'This field must be filled out!'
  },
  layout: {
    hello: 'hello',
    createHappyWindow: 'Create new HappyWindow'
  },
  window: {
    id: 'Window ID',
    idName: 'Calendar name',
    front: 'Front side',
    frontContent: 'Type of content',
    back: 'Back side',
    backContent: 'Type of content',
    content: 'Content',
    contentType: 'Type of content',
    general: 'General',
    text: 'Text on rotation',
    date: "Rotation date",
    conditional: "Validation authorization",
    textColor: 'TextColor',
    borderColor: 'Border color',
    contentColor: 'Content color',
    contentTextColor: 'Text when rotated',
    position: {
      x: 'X-position',
      y: 'Y-position'
    },
    size: {
      width: 'Window width',
      height: 'window height'
    },
    createWindowError: 'An error occurred while creating the window!',
    updateWindowError: 'An error occurred while updating the window!',
    removeWindowError: 'An error occurred while deleting the window!',
    validateWindowError: 'An error occurred while opening the window!',
    changeTemplateLayoutSuccess: 'The window layout template was changed successfully.',
    changeTemplateLayoutError: 'An error occurred while changing the window layout template!',
  },
  happyWindow: {
    info: 'Information',
    name: 'Name HappyWindow',
    password: 'Password to donate',
    passwordConfirm: 'Confirm password to donate',
    passwordEdit: 'Password to edit',
    passwordEditConfirm: 'Confirm password to edit',
    settingGrid: 'Setting grid',
    summary: 'Summary',
    backgroundGrid: 'Background HappyWindow',
    backgroundContentGrid: 'Background content HappyWindow',
    templateLayout: 'Template layout',
    changeTemplateLayout: 'Changing the template will delete the original windows!',
    customLayout: 'Custom layout',
    customLayoutMessage: 'No layout selected, you will have to place all the windows yourself.',
    management: 'Manage HappyWindow',
    manageBackground: 'Manage background HappyWindow',
    createHappyWindowSuccess: 'HappyWindow was created successfully.',
    createHappyWindowError: 'Error creating HappyWindow!',
    removeHappyWindowSuccess: 'HappyWindow was removed successfully.',
    removeHappyWindowError: 'An error occurred while removing HappyWindow!',
    removeHappyWindowMessage: 'Are you sure you want to delete HappyWindow?',
    updateWindowGridError: 'An error occurred while updating HappyWindow!',
  },
  presentation: {
    quillPlaceholder: 'Enter a dedication...'
  },
  button: {
    addWindow: "Add window",
    switchPage: "Switch pages",
    switchPageToFront: "Switch pages to the front",
    switchPageToBack: "Switch pages to the back",
    selectMode: "Select mode",
    dragMod: "Drag mode",
    testMode: "Test mode",
    next: "Next",
    back: "Back",
    createHappyWindow: 'Create HappyWindow',
    removeHappyWindow: 'Remove HappyWindow'
  },
  shared: {
    activeWarningMessages: 'Activate alert messages',
    resetSwitchWindow: 'Reset window rotation',
    requiredMessage: 'Are you sure you want to leave this value blank?',
    requiredDateMessage: 'Are you sure you want to have the date blank? If the date is not filled in, the date will not be verified when trying to rotate.',
    mustRequiredMessage: 'This field must be filled out!',
    confirmPasswordMessage: 'The passwords you entered do not match!',
    samePasswordMessage: 'The passwords for editing and donating cannot be the same!',
    nameMessage: 'The name HappyWindows is already in use!',
    loginMessageSuccess: 'Login was successful.',
    loginMessageError: 'You entered the wrong name or password!',
    imageLoaded: 'Image is loaded',
    image: 'Image',
    color: 'Color',
    text: 'Text',
    close: 'Close',
    save: 'Save',
    add: 'Add',
    remove: 'Remove',
    yes: 'Yes',
    no: 'No',
    setEveryone: 'Set to all',
    manageWaringMessages: 'Manage alert messages',
    management: 'Management',
    connectionMessageError: 'Connection lost!',
    pwa: 'PWA - Progressive Web Application',
    pwaMessage: 'Do you want install application?'
  }
};
