<div class="top-bar" *fwLet="runtimeInfo$ | async as runtimeInfo">
  <nav>
    <ul>
      <li>
        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/home">
          <i class="material-icons md-18">home</i> {{translation.menu.home | transloco}}
        </a>
      </li>
      <li *ngIf="runtimeInfo.isEdit">
        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/designer">
          <i class="material-icons md-18">palette</i> {{translation.menu.designer | transloco}}
        </a>
      </li>
      <li class="pull-right">
        <a routerLinkActive="active" href="#about">
          <i class="material-icons md-18">account_circle</i>
          About
        </a>
      </li>
      <li class="pull-right" style="padding: 1em;">
        <mat-slide-toggle [ngModel]="language === 'cs'" (toggleChange)="switchLanguage()">
          {{language === 'cs' ? (translation.language.cs | transloco) : (translation.language.en | transloco)}}
        </mat-slide-toggle>
      </li>
      <li class="pull-right login-item">
        <ng-container *ngIf="!runtimeInfo.id">
          <form [formGroup]="loginForm" autocomplete="off">
            <mat-form-field>
              <mat-label>{{translation.login.name | transloco}}</mat-label>
              <input matInput formControlName="name" required>
              <mat-error *ngIf="loginForm.get('name').hasError('required')">
                {{translation.login.mustRequiredMessage | transloco}}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{translation.login.password | transloco}}</mat-label>
              <input matInput formControlName="password" type="password" required>
              <mat-error *ngIf="loginForm.get('password').hasError('required')">
                {{translation.login.mustRequiredMessage | transloco}}
              </mat-error>
            </mat-form-field>
            <button mat-raised-button (click)="login()" class="login-btn">
              {{translation.login.button.login | transloco}}
              <mat-icon>login</mat-icon>
            </button>
          </form>
        </ng-container>
        <button *ngIf="runtimeInfo.id" mat-raised-button (click)="logout()" class="logout-btn">
          {{translation.login.button.logout | transloco}}
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </li>
    </ul>
  </nav>

  <mat-icon aria-hidden="false" aria-label="menu icon" class="mobile-nav-btn">menu</mat-icon>
</div>


<main [@slideInOut]="triggerAnimation(outlet)" class="hw-main-layout">
  <router-outlet #outlet="outlet"></router-outlet>
</main>
