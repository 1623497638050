import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

interface LetContext<T> {
  fwLet: T;
}

@Directive({
  selector: '[fwLet]'
})
export class LetDirective<T> {
  private _context: LetContext<T> = {fwLet: null};

  constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
    _viewContainer.createEmbeddedView(_templateRef, this._context);
  }

  @Input()
  set fwLet(value: T) {
    this._context.fwLet = value;
  }
}
