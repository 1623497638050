import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {translation} from '../../i18n';
import {WindowContentModel} from '@happy-windows/api-interfaces';

@Component({
  selector: 'hw-window-content-dialog',
  template: `
    <span [style.color]="windowContent.window.contentColor">
    {{windowContent.content.text}}
  </span>
  `
})
export class WindowContentDialogComponent {

  translation = translation;

  constructor(@Inject(MAT_DIALOG_DATA) public windowContent: WindowContentModel) {
  }


}
