import {createAction, props} from "@ngrx/store";
import {WarningMessageModel} from '@happy-windows/api-interfaces';

export const InitWarningMessages = createAction('[WarningMessage] InitWarningMessages');

export const LoadExampleWarningMessages = createAction('[WarningMessage] LoadExampleWarningMessages');
export const LoadWarningMessagesByCalendarName = createAction('[WarningMessage] LoadWarningMessagesByCalendarName', props<{ calendarName: string }>());
export const LoadWarningMessagesByCalendarNameSuccess = createAction('[WarningMessage] LoadWarningMessagesByCalendarNameSuccess', props<{ warningMessages: WarningMessageModel[] }>());
export const LoadWarningMessagesByCalendarNameError = createAction('[WarningMessage] LoadWarningMessagesByCalendarNameError', props<{ error: any }>());

export const LoadWarningMessageById = createAction('[WarningMessage] LoadWarningMessageById', props<{ id: number }>());
export const LoadWarningMessageByIdSuccess = createAction('[WarningMessage] LoadWarningMessageByIdSuccess', props<{ warningMessage: WarningMessageModel }>());
export const LoadWarningMessageByIdError = createAction('[WarningMessage] LoadWarningMessageByIdError', props<{ id: number, error: any }>());

export const CreateWarningMessage = createAction('[WarningMessage] CreateWindow', props<{ warningMessage: WarningMessageModel }>());
export const CreateWarningMessageSuccess = createAction('[WarningMessage] CreateWarningMessageSuccess', props<{ warningMessage: WarningMessageModel }>());
export const CreateWarningMessageError = createAction('[WarningMessage] CreateWindowError', props<{ error: any }>());

export const ToggledWarningMessage = createAction('[WarningMessage] ToggledWarningMessage', props<{ warningMessage: WarningMessageModel }>());
export const ResetWarningMessages = createAction('[WarningMessage] ResetWarningMessages');

export const UpdateWarningMessage = createAction('[WarningMessage] UpdateWarningMessage', props<{ warningMessage: WarningMessageModel }>());
export const UpdateWarningMessageSuccess = createAction('[WarningMessage] UpdateWarningMessageSuccess', props<{ warningMessage: WarningMessageModel }>());
export const UpdateWarningMessageError = createAction('[WarningMessage] UpdateWarningMessageError', props<{ id: number, error: any }>());

export const RemoveWarningMessage = createAction('[WarningMessage] RemoveWarningMessage', props<{ warningMessage: WarningMessageModel }>());
export const RemoveWarningMessageSuccess = createAction('[WarningMessage] RemoveWarningMessageSuccess', props<{ warningMessage: WarningMessageModel }>());
export const RemoveWarningMessageError = createAction('[WarningMessage] RemoveWarningMessageError', props<{ id: number, error: any }>());

export const SaveManyWarningMessage = createAction('[WarningMessage] SaveManyWarningMessage', props<{ warningMessages: WarningMessageModel[], calendarName: string }>());
export const SaveManyWarningMessageSuccess = createAction('[WarningMessage] SaveManyWarningMessageSuccess', props<{ warningMessages: WarningMessageModel[], calendarName: string }>());
export const SaveManyWarningMessageError = createAction('[WarningMessage] SaveManyWarningMessageError', props<{ error: any }>());
