import {windowReducer as WindowReducer} from './window.reducer';
import {windowContentReducer as WindowContentReducer} from './window-content.reducer';
import {warningMessageReducer as WarningMessageReducer} from './warning-message.reducer';
import {windowGridReducer as WindowGridReducer} from './window-grid.reducer';

export * from './window.reducer';
export * from './window-content.reducer';
export * from './warning-message.reducer';
export * from './window-grid.reducer';

export function windowReducer(state, action) {
  return WindowReducer(state, action);
}

export function windowContentReducer(state, action) {
  return WindowContentReducer(state, action);
}

export function warningMessageReducer(state, action) {
  return WarningMessageReducer(state, action);
}

export function windowGridReducer(state, action) {
  return WindowGridReducer(state, action);
}
