import {AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormGroup, ValidatorFn} from '@angular/forms';
import {Observable, Subject} from 'rxjs';

export class FwFormGroup<T = any> extends FormGroup {
  private _touchedChanges: Subject<boolean> = new Subject<boolean>();
  public touchedChanges: Observable<boolean> = null;
  private defaults = null;
  public readonly value: T;

  constructor(
    controls: {
      [key: string]: AbstractControl;
    }, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    //     isFocusFirstEnabled = true
  ) {

    super(controls, validatorOrOpts, asyncValidator);
    this.defaults = this.value;
    this.touchedChanges = this._touchedChanges.asObservable();
    //    this.isFocusFirstEnabled = isFocusFirstEnabled;
  }

  get(path: string | (string | number)[]): (AbstractControl & { updateValueAndValidityRecursively(opts?: { onlySelf?: boolean; emitEvent?: boolean; }) }) | null {
    return super.get(path) as (AbstractControl & { updateValueAndValidityRecursively(opts?: { onlySelf?: boolean; emitEvent?: boolean; }) } | null);
  }

  patchValue(value: Partial<T>, options: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
    super.patchValue(value, options);
  }

  setValue(value: T, options: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
    super.setValue(value, options);
  }

  resetWithDefaults(options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    this.reset(this.defaults, options);
  }

  markAsTouchedRecursively(markAsDirty = false) {
    if (markAsDirty) {
      this.markAsDirty();
    }
    this.markAsTouched({onlySelf: true});
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;
        control.markAsTouchedRecursively(markAsDirty);
      }
    }
  }

  markAsTouched(opts: { onlySelf?: boolean } = {}): void {
    this._touchedChanges.next(true);
    super.markAsTouched(opts);
  }

  markAsUntouched(opts: { onlySelf?: boolean } = {}): void {
    this._touchedChanges.next(false);
    super.markAsUntouched(opts);
  }

  markAsUntouchedRecursively(markAsPristine = false) {
    this.markAsUntouched();
    if (markAsPristine) {
      this.markAsPristine();
    }
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;

        control.markAsUntouchedRecursively(markAsPristine);
      }
    }
  }

  updateValueAndValidityRecursively(opts: { onlySelf?: boolean; emitEvent?: boolean; } = {}) {
    this.updateValueAndValidity();
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const control = this.controls[key] as any;

        if (control.updateValueAndValidityRecursively) {
          control.updateValueAndValidityRecursively(opts);
        } else {
          control.updateValueAndValidity(opts);
        }
      }
    }
  }

}
