<ng-container *fwLet="windowIdentifyPack.data as window">
  <ng-container *fwLet="designerMode$ | async as designerMode">
    <div class="hw-window" #container cdkDrag cdkDragBoundary=".hw-window-grid"
         [cdkDragData]="window" [cdkDragDisabled]="!isDragMode"
         [cdkDragFreeDragPosition]="window.position"
         (cdkDragEnded)="onDragEnded($event)"
         [style.width.px]="tmpSize.width || window.size.width"
         [style.height.px]="tmpSize.height || window.size.height"
         [ngClass]="window.focused ? 'focused' : null">
      <fw-loading-panel [pack]="windowIdentifyPack" size="small">
        <ng-template fwLoadingDataTemplate>

          <div class="flipper-main">
            <div class="flip-container">
              <!--             doresit pageState / open  - pokud se jedna o runtime mode!!! -->
              <div class="flipper" [class.rotate]="designerMode === 'run' ? window.open : window.pageState === 'back'"
                   [style.width.px]="tmpSize.width || window.size.width"
                   [style.height.px]="tmpSize.height || window.size.height"
                   [ngStyle]="{'border': window.borderColor +' 1px solid'}">
                <span *ngIf="isDragMode" (click)="onRemove(window)"
                      class="material-icons remove-icon">
                  delete_outline
                </span>
                <div class="card-face front"
                     [style.width.px]="(tmpSize.width || window.size.width)-2"
                     [style.height.px]="(tmpSize.height || window.size.height)-2">
                  <!-- front content -->
                  <ng-container [ngSwitch]="window.frontContent">
                <span *ngSwitchCase="'text'" [ngStyle]="{'color': window.textColor}">
                    {{window.front}}
                </span>
                    <div *ngSwitchCase="'image'"
                         [ngStyle]="{'background-image': !!window.front ? 'url(' + window.front + ')' : null,
                       'height':'100%', 'width': '100%'}">
                    </div>
                  </ng-container>

                </div>
                <div class="card-face back"
                     [style.width.px]="(tmpSize.width || window.size.width)-2"
                     [style.height.px]="(tmpSize.height || window.size.height)-2">
                  <!-- back content -->
                  <ng-container [ngSwitch]="window.backContent">
                <span *ngSwitchCase="'text'" [ngStyle]="{'color': window.textColor}">
                    {{window.back}}
                </span>
                    <div *ngSwitchCase="'image'"
                         [ngStyle]="{'background-image': !!window.back ? 'url(' + window.back + ')' : null,
                       'height': '100%', 'width': '100%'}">
                    </div>
                  </ng-container>
                </div>

                <div *ngIf="isDragMode" class="hw-window-handle" cdkDragHandle>
                  <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </div>
                <div *ngIf="isDragMode" class="resize resize__bottom--right"
                     style="overflow: hidden; transform: rotate(180deg); bottom: 2px; right: 2px"
                     (resize)="onResize($event)"
                     (resizeEnd)="onResizeEnd($event)"
                     [targetElement]="container" [direction]="angularResizeElementDirection.BOTTOM_RIGHT">
                  <span class="material-icons resize-icon">reorder</span>
                </div>

              </div>
            </div>
          </div>

        </ng-template>
      </fw-loading-panel>
    </div>
  </ng-container>
</ng-container>
