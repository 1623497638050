import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {RuntimeService} from '../providers';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private runtimeService: RuntimeService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentToken = 'Bearer ' + this.runtimeService.getToken();
    if (currentToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `${currentToken}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((event: any) => {
        if (event instanceof HttpErrorResponse) {
          console.warn(event);
          switch (event.status) {
            case 500 : {
              // zpracovani constraint chyb (vkladani duplicitniho unikatniho klice)
              return throwError(event);
            }
            case 400 : {
              // zpracovani constraint chyb (vkladani duplicitniho unikatniho klice)
              return throwError(event);
            }
            case 401 : {
              return throwError(event);
              // this.runtimeService.signOut(true);
            }
            default: {
              return throwError(event);
            }
          }
        }
      })
    );
  }
}
