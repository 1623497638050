<div class="navigation" [ngClass]="swipeClass">
  <mat-slide-toggle [ngModel]="language === 'cs'" (toggleChange)="switchLanguage()">
    {{language === 'cs' ? (translation.language.cs | transloco) : (translation.language.en | transloco)}}
  </mat-slide-toggle>
  <button *ngIf="runtimeService.isLoggedIn()" mat-raised-button (click)="logout()" class="logout-btn">
    {{translation.login.button.logout | transloco}}
    <mat-icon>exit_to_app</mat-icon>
  </button>
</div>
<hw-window-grid *ngIf="windowGrid$ | async as windowGrid" [windowGrid]="windowGrid"
                (swipeleft)="onSwipe($event, 'left')" (swiperight)="onSwipe($event, 'right')"
                (tap)="onSwipe($event, 'tap')"
                [showWindowGridSetting]="false" [windowZoom]="calculateZoom().zoom" [isPwa]="true"
                [style.height.px]="calculateZoom().height" [style.width.px]="calculateZoom().width">
</hw-window-grid>

