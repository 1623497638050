import {ChangeDetectionStrategy, Component} from '@angular/core';
import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {
  FwFormBuilder,
  FwFormGroup,
  getRuntimeInfo,
  RuntimeInfoModel,
  RuntimeService
} from '@happy-windows/framework/core';
import {Observable} from 'rxjs';
import {TranslocoService} from '@happy-windows/framework/translate';
import {Store} from '@ngrx/store';
import {Validators} from '@angular/forms';
import {RouterOutlet} from '@angular/router';
import {translation} from '../../i18n';
import {LoginWindowGrid, LogoutWindowGrid} from '../../actions';

@Component({
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition('* => *, :enter', [
        query(':enter, :leave', style({position: 'absolute', width: '100%'}), {optional: true}),
        query(':enter', style({transform: 'translateX(100vw)'}), {optional: true}),
        query(':leave', style({transform: 'translateX(0vw)'}), {optional: true}),

        group([
          query(':leave', [
            animate('800ms ease-in-out', style({
              transform: 'translateX(-100vw)'
            }))
          ], {optional: true}),
          query(':enter', [
            animate('800ms ease-in-out', style({
              transform: 'translateX(0)'
            }))
          ], {optional: true})
        ])
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLayoutComponent {
  translation = translation;
  language: string;

  loginForm: FwFormGroup;

  runtimeInfo$: Observable<RuntimeInfoModel>;

  constructor(private translocoService: TranslocoService,
              private runtimeService: RuntimeService,
              private fb: FwFormBuilder, private store: Store<any>) {
    const language = runtimeService.getLanguage();
    if (language) {
      this.language = language;
      this.translocoService.setActiveLang(this.language);
    } else {
      this.language = translocoService.getActiveLang();
    }
    this.runtimeService.setLanguage(this.language);

    this.loginForm = fb.fwGroup({
      name: fb.fwControl('', [Validators.required]),
      password: fb.fwControl('', [Validators.required]),
    });

    this.runtimeInfo$ = this.store.select(getRuntimeInfo);
  }

  triggerAnimation(outlet: RouterOutlet) {
    return outlet.activatedRouteData.animation || null;
  }

  switchLanguage() {
    this.language = this.language === 'cs' ? 'en' : 'cs';
    this.runtimeService.setLanguage(this.language);
    this.translocoService.setActiveLang(this.language);
  }

  login() {
    if (this.loginForm.valid) {
      this.store.dispatch(LoginWindowGrid({
        name: this.loginForm.get('name').value,
        password: this.loginForm.get('password').value,
        isPwa: false
      }));
    } else {
      this.loginForm.markAsTouchedRecursively();
    }
  }

  logout() {
    this.store.dispatch(LogoutWindowGrid({isPwa: false}));
  }
}
