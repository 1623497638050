import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {Store} from '@ngrx/store';
import {WindowGridState} from '../../reducers';
import {Subject} from 'rxjs';
import {WindowGridModel} from '@happy-windows/api-interfaces';
import {FwFormBuilder, FwFormGroup} from '@happy-windows/framework/core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {translation} from "../../i18n";
import {WarningMessagesDialogComponent} from '../warning-messages-dialog/warning-messages-dialog.component';
import {CroppieDialogComponent} from '../croppie-dialog/croppie-dialog.component';
import {BORDER_COLOR, CONTENT_COLOR, GRID, OPTION_WINDOW_GRID_TEMPLATE, TEXT_COLOR} from '../../const';
import {ChangeLayoutTemplate, RemoveWindowGrid, UpdateWindowGrid} from '../../actions';
import {DialogService, WindowGridService} from '../../providers';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

/**
 * Pravy panel pro editaci window gridu
 */
@Component({
  selector: 'hw-window-grid-setting',
  templateUrl: './window-grid-setting.component.html',
  styleUrls: ['./window-grid-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowGridSettingComponent implements OnInit, OnChanges, OnDestroy {

  translation = translation;
  destroyed$: Subject<any> = new Subject();

  windowGridForm: FwFormGroup;

  optionWindowGridContent = [
    {value: 'color', viewValue: this.translation.shared.color},
    {value: 'image', viewValue: this.translation.shared.image},
  ];

  optionWindowGridTemplate = [{
    ...OPTION_WINDOW_GRID_TEMPLATE[0],
    value: {row: 0, col: 0}
  }, ...OPTION_WINDOW_GRID_TEMPLATE.slice(1)];
  selectedWindowGridTemplate = this.optionWindowGridTemplate[0];

  @Input()
  windowGrid: WindowGridModel;

  constructor(private store: Store<WindowGridState>,
              private fb: FwFormBuilder,
              private dialog: DialogService,
              private cdr: ChangeDetectorRef,
              private windowGridService: WindowGridService) {
    this.windowGridForm = fb.fwGroup({
      id: fb.fwControl(),
      name: fb.fwControl('', [Validators.required]),
      password: fb.fwControl('', [Validators.required]),
      password1: fb.fwControl('', [Validators.required]),
      passwordEdit: fb.fwControl('', [Validators.required]),
      passwordEdit1: fb.fwControl('', [Validators.required]),
      background: fb.fwControl('', [Validators.required]),
      template: fb.fwControl(),
      backgroundContent: fb.fwControl()
    });
  }

  ngOnInit(): void {
    this.windowGridForm.valueChanges.pipe(
      takeUntil(this.destroyed$),
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(x => {
        this.store.dispatch(UpdateWindowGrid({
          windowGrid: {
            id: this.windowGridForm.get('id').value,
            name: this.windowGridForm.get('name').value,
            background: this.windowGridForm.get('background').value,
            backgroundContent: this.windowGridForm.get('backgroundContent').value,
            example: false
          }
        }));
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const tmpWindowGrid = changes['windowGrid'];
    if (tmpWindowGrid && tmpWindowGrid.currentValue) {
      const tmpWindowData = tmpWindowGrid.currentValue;
      this.windowGridForm.patchValue(tmpWindowData, {emitEvent: false});
    }
  }

  changeBackgroundContent(event) {
    this.windowGridForm.get('background').setValue(event.value === 'image' ? null : TEXT_COLOR);
    this.windowGridForm.get('background').updateValueAndValidity();
  }

  setImage() {
    const dialogRef = this.dialog.open(CroppieDialogComponent, {
      height: '900px',
      width: '500px',
      data: GRID
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.windowGridForm.get('background').setValue(result);
      }
    });
  }

  manageWaringMessages() {
    this.dialog.open(WarningMessagesDialogComponent, {
      data: this.windowGridForm.get('name').value,
      width: '600px',
    });
  }


  changeWindowGridTemplate(event) {
    const value: { row: number, col: number } = event.value;
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: this.translation.happyWindow.changeTemplateLayout
      }
    }).afterClosed().subscribe(x => {
      if (x === 'yes') {
        this.store.dispatch(ChangeLayoutTemplate({
          idName: this.windowGrid.name,
          windows: this.windowGridService.generateWindowsByTemplate(
            this.windowGrid.name,
            TEXT_COLOR,
            BORDER_COLOR,
            CONTENT_COLOR,
            value
          )
        }));
      }
      this.selectedWindowGridTemplate = this.optionWindowGridTemplate[0];
      this.cdr.markForCheck();
    });
  }

  removeHappyWindow() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.translation.shared.remove,
        message: this.translation.happyWindow.removeHappyWindowMessage
      }
    }).afterClosed().subscribe(x => {
      if (x === 'yes') {
        this.store.dispatch(RemoveWindowGrid({windowGrid: this.windowGrid}));
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

}
