import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import {WindowState} from '../../reducers';
import {Subject} from 'rxjs';
import {WindowModel} from '@happy-windows/api-interfaces';
import {FwFormBuilder, FwFormGroup, IdentifiedPack} from '@happy-windows/framework/core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Validators} from '@angular/forms';
import {translation} from "../../i18n";
import {CroppieDialogComponent} from '../croppie-dialog/croppie-dialog.component';
import {SetTemporaryVariableWindow, UpdateWindow, UpdateWindows} from '../../actions';
import {DialogService} from '../../providers';
import {BORDER_COLOR, CONTENT_COLOR, TEXT_COLOR} from '../../const';

/**
 * Pravy panel pro editaci jednotlivych oken
 */
@Component({
  selector: 'hw-window-setting',
  templateUrl: './window-setting.component.html',
  styleUrls: ['./window-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSettingComponent implements OnInit, OnChanges, OnDestroy {

  translation = translation;
  destroyed$: Subject<any> = new Subject();

  windowForm: FwFormGroup;

  optionWindowContent = [
    {value: 'text', viewValue: this.translation.shared.text},
    {value: 'image', viewValue: this.translation.shared.image},
  ];

  @Input()
  window: IdentifiedPack<WindowModel>;

  constructor(private store: Store<WindowState>,
              private fb: FwFormBuilder,
              private dialog: DialogService) {
    this.windowForm = fb.fwGroup({
      id: fb.fwControl(),
      idName: fb.fwControl('', [Validators.required]),
      front: fb.fwControl('', [Validators.required]),
      frontContent: fb.fwControl('text', [Validators.required]),
      back: fb.fwControl('', [Validators.required]),
      backContent: fb.fwControl('text', [Validators.required]),
      text: fb.fwControl('', [Validators.required]),
      date: fb.fwControl(),
      open: fb.fwControl(false),
      conditional: fb.fwControl(false),
      textColor: fb.fwControl(TEXT_COLOR),
      borderColor: fb.fwControl(BORDER_COLOR),
      contentColor: fb.fwControl(CONTENT_COLOR),
      position: fb.fwGroup({
        x: fb.fwControl(0, [Validators.required]),
        y: fb.fwControl(0, [Validators.required]),
      }),
      size: fb.fwGroup({
        width: fb.fwControl(100, [Validators.required]),
        height: fb.fwControl(100, [Validators.required]),
      }),
      mode: fb.fwControl(),
      focused: fb.fwControl(),
      pageState: fb.fwControl()
    });
  }

  ngOnInit(): void {
    this.windowForm.valueChanges.pipe(
      takeUntil(this.destroyed$),
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(x => {
        if (this.windowForm.getRawValue().id) {
          this.store.dispatch(SetTemporaryVariableWindow({window: this.windowForm.getRawValue()}));
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const tmpWindow = changes['window'];
    if (tmpWindow && tmpWindow.currentValue) {
      const tmpWindowData = tmpWindow.currentValue.data;
      this.windowForm.patchValue(tmpWindowData, {emitEvent: false});
      if (tmpWindow.previousValue && tmpWindow.currentValue.data !== tmpWindow.previousValue.data
        && this.windowForm.dirty) {
        this.store.dispatch(UpdateWindow({window: tmpWindow.currentValue.data}));
        this.windowForm.markAsPristine();
      }
    }
  }

  changePageContent(side: 'front' | 'back') {
    this.windowForm.get(side).setValue(null);
    this.windowForm.get(side).updateValueAndValidity();
  }

  setImage(side: 'front' | 'back') {
    const dialogRef = this.dialog.open(CroppieDialogComponent, {data: this.windowForm.get('size').value});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.windowForm.get(side).setValue(result);
        this.windowForm.markAsDirty();
      }
    });
  }

  setTextColorEveryone() {
    this.store.dispatch(UpdateWindows({
      idName: this.windowForm.get('idName').value,
      properties: {
        textColor: this.windowForm.get('textColor').value
      }
    }));
  }

  setBorderColorEveryone() {
    this.store.dispatch(UpdateWindows({
      idName: this.windowForm.get('idName').value,
      properties: {
        borderColor: this.windowForm.get('borderColor').value
      }
    }));
  }

  setContentColorEveryone() {
    this.store.dispatch(UpdateWindows({
      idName: this.windowForm.get('idName').value,
      properties: {
        contentColor: this.windowForm.get('contentColor').value
      }
    }));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyed$ = null;
  }

}
