import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {


  constructor(private swUpdate: SwUpdate) {

  }

  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      // pokud je povoleny update sw a zaroven se jedna o jiz nainstalovanou aplikaci, tak hlidam, zda neni nova verze
      this.swUpdate.available.subscribe(ev => {
        console.log("New version available!");
        console.log(ev);
        // dialog, zda chceme nainstalovat novou aplikaci
        if (confirm('Do you want update app?')) {
          window.location.reload();
        }
      });
    }
  }

}
