import {createAction, props} from "@ngrx/store";
import {DesignerModeType, WarningMessageModel, WindowModel} from '@happy-windows/api-interfaces';

export const InitWindow = createAction('[Window] InitWindow');

// slouzi pro nacteni happy window jako ukazky (nepouziva token)
export const LoadExampleWindows = createAction('[Window] LoadExampleWindows');
export const LoadWindowsByCalendarName = createAction('[Window] LoadWindowsByCalendarName', props<{ calendarName: string }>());
export const LoadWindowsByCalendarNameSuccess = createAction('[Window] LoadWindowsByCalendarNameSuccess', props<{ windows: WindowModel[] }>());
export const LoadWindowsByCalendarNameError = createAction('[Window] LoadWindowsByCalendarNameError', props<{ error: any }>());

export const LoadWindowById = createAction('[Window] LoadWindowById', props<{ id: number }>());
export const LoadWindowByIdSuccess = createAction('[Window] LoadWindowByIdSuccess', props<{ window: WindowModel }>());
export const LoadWindowByIdError = createAction('[Window] LoadWindowByIdError', props<{ id: number, error: any }>());

export const CreateWindow = createAction('[Window] CreateWindow', props<{ window: WindowModel }>());
export const CreateWindowSuccess = createAction('[Window] CreateWindowSuccess', props<{ window: WindowModel }>());
export const CreateWindowError = createAction('[Window] CreateWindowError', props<{ error: any }>());

export const UpdateWindow = createAction('[Window] UpdateWindow', props<{ window: WindowModel }>());
export const UpdateWindowSuccess = createAction('[Window] UpdateWindowSuccess', props<{ window: WindowModel }>());
export const UpdateWindowError = createAction('[Window] UpdateWindowError', props<{ id: number, error: any }>());

export const RemoveWindow = createAction('[Window] RemoveWindow', props<{ window: WindowModel }>());
export const RemoveWindowSuccess = createAction('[Window] RemoveWindowSuccess', props<{ window: WindowModel }>());
export const RemoveWindowError = createAction('[Window] RemoveWindowError', props<{ id: number, error: any }>());

export const CreateWindows = createAction('[Window] CreateWindows', props<{ windows: WindowModel[] }>());
export const CreateWindowsSuccess = createAction('[Window] CreateWindowsSuccess', props<{ windows: WindowModel[] }>());
export const CreateWindowsError = createAction('[Window] CreateWindowsError', props<{ error: any }>());

export const UpdateWindows = createAction('[Window] UpdateWindows', props<{ idName: string, properties: { textColor?: string, borderColor?: string, contentColor?: string } }>());
export const UpdateWindowsSuccess = createAction('[Window] UpdateWindowsSuccess', props<{ windows: WindowModel[] }>());
export const UpdateWindowsError = createAction('[Window] UpdateWindowsError', props<{ error: any }>());

export const ChangeLayoutTemplate = createAction('[Window] ChangeLayoutTemplate', props<{ idName: string, windows: WindowModel[] }>());
export const ChangeLayoutTemplateSuccess = createAction('[Window] ChangeLayoutTemplateSuccess', props<{ windows: WindowModel[] }>());
export const ChangeLayoutTemplateError = createAction('[Window] ChangeLayoutTemplateError', props<{ error: any }>());

export const ChangeWindowsMode = createAction('[Window] ChangeWindowsMode', props<{ mode: DesignerModeType }>());
export const SetFocusOnWindow = createAction('[Window] SetFocusOnWindow', props<{ window: WindowModel }>());
export const DisableFocusOnWindow = createAction('[Window] DisableFocusOnWindow');
export const CheckDate = createAction('[Window] CheckDate');
export const SwitchPageWindows = createAction('[Window] SwitchPageWindows');

export const ClosePageWindow = createAction('[Window] ClosePageWindow', props<{ window: WindowModel }>());
export const ClosePageWindowSuccess = createAction('[Window] ClosePageWindowSuccess', props<{ window: WindowModel }>());
export const ClosePageWindowError = createAction('[Window] ClosePageWindowError', props<{ id: number, error: any }>());
export const OpenPageWindow = createAction('[Window] OpenPageWindow', props<{ window: WindowModel, calendarName: string }>());
export const ResetPageWindows = createAction('[Window] ResetPageWindows');
export const ValidateWindow = createAction('[Window] ValidateWindow', props<{ window: WindowModel, warningMessage: WarningMessageModel }>());
export const DoNothingWindow = createAction('[Window] DoNothingWindow');

/**
 * Dojde k tomu, ze se zmeni store pri zmene hodnoty window, reaguje na to selector, ktery porovna objekty a podle toho se posle request na backend nebo ne
 */
export const SetTemporaryVariableWindow = createAction('[Window] SetTemporaryVariableWindow', props<{ window: WindowModel }>());
