<!-- Default loading template -->
<ng-template #defaultLoadingTemplate>
  <!--  <div class='text-input__loading'>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--    <div class='text-input__loading&#45;&#45;line'></div>-->
  <!--  </div>-->
  <div class="spinner" [ngClass]="size">
  </div>

</ng-template>

<ng-template #defaultLoadingTemplateDialog>
  <div class="loading-wrap">
    <div class="bounceball"></div>
    <div class="text">{{translation.shared.loadingData | transloco}}</div>
  </div>
</ng-template>

<!-- Default error template -->
<ng-template #defaultErrorTemplate let-error="error">
  <div class="ui-messages ui-widget ui-corner-all ui-messages-error ng-trigger ng-trigger-messageAnimation">
    <button mat-button color="warn" label="{{translation.shared.retry | transloco}}" class="ui-button-danger pull-right"
            (click)="onRetry()">
      <span class="material-icons">cached</span>
    </button>
    <ul>
      <li>
        <span class="ui-messages-summary">{{translation.shared.errorLoadingData | transloco}}</span>
        <span class="ui-messages-detail">{{ error  | json }}</span>
      </li>
    </ul>
  </div>
</ng-template>

<!-- Default data template -->
<ng-template
  #defaultDataTemplate
  let-data
>
  <span>{{ data | json }}</span>
</ng-template>


<!-- BODY -->
<ng-container *ngIf="context.loading">
  <!--  <p-dialog *ngIf="dialog" [visible]="context.loading" [showHeader]="false" class="ui-dialog-loading">-->
  <!--    <ng-container *ngTemplateOutlet="loadingTemplate; context: context"></ng-container>-->
  <!--  </p-dialog>-->
  <ng-container *ngIf="!dialog">
    <ng-container *ngTemplateOutlet="loadingTemplate; context: context"></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="!context.loading && context.error">
  <!--  <p-dialog *ngIf="dialog" [visible]="!context.loading && context.error" [showHeader]="false" class="ui-dialog-loading">-->
  <!--    <ng-container *ngTemplateOutlet="errorTemplate; context: context"></ng-container>-->
  <!--  </p-dialog>-->
  <ng-container *ngIf="!dialog">
    <ng-container *ngTemplateOutlet="errorTemplate; context: context"></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="!context.loading && !context.error">
  <ng-container *ngTemplateOutlet="dataTemplate; context: context"></ng-container>
</ng-container>
