import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {UpsertPreviousUrl,} from '../actions';
import {ROUTER_REQUEST, RouterNavigatedAction} from '@ngrx/router-store';
import {CustomRouterStateUrlModel} from '../models';

@Injectable()
export class CoreEffect {

  upsertPreviousUrl = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction<CustomRouterStateUrlModel>>(ROUTER_REQUEST),
      map(({payload}) => UpsertPreviousUrl({previousUrl: payload.routerState.url}))
    ));

  constructor(private actions$: Actions) {
  }

}
