import {Store} from '@ngrx/store';

import {Injectable, OnDestroy, PipeTransform} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';

@Injectable()
export abstract class AbstractCachedLoaderPipe implements PipeTransform, OnDestroy {

  protected _checkSubscription: Subscription;
  protected _obs$: Observable<any>;
  protected _id: any;
  protected _args?: any;
  protected _of = of();

  protected constructor(protected store: Store<any>) {
  }

  abstract setObservable(_id: any, args?: any);

  abstract checkIfDispatchNeeded();

  ngOnDestroy() {
    this.destroy();
  }

  destroy() {
    this._obs$ = undefined;
    if (this._checkSubscription) {
      this._checkSubscription.unsubscribe();
      this._checkSubscription = undefined;
    }
  }

  transform(id: any, args?: any): Observable<any> {
    if (!id) return this._of;

//     Check if return new Observable and check if group has beeen already loaded
    if (id !== this._id || args !== this._args) {
      this.setObservable(id, args);
      this.checkIfDispatchNeeded();
    }

    return this._obs$;
  }
}
