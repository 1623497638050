import {createReducer, on} from "@ngrx/store";
import {
  CreateWarningMessage,
  CreateWarningMessageError,
  CreateWarningMessageSuccess,
  InitWarningMessages,
  LoadExampleWarningMessages,
  LoadWarningMessageById,
  LoadWarningMessageByIdError,
  LoadWarningMessageByIdSuccess,
  LoadWarningMessagesByCalendarName,
  LoadWarningMessagesByCalendarNameError,
  LoadWarningMessagesByCalendarNameSuccess,
  RemoveWarningMessage,
  RemoveWarningMessageError,
  RemoveWarningMessageSuccess,
  ResetWarningMessages,
  SaveManyWarningMessage,
  SaveManyWarningMessageError,
  SaveManyWarningMessageSuccess,
  ToggledWarningMessage,
  UpdateWarningMessage,
  UpdateWarningMessageError,
  UpdateWarningMessageSuccess
} from '../actions';
import {WarningMessageModel} from '@happy-windows/api-interfaces';
import {EntityAdapter, EntityState} from '@ngrx/entity/src/models';
import {createEntityAdapter} from '@ngrx/entity';
import {IdentifiedPack} from '@happy-windows/framework/core';


export interface WarningMessageState extends EntityState<IdentifiedPack<WarningMessageModel>> {
  loading: boolean;
  error: any;
}

export const warningMessageAdapter: EntityAdapter<IdentifiedPack<WarningMessageModel>> = createEntityAdapter<WarningMessageModel>({});

const initialState: WarningMessageState = warningMessageAdapter.getInitialState({
  loading: false,
  error: null
});

export const warningMessageReducer = createReducer(
  initialState,


  on(InitWarningMessages,
    (state) => ({
      ...warningMessageAdapter.removeAll(state),
      error: null,
      loading: false
    })
  ),

  on(LoadWarningMessagesByCalendarName,
    LoadExampleWarningMessages,
    CreateWarningMessage,
    SaveManyWarningMessage,
    (state) => ({
      ...state,
      loading: true
    })
  ),

  on(LoadWarningMessagesByCalendarNameSuccess,
    (state, {warningMessages}) => ({
      ...warningMessageAdapter.upsertMany(warningMessages.map(message => ({
        id: message.id,
        data: message,
        loading: false,
        error: null
      })), state),
      error: null,
      loading: false
    })
  ),

  on(SaveManyWarningMessageSuccess,
    (state, {calendarName, warningMessages}) => {
      const newState = warningMessageAdapter.removeMany((entity) => entity.data.idName === calendarName, state);
      return {
        ...warningMessageAdapter.upsertMany(warningMessages.map(message => ({
          id: message.id,
          data: message,
          loading: false,
          error: null
        })), newState),
        error: null,
        loading: false
      }
    }
  ),

  on(LoadWarningMessagesByCalendarNameError,
    CreateWarningMessageError,
    SaveManyWarningMessageError,
    (state, {error}) => ({
      ...state,
      loading: false,
      error: error
    })
  ),

  on(LoadWarningMessageById,
    (state, {id}) =>
      warningMessageAdapter.upsertOne({
        id: id,
        loading: true
      }, state)
  ),

  on(UpdateWarningMessage,
    RemoveWarningMessage,
    (state, {warningMessage}) =>
      warningMessageAdapter.upsertOne({
        id: warningMessage.id,
        loading: true
      }, state)
  ),

  on(LoadWarningMessageByIdSuccess,
    UpdateWarningMessageSuccess,
    CreateWarningMessageSuccess,
    (state, {warningMessage}) =>
      warningMessageAdapter.upsertOne({
        id: warningMessage.id,
        data: warningMessage,
        loading: false,
        error: null
      }, {...state, loading: false, error: null})
  ),

  on(RemoveWarningMessageSuccess, (state, {warningMessage}) =>
    warningMessageAdapter.removeOne(warningMessage.id, state)
  ),

  on(LoadWarningMessageByIdError,
    UpdateWarningMessageError,
    RemoveWarningMessageError,
    (state, {id, error}) =>
      warningMessageAdapter.upsertOne({
        id: id,
        data: null,
        loading: false,
        error: error
      }, state)
  ),

  on(ToggledWarningMessage,
    (state, {warningMessage}) =>
      warningMessageAdapter.upsertOne({
        id: warningMessage.id,
        data: {
          ...state.entities[warningMessage.id].data,
          opened: !state.entities[warningMessage.id].data.opened
        }
      }, state)
  ),

  on(ResetWarningMessages,
    (state) =>
      warningMessageAdapter.map((entity => (
        {
          ...entity,
          data: {
            ...entity.data,
            opened: false
          }
        }
      )), state)
  )
);
