import {NgModule} from '@angular/core';
import {COMPONENTS, EXPORTS, IMPORTS, PROVIDERS} from '.';

@NgModule({
  imports: [...IMPORTS],
  declarations: [...COMPONENTS],
  providers: [...PROVIDERS],
  exports: [...EXPORTS]
})
export class HappyWindowsLayoutModule {
}
