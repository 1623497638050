import {Component, Inject, ViewChild} from '@angular/core';
import {CroppieComponent} from '@happy-windows/framework/shared';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SizeModel} from '@happy-windows/api-interfaces';
import {translation} from '../../i18n';

@Component({
  selector: 'hw-croppie-dialog',
  templateUrl: './croppie-dialog.component.html',
  styleUrls: ['./croppie-dialog.component.scss']
})
export class CroppieDialogComponent {

  translation = translation;

  @ViewChild(CroppieComponent)
  croppieComponent: CroppieComponent;

  constructor(private dialogRef: MatDialogRef<CroppieDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public sizeData: SizeModel) {
  }

  getImage() {
    this.croppieComponent.getResult().then(x => {
      this.dialogRef.close(x);
    });
  }


}
