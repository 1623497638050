import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';

import {TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {LOCALIZATIONS} from './di/tokens';
import {Localization} from './models';
import {mergeArraysToArray} from '@happy-windows/framework/core';


@NgModule({
  imports: [
    TranslocoModule
  ],
  exports: [
    TranslocoModule
  ]
})
export class FrameworkTranslateModule {
  constructor(
    translocoService: TranslocoService,
    @Optional() @Inject(LOCALIZATIONS) localizations: Localization[][]) {
    const finalArr = mergeArraysToArray(localizations);
    finalArr.forEach(localization => {
      translocoService.setTranslation(localization.value, localization.language, {merge: true});
    });
  }

  public static forFeature(localizations: Localization[]): ModuleWithProviders<FrameworkTranslateModule> {
    return {
      ngModule: FrameworkTranslateModule,
      providers: [{
        provide: LOCALIZATIONS,
        useValue: localizations,
        multi: true
      }]
    };
  }

  public static forRoot(options: {
    localizations: Localization[],
    translocoConfig: TranslocoConfig
  }): ModuleWithProviders<FrameworkTranslateModule> {
    return {
      ngModule: FrameworkTranslateModule,
      providers: [
        {
          provide: LOCALIZATIONS,
          useValue: options.localizations,
          multi: true
        },
        {
          provide: TRANSLOCO_CONFIG,
          useValue: {
            availableLangs: ['cs'],
            defaultLang: navigator.language === 'cs' ? 'cs' : 'en',
            prodMode: false,
            reRenderOnLangChange: false
          }
        },
        {
          provide: TRANSLOCO_CONFIG,
          useValue: options.translocoConfig
        }
      ]
    };
  }
}
