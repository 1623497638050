import {NgModule} from '@angular/core';

import {COMPONENTS, EXPORTS, GUARDS, IMPORTS, PROVIDERS, RESOLVERS} from '.';

@NgModule({
  imports: [
    ...IMPORTS
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...EXPORTS,
    ...COMPONENTS
  ],
  providers: [
    ...GUARDS,
    ...RESOLVERS,
    ...PROVIDERS
  ]
})
export class FrameworkSharedModule {
}
