<mat-drawer-container class="container" autosize *ngIf="windowGrid$ | async as windowGrid">
  <hw-window-action [calendarName]="windowGrid.name">
  </hw-window-action>

  <div class="main-content">
    <hw-window-grid [windowGrid]="windowGrid" (click)="disableFocus()">
    </hw-window-grid>
  </div>

  <mat-drawer #drawer class="content-sidebar" mode="side" position="end">
    <ng-container *fwLet="rightPanel$ | async as rightPanel">
      <hw-window-setting *ngIf="rightPanel === 'windowSetting'" [window]="selectedWindow$ | async">
      </hw-window-setting>
      <hw-window-testing *ngIf="rightPanel === 'testMode'">
      </hw-window-testing>
      <hw-window-grid-setting *ngIf="rightPanel === 'windowGridSetting'" [windowGrid]="windowGrid">
      </hw-window-grid-setting>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>
