import {Injectable} from "@angular/core";
import {ConfigModel} from '../models';

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class ConfigService<ConfigType = ConfigModel> {
  private static _config: any = null;

  get value(): ConfigType {
    if (!ConfigService._config) throw Error("Value of config service is not initialized, are you sure you have called it in initializer / pre bootstrap?");
    return ConfigService._config;
  }

  /**
   * ...
   * @returns Promise to wait for
   * This comment is mainly to use dynamic for compiler
   * @dynamic
   */
  static prebootstrapLoad(options: {
    serviceWorker: boolean;
  } = null): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.addEventListener('load', _ => {
        if (request.status === 200) {
          try {
            ConfigService._config = JSON.parse(request.responseText);
          } catch (e) {
            reject(e);
          }
          resolve();
        } else {
          reject(request.statusText);
        }
      });
      request.open('GET', (options && options.serviceWorker) ? 'config.json' : '/config.json?hash=' + new Date().toISOString());
      request.send();
    });
  }


  load(): Promise<ConfigType> {
    return new Promise((res, rej) => {
      res();
    });
  }
}
