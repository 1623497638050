import {AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn} from '@angular/forms';
import {Observable, Subject} from 'rxjs';

export class FwFormArray<T> extends FormArray {
  private _touchedChanges: Subject<boolean> = new Subject<boolean>();
  public touchedChanges: Observable<boolean> = null;

  constructor(
    controls: AbstractControl[],
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    this.touchedChanges = this._touchedChanges.asObservable();
  }


  setValue(value: T[], options: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
    super.setValue(value, options);
  }

  patchValue(value: T[], options: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
    super.patchValue(value, options);
  }

  markAsTouchedRecursively() {
    this.markAsTouched({onlySelf: true});
    this.markAsDirty();
    this.controls.forEach(control => {

      (control as any).markAsTouchedRecursively();

    });
  }

  markAsUntouchedRecursively() {
    this.markAsUntouched();
    this.markAsPristine();
    this.controls.forEach(control => {
      (control as any).markAsUntouchedRecursively();
    });
  }

  markAsTouched(opts: { onlySelf?: boolean } = {}): void {
    this._touchedChanges.next(true);
    super.markAsTouched(opts);
  }

  markAsUntouched(opts: { onlySelf?: boolean } = {}): void {
    this._touchedChanges.next(false);
    super.markAsUntouched(opts);
  }

  updateValueAndValidityRecursively(opts: { onlySelf?: boolean; emitEvent?: boolean; } = {}) {
    this.updateValueAndValidity();

    this.controls.forEach((control: any) => {
      if (control.updateValueAndValidityRecursively) {
        control.updateValueAndValidityRecursively(opts);
      } else {
        control.updateValueAndValidity(opts);
      }
    });
  }
}
