import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {DesignerModeType, PositionModel, SizeModel, WindowModel} from '@happy-windows/api-interfaces';
import {AngularResizeElementDirection, AngularResizeElementEvent} from 'angular-resize-element';
import {IdentifiedPack} from '@happy-windows/framework/core';
import {LocalStateService} from '@happy-windows/framework/local-state';
import {DESIGNER_MODE} from '../../const';
import {Observable} from 'rxjs';

@Component({
  selector: 'hw-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowComponent {

  angularResizeElementDirection = AngularResizeElementDirection;
  tmpSize: SizeModel = {
    width: undefined,
    height: undefined
  };

  designerMode$: Observable<DesignerModeType>;

  @Input()
  windowIdentifyPack: IdentifiedPack<WindowModel>;

  @Output()
  changePosition = new EventEmitter<{ window: WindowModel, position: PositionModel }>();

  @Output()
  changeSize = new EventEmitter<{ window: WindowModel, size: SizeModel }>();

  @Output()
  changeFocus = new EventEmitter<WindowModel>();

  @Output()
  switchPage = new EventEmitter<WindowModel>();

  @Output()
  remove = new EventEmitter<WindowModel>();

  constructor(public element: ElementRef,
              private localStateService: LocalStateService) {
    this.designerMode$ = localStateService.getState(DESIGNER_MODE);
  }

  @HostListener('click', ['$event'])
  click(event) {
    event.stopPropagation();
    const designerMode: DesignerModeType = this.localStateService.getStateInstant(DESIGNER_MODE);
    if (designerMode === 'test' || designerMode === 'run' || designerMode === 'example') {
      this.switchPage.emit(this.windowIdentifyPack.data);
    } else if (designerMode === 'select') {
      this.changeFocus.emit(this.windowIdentifyPack.data);
    }
  }

  onRemove(window: WindowModel): void {
    this.remove.emit(window);
  }

  onResize(evt: AngularResizeElementEvent): void {
    this.tmpSize.width = evt.currentWidthValue;
    this.tmpSize.height = evt.currentHeightValue;
  }

  onResizeEnd(evt: AngularResizeElementEvent) {
    this.changeSize.emit({
      window: this.windowIdentifyPack.data,
      size: {
        width: evt.currentWidthValue > 400 ? 400 : evt.currentWidthValue,
        height: evt.currentHeightValue > 400 ? 400 : evt.currentHeightValue
      }
    });
    // this.tmpSize = {
    //   width: undefined,
    //   height: undefined
    // };
  }

  onDragEnded(event) {
    const transform: string = event.source.element.nativeElement.style.transform ||
      event.source.element.nativeElement.style.webkitTransform ||
      event.source.element.nativeElement.style.mozTransform ||
      event.source.element.nativeElement.style.msTransform;
    let transformData: RegExpMatchArray = transform.match(/^translate3d\((.+)\)$/);
    if (transformData[1].includes(' translate3d(')) {
      transformData = transformData[1].split(' translate3d(');
    }
    const position: PositionModel = {
      x: parseFloat(transformData[1].split(', ')[0]),
      y: parseFloat(transformData[1].split(', ')[1])
    };
    this.changePosition.emit({window: this.windowIdentifyPack.data, position: position});
  }

  get isDragMode(): boolean {
    return this.localStateService.getStateInstant(DESIGNER_MODE) === 'drag';
  }

}
