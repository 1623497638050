import {createFeatureSelector, createSelector} from '@ngrx/store';
import {windowAdapter, WindowState} from '../reducers';
import {CustomRouterStateUrlModel} from '@happy-windows/framework/core';

const getWindowState = createFeatureSelector<WindowState>('window');
const getRouterState = createFeatureSelector<CustomRouterStateUrlModel>('router');


const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = windowAdapter.getSelectors(getWindowState);


export const selectWindowsByCalendarName = (calendarName: string) => createSelector(
  selectAll,
  getWindowState,
  (windows, windowState) => (
    {
      data: windows.filter(x => x.data.idName === calendarName),
      loading: windowState.loading,
      error: windowState.error
    }
  )
);

export const selectedWindow = createSelector(
  selectAll,
  (windows) => windows.find(x => x.data.focused)
);
