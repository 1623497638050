import {Injectable} from '@angular/core';

import {Store} from "@ngrx/store";
import {Router} from '@angular/router';
import {ApiService} from './api.service';
import {ConfigService} from './config.service';
import {RuntimeInfoModel} from '../models';
import jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class RuntimeService {

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
    private store: Store<any>,
    private router: Router
  ) {
  }

  readonly TOKEN_KEY = "TOKEN";
  readonly LOGIN_KEY = "LOGIN";
  readonly LANGUAGE = "LANGUAGE";

  setLanguage(language: string) {
    localStorage.setItem(this.LANGUAGE, language);
  }

  getLanguage(): string {
    return localStorage.getItem(this.LANGUAGE);
  }

  setToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  removeToken() {
    localStorage.removeItem(this.TOKEN_KEY);
    ;
  }

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  isLoggedIn() {
    return localStorage.getItem(this.TOKEN_KEY) != null;
  }

  getLogin() {
    return localStorage.getItem(this.LOGIN_KEY);
  }

  setLogin(login: string) {
    localStorage.setItem(this.LOGIN_KEY, login);
  }

  signOut(isPwa: boolean, redirect = false) {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.LOGIN_KEY);
    if (redirect) {
      if (isPwa) {
        if (!this.router.url.includes('/pwa/login')) {
          this.router.navigate(['/pwa/login']);
        }
      } else {
        if (!this.router.url.includes('/home')) {
          this.router.navigate(['/home']);
          // this.router.navigate(['/home'],{queryParams: {return_url: this.router.url}});
        }
      }
    }
  }

  /**
   * Vrátí aktuální informace, zejména přihlášeného uživatele.
   *
   * Přihlášený uživatel se do store dává před načtením stránky, tedy pokud je přihlášený, je zaručeno, že
   * vrací hodnotu ihned.
   *
   * Upozornení: Jde o immutable hodnotu - jednorázovou, takže se nezmění žádná součást,
   * pokud chcete být notifikování o změnách, použijte observable variantu
   * a metodu [getCurrentUserObservable]
   */
  // getCurrent(): RuntimeInfo {
  //   let runtimeInfo: RuntimeInfo = null;
  //   this.store.select(getRuntimeInfo).pipe(take(1)).subscribe(r => runtimeInfo = r);
  //   return runtimeInfo;
  // }

  /**
   * Vrátí aktuální informace, zejména přihlášeného uživatele.
   *
   * Přihlášený uživatel se do store dává před načtením stránky, tedy pokud je přihlášený, je zaručeno, že
   * vrací hodnotu ihned.
   */
  // getCurrentUserObservable() : Observable<RuntimeInfo> {
  //   return this.store.select(getRuntimeInfo);
  // }


  getRuntimeInfo(): RuntimeInfoModel {
    return jwt_decode(this.getToken()) as RuntimeInfoModel;
  }

}
