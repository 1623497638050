export const cs = {
  language: {
    cs: 'Čeština',
    en: 'Angličtina'
  },
  menu: {
    home: 'Domů',
    designer: 'Návrhář'
  },
  login: {
    name: 'Název Happy window',
    password: 'Heslo pro přihlášení/editaci',
    button: {
      login: 'Příhlásit se',
      logout: 'Odhlásit se'
    },
    mustRequiredMessage: 'Tato položka musí být vyplněna!'
  },
  layout: {
    hello: 'ahoj',
    createHappyWindow: 'Založení nového HappyWindow'
  },
  window: {
    id: 'ID okna',
    idName: 'Název kalendáře',
    front: 'Přední strana',
    frontContent: 'Typ obsahu',
    back: 'Zadní strana',
    backContent: 'Typ obsahu',
    content: 'Obsah',
    contentType: 'Typ obsahu',
    general: 'Obecné',
    text: 'Text při otočení',
    date: 'Datum pro otočení',
    conditional: 'Povolení validace',
    textColor: 'Barva textu',
    borderColor: 'Barva rámečku',
    contentColor: 'Barva obsahu',
    contentTextColor: 'Text při otočení',
    position: {
      x: 'X-pozice',
      y: 'Y-pozice'
    },
    size: {
      width: 'Šířka okna',
      height: 'Výška okna'
    },
    createWindowError: 'Při vytvoření okna došlo k chybě!',
    updateWindowError: 'Při aktualizaci okna došlo k chybě!',
    removeWindowError: 'Při odstranění okna došlo k chybě!',
    validateWindowError: 'Při otevírání okna došlo k chybě!',
    changeTemplateLayoutSuccess: 'Změna šablony rozložení oken proběhla v pořádku.',
    changeTemplateLayoutError: 'Při změně šablony rozložení oken došlo k chybě!',
  },
  happyWindow: {
    info: 'Základní informace',
    name: 'Název HappyWindow',
    password: 'Heslo k darovaní',
    passwordConfirm: 'Potvrzení hesla k darování',
    passwordEdit: 'Heslo pro editaci',
    passwordEditConfirm: 'Potvrzení hesla pro editaci',
    settingGrid: 'Nastavení rozvržení',
    summary: 'Souhrn',
    backgroundGrid: 'Pozadí HappyWindow',
    backgroundContentGrid: 'Obsah pozadí HappyWindow',
    templateLayout: 'Šablona rozložení',
    changeTemplateLayout: 'Při změně šablony dojde k odstranění aktuálních oken!',
    customLayout: 'Vlastní rozložení',
    customLayoutMessage: 'Nebylo vybrané žádné rozložení, veškeré okna si budete muset rozmístit sami.',
    management: 'Správa HappyWindow',
    manageBackground: 'Správa pozadí HappyWindow',
    createHappyWindowSuccess: 'Vytvoření HappyWindow proběhlo v pořádku.',
    createHappyWindowError: 'Při vytvoření HappyWindow došlo k chybě!',
    removeHappyWindowSuccess: 'Odstranění HappyWindow proběhlo v pořádku.',
    removeHappyWindowError: 'Při odstranění HappyWindow došlo k chybě!',
    removeHappyWindowMessage: 'Opravdu chcete odstranit HappyWindow?',
    updateWindowGridError: 'Při aktualizaci HappyWindow došlo k chybě!',
  },
  presentation: {
    quillPlaceholder: 'Zadějte věnování...'
  },
  button: {
    addWindow: "Přidat okno",
    switchPage: "Otočit okna",
    switchPageToFront: "Otočit okna na přední stranu",
    switchPageToBack: "Otočit okna na zadní stranu",
    selectMode: "Úprava oken",
    dragMod: "Rozvrzení oken",
    testMode: "Testovací režim",
    next: "Pokračovat",
    back: "Vrátit se",
    createHappyWindow: 'Vytvořit HappyWindow',
    removeHappyWindow: 'Odstranit HappyWindow'
  },
  shared: {
    activeWarningMessages: 'Aktivovat varovné hlášky',
    resetSwitchWindow: 'Resetovat otočení oken',
    requiredMessage: 'Opravdu chcete mít tuto hodnotu nevyplněnou?',
    requiredDateMessage: 'Opravdu chcete mít datum nevyplněný? Pokud datum není vyplněn, nebude docházet k ověření datumu při pokusu o otočení.',
    mustRequiredMessage: 'Tato položka musí být vyplněna!',
    confirmPasswordMessage: 'Zadaná hesla se neshodují!',
    samePasswordMessage: 'Hesla pro editaci a k darování nesmí být stejná!',
    nameMessage: 'Název HappyWindows je již používán!',
    loginMessageSuccess: 'Přihlášení proběhlo v pořádku.',
    loginMessageError: 'Zadali jste špatný název nebo heslo!',
    imageLoaded: 'Obrázek je nahraný',
    image: 'Obrázek',
    color: 'Barva',
    text: 'Text',
    close: 'Zavřít',
    save: 'Uložit',
    add: 'Přidat',
    remove: 'Smazat',
    yes: 'Ano',
    no: 'Ne',
    setEveryone: 'Nastavit všem',
    manageWaringMessages: 'Správa varovných zpráv',
    management: 'Správa',
    connectionMessageError: 'Ztráta připojení!',
    pwa: 'PWA - Progressive Web Application',
    pwaMessage: 'Chcete nainstalovat aplikaci?'
  }
};
