<div class="action-bar" *ngIf="designerMode$ | async as designerMode">
  <a href="javascript:;" (click)="addWindow()" *ngIf="designerMode === 'drag'">
    <i class="material-icons md-18">queue</i>
    {{translation.button.addWindow | transloco}}
  </a>
  <span *ngIf="designerMode === 'drag'" class="divider"></span>
  <a href="javascript:;" (click)="changeMod('drag')" *ngIf="designerMode === 'select' || designerMode === 'test'">
    <i class="material-icons md-18">apps</i>
    {{translation.button.dragMod | transloco}}
  </a>
  <span *ngIf="designerMode === 'select' || designerMode === 'test'" class="divider"></span>
  <a href="javascript:;" (click)="changeMod('select')" *ngIf="designerMode === 'drag' || designerMode === 'test'">
    <i class="material-icons md-18">filter_none</i>
    {{translation.button.selectMode | transloco}}
  </a>
  <span *ngIf="designerMode === 'drag' || designerMode === 'test'" class="divider"></span>
  <a href="javascript:;" (click)="changeMod('test')" *ngIf="designerMode === 'select' || designerMode === 'drag'">
    <i class="material-icons md-18">launch</i>
    {{translation.button.testMode | transloco}}
  </a>
  <span *ngIf="designerMode === 'select' || designerMode === 'drag'" class="divider"></span>
  <a href="javascript:;" (click)="switchPage()" *ngIf="designerMode === 'select' || designerMode === 'drag'">
    <ng-container *ngIf="pageSiteState === 'front' else frontPage">
      <i class="material-icons md-18">flip_to_back</i>
      {{translation.button.switchPageToBack | transloco}}
    </ng-container>
    <ng-template #frontPage>
      <i class="material-icons md-18" *ngIf="pageSiteState === 'back'">flip_to_front</i>
      {{translation.button.switchPageToFront | transloco}}
    </ng-template>
  </a>
</div>
